import { Images, colors, fitSize } from "../../theme";
import React from "react";
import { SimpleHeader } from "../../components";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import styles from "./index.module.css";

interface RelationServiceProps {}

export const RelationService: React.FC<RelationServiceProps> = ({}) => {
  useDocumentTitle("联系客服");

  return (
    <div className={styles.container}>
      {/* <SimpleHeader title="联系客服" /> */}
      <div className={styles.content}>
        <div
          style={{
            backgroundImage: `url(${Images.mine.wechat})`,
            backgroundSize: "100% 100%",
            width: fitSize(350),
            height: fitSize(500),
            marginTop: 100,
          }}
        ></div>
      </div>
    </div>
  );
};
