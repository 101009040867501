import React, { FC, useEffect } from "react";
import Modal from "react-modal";
import {
  Location,
  NavigationType,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import {
  Images,
  colors,
  fitSize,
  fontName,
  windowHeight,
  windowWidth,
} from "../../../theme";
import { Touchable } from "../../../components/Button";
import { doCopy } from "../../../utils/doCopy";
import { showInfo } from "../../../utils/showUtil";

interface ExchangeModalProps {
  open: boolean;
  onClose: () => void;
  value: string;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: windowWidth,
    height: windowHeight,
    backgroundColor: colors.palette.neutral900 + "cc",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  overlay: { zIndex: 1000 },
};

export const ExchangeModal: FC<ExchangeModalProps> = ({
  open = false,
  onClose = () => {},
  value,
}) => {
  const location: Location = useLocation();
  const navType: NavigationType = useNavigationType();

  useEffect(() => {
    if (navType === "POP" && location.key !== "default") {
      onClose();
    }
  }, [location]);

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById("root") as HTMLElement}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Touchable
          style={{
            width: fitSize(14),
            height: fitSize(14),
            display: "flex",
            justifyContent: "center",
            backgroundImage: `url(${Images.components.close})`,
            backgroundSize: "100% 100%",
            position: "absolute",
            top: 350,
            right: 50,
          }}
          onClick={() => {
            onClose();
          }}
        />
        <div
          style={{
            height: fitSize(163),
            width: fitSize(310),
            alignSelf: "center",
            backgroundImage: `url(${Images.mine.exchange_modal})`,
            backgroundSize: "100% 100%",
            position: "relative",
          }}
        >
          <span
            style={{
              fontSize: fitSize(14),
              fontWeight: 500,
              color: "#767e85",
              fontFamily: fontName.PinFangSCSemibold,
              maxWidth: fitSize(207),
              position: "absolute",
              left: 46,
              top: 51,
              wordBreak: "normal",
              display: "block",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            {value}
          </span>
        </div>
        <Touchable
          style={{
            width: fitSize(77),
            height: fitSize(32),
            display: "flex",
            justifyContent: "center",
            backgroundImage: `url(${Images.home.home_hot_btn})`,
            backgroundSize: "100% 100%",
            position: "absolute",
            bottom: 310,
            alignSelf: "center",
          }}
          onClick={() => {
            doCopy(value);
            onClose();
            showInfo("复制成功", "OK");
          }}
        >
          <span
            style={{
              fontSize: fitSize(12),
              color: "#212F3B",
              fontWeight: 500,
              fontFamily: fontName.PinFangSCSemibold,
              marginTop: fitSize(6),
            }}
          >
            复制
          </span>
        </Touchable>
      </div>
    </Modal>
  );
};
