import React, { useState, useEffect, ReactElement } from 'react'
import styles from './index.module.css'
import { observer } from 'mobx-react-lite'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useNavigate } from 'react-router-dom'

interface myFrameProps {}

export const myFrame: React.FC<myFrameProps> = observer(({}) => {
  useDocumentTitle('联系客服')
  const navigate = useNavigate()

  const reload = () => {
    var browserRule = /^.*((iPhone)|(iPad)|(Safari))+.*$/
    if (browserRule.test(navigator.userAgent)) {
      navigate(-1)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      reload()
    }, 500)
  }, [])

  return (
    <div className={styles.container}>
      <iframe
        id="myFrame"
        title={'联系客服'}
        src={'https://work.weixin.qq.com/kfid/kfc6cc0ed559ab28c94'}
        width={'100%'}
        height={'100%'}
      ></iframe>
    </div>
  )
})
