import { observer } from 'mobx-react-lite'
import React, { FC, useEffect, useState } from 'react'
import styles from './index.module.css'
import { Images, colors, fitSize, fontName, windowWidth } from '../../theme'
import { useNavigate } from 'react-router-dom'
import { useStores } from '../../models'
import { GToast } from '../../navigators'
import { showInfo } from '../../utils/showUtil'
import { Touchable } from '../../components/Button'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { api } from '../../services/api'
import { clear, loadString, saveString } from '../../utils/storage'
import { ready } from '../../hooks/useInit'
import _ from 'lodash'

const LABEL_LIST = [
  {
    id: 1,
    router: '/lucky-draw-record',
    label: '扭蛋记录',
    params: null,
    icon: Images.mine.record_list
  },
  {
    id: 2,
    router: '/order-center',
    label: '我的订单',
    params: {
      id: 0
    },
    icon: Images.mine.prize_redemption
  },
  {
    id: 3,
    router: '/virtual-prize',
    label: '虚拟奖品',
    params: null,
    icon: Images.mine.customer_service
  },
  {
    id: 4,
    router: '/address-mange',
    label: '地址管理',
    params: { type: 'add' },
    icon: Images.mine.address_list
  }
]

interface MineScreenProps {}

export const MineScreen: FC<MineScreenProps> = observer(() => {
  useDocumentTitle('我的')
  const navigate = useNavigate()
  const { accountStore } = useStores()
  const [info, setInfo] = useState({
    one: 0, //待发货
    two: 0, //待收货
    three: 0, //优惠券
    four: 0 //道具卡数
  })

  const fetchGetUserPackage = async () => {
    let token = ''
    if (localStorage.getItem('root-v1')) {
      token = JSON.parse(localStorage.getItem('root-v1'))?.accountStore?.token
      api.apisauce.setHeader('Authorization', token)
    }

    try {
      const res = await api.getUserPackage()
      if (res.kind === 'ok') {
        setInfo(res.data)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    fetchGetUserPackage()
  }, [])

  const goSetting = () => {
    navigate('/my-frame')
  }

  const fetchLoginInfo = async (token: string) => {
    const res = await api.getLoginInfo(token)
    if (res.kind === 'ok' && !_.isEmpty(res.data)) {
      accountStore.doMessageLogin(res.data.authorization)
      accountStore.setUserInfo({
        authorization: res.data.authorization,
        userId: res.data.user_id,
        ddhId: res.data.ddh_id,
        userName: res.data.user_name
      })
      api.apisauce.setHeader('Authorization', res.data.authorization)
      fetchGetUserPackage()
    }
  }

  const init = async () => {
    if ((await loadString('first')) !== 'true') {
      const authCodeList = (await loadString('first')) === 'true' ? [] : ['metroUserId', 'mobile']
      ready(() => {
        ;(window as any).AlipayJSBridge.call(
          'metro_thirdPartyAuthToken',
          {
            appid: 2024011014213318,
            thirdCode: 'niudanCode',
            businessName: 'niudanCode',
            authCodeList
          },
          result => {
            const { code, data } = result
            if (code === 1000) {
              fetchLoginInfo(data.token)
              saveString('first', 'true')
            }
          }
        )
      })
    }
  }

  return (
    <div className={styles.container}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: fitSize(170),
          // background: "linear-gradient(273deg, #E2FAFF 0%, #FFF8D0 100%)",
          backgroundImage: `url(${Images.mine.mine_bg})`,
          backgroundSize: '100% 100%',
          position: 'relative'
        }}
      >
        <Touchable
          style={{
            position: 'absolute',
            right: 14,
            top: 16
          }}
          onClick={goSetting}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img
              src={Images.mine.mine_more}
              alt=""
              style={{
                width: fitSize(24),
                height: fitSize(24),
                textAlign: 'center',
                marginBottom: fitSize(4)
              }}
            />
            <span
              style={{
                fontSize: fitSize(11),
                fontFamily: fontName.PingFangSC,
                fontWeight: 400,
                color: '#2c2c2c'
              }}
            >
              联系客服
            </span>
          </div>
        </Touchable>
        <Touchable
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: fitSize(70),
            alignItems: 'center',
            alignSelf: 'flex-end',
            marginBottom: fitSize(30)
          }}
          onClick={init}
        >
          <div
            style={{
              width: fitSize(70),
              height: fitSize(70),
              borderRadius: fitSize(35),
              marginLeft: fitSize(14),
              alignSelf: 'center',
              border: '2px solid #212F3B',
              backgroundImage: `url(${Images.mine.user_logo})`,
              backgroundSize: '100% 100%',
              backgroundColor: '#2D73C8'
            }}
          />
          <span
            style={{
              fontSize: fitSize(20),
              color: colors.palette.neutral30,
              fontWeight: 600,
              fontFamily: fontName.PingFangSC,
              marginLeft: fitSize(10),
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: fitSize(250)
            }}
          >
            {accountStore.user.userName ?? '未登录'}
          </span>
        </Touchable>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: fitSize(91),
          borderRadius: '10px 10px 0px 0px',
          backgroundColor: colors.palette.neutral100,
          position: 'absolute',
          top: fitSize(155),
          alignItems: 'center'
        }}
      >
        {LABEL_LIST.map(item => {
          return (
            <Touchable
              key={item.id}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: fitSize(94),
                height: fitSize(51)
              }}
              onClick={() => {
                if (item.router) {
                  navigate(item.router, { state: item.params })
                } else {
                  showInfo('暂未开放')
                }
              }}
            >
              <img
                src={item.icon}
                alt=""
                style={{
                  width: fitSize(30),
                  height: fitSize(30),
                  objectFit: 'cover'
                }}
              />
              <span
                style={{
                  fontSize: fitSize(12),
                  color: colors.palette.neutral30,
                  fontWeight: 400,
                  // fontFamily: fontName.PingFangSC,
                  marginTop: fitSize(5)
                }}
              >
                {item.label}
              </span>
            </Touchable>
          )
        })}
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          backgroundColor: colors.palette.neutral100,
          marginTop: fitSize(86)
        }}
      >
        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: windowWidth - fitSize(28),
            height: fitSize(20),
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            marginTop: fitSize(14),
            marginBottom: fitSize(18)
          }}
        >
          <span
            style={{
              fontSize: fitSize(13),
              color: colors.palette.neutral30,
              fontWeight: 400
              // fontFamily: fontName.PingFangSC,
            }}
          >
            我的订单
          </span>
          <Touchable
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
            onClick={() => {
              navigate('/order-center', {
                state: {
                  id: 0
                }
              })
            }}
          >
            <span
              style={{
                fontSize: fitSize(11),
                color: colors.palette.neutral30,
                fontWeight: 400
                // fontFamily: fontName.PingFangSC,
              }}
            >
              全部订单
            </span>
            <img
              src={Images.mine.mine_right}
              alt=""
              style={{
                width: fitSize(14),
                height: fitSize(14),
                objectFit: 'cover'
              }}
            />
          </Touchable>
        </div> */}
        <div
          style={{
            fontSize: fitSize(13),
            color: colors.palette.neutral30,
            padding: fitSize(14)
          }}
        >
          我的物流
        </div>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            marginBottom: fitSize(14),
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: fitSize(165),
              height: fitSize(85),
              backgroundImage: `url(${Images.mine.wait_for_delivery})`,
              backgroundSize: '100% 100%'
            }}
          >
            <Touchable
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%'
              }}
              onClick={() => {
                navigate('/logistics-list', {
                  state: {
                    id: 2
                  }
                })
              }}
            >
              <span
                style={{
                  fontSize: fitSize(12),
                  color: colors.palette.neutral30,
                  fontWeight: 400,
                  // fontFamily: fontName.PingFangSC,
                  marginTop: fitSize(16),
                  marginLeft: fitSize(85)
                }}
              >
                待发货
              </span>
              <span
                style={{
                  fontSize: fitSize(18),
                  color: colors.palette.neutral30,
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                  marginTop: fitSize(3),
                  marginLeft: fitSize(85)
                }}
              >
                {info?.one}
              </span>
            </Touchable>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: fitSize(165),
              height: fitSize(85),
              backgroundImage: `url(${Images.mine.receive_goods})`,
              backgroundSize: '100% 100%'
            }}
          >
            <Touchable
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%'
              }}
              onClick={() => {
                navigate('/logistics-list', {
                  state: {
                    id: 3
                  }
                })
              }}
            >
              <span
                style={{
                  fontSize: fitSize(12),
                  color: colors.palette.neutral30,
                  fontWeight: 400,
                  // fontFamily: fontName.PingFangSC,
                  marginTop: fitSize(16),
                  marginLeft: fitSize(85)
                }}
              >
                待收货
              </span>
              <span
                style={{
                  fontSize: fitSize(18),
                  color: colors.palette.neutral30,
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                  marginTop: fitSize(3),
                  marginLeft: fitSize(85)
                }}
              >
                {info?.two}
              </span>
            </Touchable>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: colors.palette.neutral100,
          marginTop: fitSize(10),
          paddingBottom: fitSize(14)
        }}
      >
        <div
          style={{
            fontSize: fitSize(13),
            color: colors.palette.neutral30,
            paddingLeft: fitSize(14),
            paddingTop: fitSize(14)
          }}
        >
          我的福利
        </div>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'row',
            width: '100%',
            flexWrap: 'wrap'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: fitSize(165),
              height: fitSize(85),
              marginTop: fitSize(14),
              marginLeft: fitSize(14),
              backgroundImage: `url(${Images.mine.coupon})`,
              backgroundSize: '100% 100%'
            }}
          >
            <Touchable
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%'
              }}
              onClick={() => {
                navigate('/coupon-center', {})
              }}
            >
              <span
                style={{
                  fontSize: fitSize(12),
                  color: colors.palette.neutral30,
                  fontWeight: 400,
                  // fontFamily: fontName.PingFangSC,
                  marginTop: fitSize(16),
                  marginLeft: fitSize(85)
                }}
              >
                优惠劵
              </span>
              <span
                style={{
                  fontSize: fitSize(18),
                  color: colors.palette.neutral30,
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                  marginTop: fitSize(3),
                  marginLeft: fitSize(85)
                }}
              >
                {info?.three}
              </span>
            </Touchable>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: fitSize(165),
              height: fitSize(85),
              marginTop: fitSize(14),
              marginLeft: fitSize(14),
              backgroundImage: `url(${Images.mine.item_card})`,
              backgroundSize: '100% 100%'
            }}
          >
            <Touchable
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%'
              }}
              onClick={() => {
                navigate('/stage-property-card', {})
              }}
            >
              <span
                style={{
                  fontSize: fitSize(12),
                  color: colors.palette.neutral30,
                  fontWeight: 400,
                  fontFamily: fontName.PingFangSC,
                  marginTop: fitSize(16),
                  marginLeft: fitSize(85)
                }}
              >
                道具卡
              </span>
              <span
                style={{
                  fontSize: fitSize(18),
                  color: colors.palette.neutral30,
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                  marginTop: fitSize(3),
                  marginLeft: fitSize(85)
                }}
              >
                {info?.four}
              </span>
            </Touchable>
          </div>
        </div>
      </div>
    </div>
  )
})
