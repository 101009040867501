import React, { FC, useEffect } from 'react'
import Modal from 'react-modal'
import { Images, colors, fitSize, fontName, windowHeight, windowWidth } from '../theme'
import { Location, NavigationType, useLocation, useNavigationType } from 'react-router-dom'
import { Touchable } from '../components/Button'
import dayjs from 'dayjs'
import styles from './index.module.css'

interface GetCouponModalProps {
  open: boolean
  onClose: () => void
  info: {
    number: number
    total: number
    title: string
    startTime: number
    endTime: number
    couponType: number
  }
  type: 'Free' | 'Paid'
  onGetCoupon: () => void
  isProp?: boolean
  propIcon?: string
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: windowWidth,
    height: windowHeight,
    backgroundColor: colors.palette.neutral900 + 'a6'
  },
  overlay: { zIndex: 1000 }
}

export const GetCouponModal: FC<GetCouponModalProps> = ({
  open = false,
  onClose = () => {},
  info,
  type,
  onGetCoupon,
  isProp,
  propIcon
}) => {
  const location: Location = useLocation()
  const navType: NavigationType = useNavigationType()

  useEffect(() => {
    if (navType === 'POP' && location.key !== 'default') {
      onClose()
    }
  }, [location])

  const $freeContentStyle: React.CSSProperties = {
    width: fitSize(352),
    height: fitSize(361),
    backgroundImage: `url(${!isProp ? Images.components.free_coupon_modal : Images.activityNew.prop_modal})`,
    backgroundSize: '100% 100%',
    display: 'flex',
    flexDirection: 'column'
  }

  const $paidContentStyle: React.CSSProperties = {
    width: '100%',
    height: fitSize(478),
    backgroundImage: `url(${!isProp ? Images.components.paid_coupon_modal : Images.activityNew.discount_modal})`,
    backgroundSize: '100% 100%',
    display: 'flex',
    flexDirection: 'column'
  }

  const $paidSubmitStyle: React.CSSProperties = {
    width: fitSize(200),
    height: fitSize(100),
    alignSelf: 'center',
    marginLeft: fitSize(15),
    marginTop: fitSize(35),
    borderRadius: fitSize(20)
  }

  const $freeSubmitStyle: React.CSSProperties = {
    width: fitSize(200),
    height: fitSize(100),
    alignSelf: 'center',
    borderRadius: fitSize(20),
    position: 'absolute',
    bottom: 270
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById('root') as HTMLElement}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%'
        }}
      >
        <div style={type === 'Free' ? $freeContentStyle : $paidContentStyle}>
          {type === 'Paid' ? (
            <React.Fragment>
              <span
                style={{
                  fontSize: fitSize(12),
                  color: '#832604',
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                  marginTop: fitSize(116),
                  marginLeft: fitSize(10),
                  alignSelf: 'center'
                }}
              >
                天降好礼
              </span>
              <span
                style={{
                  fontSize: fitSize(22),
                  color: '#832604',
                  fontWeight: 600,
                  fontFamily: fontName.PinFangSCSemibold,
                  marginTop: fitSize(10),
                  marginLeft: fitSize(15),
                  alignSelf: 'center'
                }}
              >
                恭喜获得
                <span
                  style={{
                    fontSize: fitSize(22),
                    color: '#FD5A56',
                    fontWeight: 600,
                    fontFamily: fontName.PinFangSCSemibold,
                    alignSelf: 'center'
                  }}
                >
                  {1}张
                </span>
                {!isProp ? '优惠劵' : '重抽卡'}
              </span>
              <span
                style={{
                  fontSize: fitSize(11),
                  color: '#832604',
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                  marginTop: fitSize(1),
                  marginLeft: fitSize(15),
                  alignSelf: 'center'
                }}
              >
                {!isProp ? `指定扭蛋机立减${info.number}元` : '全部扭蛋机可用'}
              </span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignSelf: 'center',
                  width: fitSize(300),
                  marginTop: fitSize(25),
                  position: 'relative',
                  justifyContent: 'center'
                }}
              >
                {!isProp ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      width: fitSize(86),
                      height: fitSize(76),
                      position: 'absolute',
                      left: 50,
                      top: -15
                    }}
                  >
                    <span
                      style={{
                        fontSize:
                          info?.couponType === 1
                            ? fitSize(40)
                            : info?.number.toString().length >= 3
                            ? fitSize(30)
                            : info?.number.toString().length === 2
                            ? fitSize(35)
                            : fitSize(40),
                        color: '#FFFFFF',
                        fontWeight: 600,
                        fontFamily: fontName.PoppinsExtraBoldItalic,
                        boxShadow: '0px 2px 4px rgba(190,88,79,0.17)'
                      }}
                    >
                      {info?.couponType === 1 ? info?.number * 100 : info?.number}
                    </span>
                    <span
                      style={{
                        fontSize: fitSize(15),
                        color: '#FFFFFF',
                        fontWeight: 600,
                        fontFamily: fontName.PinFangSCSemibold,
                        boxShadow: '0px 2px 4px rgba(190,88,79,0.17)',
                        marginLeft: fitSize(5),
                        marginBottom: fitSize(10)
                      }}
                    >
                      {info?.couponType === 1 ? '折' : '元'}
                    </span>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: fitSize(86),
                      height: fitSize(76),
                      position: 'absolute',
                      left: 50,
                      top: -5
                    }}
                  >
                    <img
                      alt=""
                      src={Images.mine.card1}
                      style={{
                        width: fitSize(55),
                        height: fitSize(38),
                        objectFit: 'contain'
                      }}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: fitSize(15),
                    paddingTop: fitSize(6),
                    position: 'absolute',
                    left: 140,
                    top: info?.startTime === 0 && info?.endTime === 0 ? 15 : 5
                  }}
                >
                  <span
                    style={{
                      fontSize: fitSize(16),
                      color: '#FFFFFF',
                      fontWeight: 600,
                      fontFamily: fontName.PinFangSCSemibold,
                      boxShadow: '0px 2px 4px rgba(190,88,79,0.17)'
                    }}
                  >
                    {info?.title}
                  </span>
                  {!isProp ? (
                    <span
                      style={{
                        fontSize: fitSize(10),
                        color: '#FFFFFF77',
                        fontWeight: 400,
                        marginTop: fitSize(2),
                        textShadow: '0px 2px 4px rgba(190,88,79,0.17)',
                        display: info?.startTime === 0 && info?.endTime === 0 ? 'none' : 'flex'
                      }}
                    >
                      {dayjs(info?.startTime * 1000).format('YYYY.MM.DD')}-{dayjs(info?.endTime * 1000).format('YYYY.MM.DD')}
                    </span>
                  ) : (
                    <span
                      style={{
                        fontSize: fitSize(10),
                        color: '#FFFFFF77',
                        fontWeight: 400,
                        marginTop: fitSize(2),
                        display: !isProp ? 'none' : 'flex',
                        textShadow: '0px 2px 4px rgba(190,88,79,0.17)'
                      }}
                    >
                      使用后将直接替换
                    </span>
                  )}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div
              style={{
                width: fitSize(130),
                height: fitSize(123),
                transform: 'rotate(11deg)',
                position: 'absolute',
                left: fitSize(140),
                bottom: fitSize(355),
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {!isProp ? (
                <>
                  <span
                    style={{
                      fontSize:
                        info?.couponType === 1
                          ? fitSize(80)
                          : info?.number.toString().length >= 3
                          ? fitSize(45)
                          : info?.number.toString().length > 1
                          ? fitSize(60)
                          : fitSize(80),
                      fontWeight: 500,
                      fontFamily: fontName.PinFangSCSemibold,
                      color: colors.palette.neutral100,
                      transform: 'rotate(-2deg)',
                      marginLeft:
                        info?.couponType === 1
                          ? fitSize(15)
                          : info?.number.toString().length >= 3
                          ? fitSize(3)
                          : info?.number.toString().length > 1
                          ? fitSize(5)
                          : fitSize(15),
                      marginTop:
                        info?.couponType === 1
                          ? fitSize(7)
                          : info?.number.toString().length >= 3
                          ? fitSize(30)
                          : info?.number.toString().length > 1
                          ? fitSize(27)
                          : fitSize(7)
                    }}
                    className={styles.number_span}
                  >
                    {info?.couponType === 1 ? info?.number * 100 : info?.number}
                  </span>
                  <span
                    style={{
                      fontSize:
                        info?.couponType === 1
                          ? fitSize(50)
                          : info?.number.toString().length >= 3
                          ? fitSize(40)
                          : info?.number.toString().length > 1
                          ? fitSize(50)
                          : fitSize(50),
                      fontWeight: 500,
                      fontFamily: fontName.PinFangSCSemibold,
                      color: colors.palette.neutral100,
                      transform:
                        info?.couponType === 1
                          ? 'rotate(-1deg)'
                          : info?.number.toString().length >= 3
                          ? 'rotate(0deg)'
                          : info?.number.toString().length > 1
                          ? 'rotate(-4deg)'
                          : 'rotate(-1deg)',
                      position: 'absolute',
                      top: 35,
                      right:
                        info?.couponType === 1
                          ? fitSize(5)
                          : info?.number.toString().length >= 3
                          ? fitSize(0)
                          : info?.number.toString().length > 1
                          ? fitSize(1)
                          : fitSize(5)
                    }}
                    className={styles.number_span}
                  >
                    {info?.couponType === 1 ? '折' : '元'}
                  </span>
                </>
              ) : (
                <img
                  alt=""
                  src={Images.mine.card1}
                  style={{
                    width: fitSize(95),
                    height: fitSize(76),
                    objectFit: 'contain',
                    position: 'absolute',
                    bottom: 40,
                    right: 20
                  }}
                />
              )}
              <span
                style={{
                  fontSize: fitSize(12),
                  fontWeight: 400,
                  fontFamily: fontName.PingFangSC,
                  color: '#212F3B',
                  // transform: "rotate(deg)",
                  position: 'absolute',
                  bottom: 10,
                  right: 20
                }}
              >
                {!isProp ? info?.title : '全部扭蛋机可用'}
              </span>
            </div>
          )}
          <Touchable style={type === 'Free' ? $freeSubmitStyle : $paidSubmitStyle} onClick={type === 'Free' ? onGetCoupon : onClose} />
          {type === 'Paid' && (
            <div style={{ textAlign: 'center' }}>
              <img
                src={Images.components.close}
                style={{ width: fitSize(20), height: fitSize(20), marginTop: fitSize(28) }}
                onClick={onClose}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
