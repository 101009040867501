import { observer } from "mobx-react-lite";
import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./index.module.css";
import { Images, colors, fitSize, windowWidth } from "../../theme";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactLoading from "react-loading";
import { CardContent } from "../TabHome/components/SectionGroup";
import { Touchable } from "../../components/Button";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { GToast } from "../../navigators";
import { ZoneItem, api } from "../../services/api";
import { EmptyItem } from "../../components/EmptyItem";

interface CouponUsableProps {}

export const CouponUsable: FC<CouponUsableProps> = observer(() => {
  useDocumentTitle("可用优惠券");
  const {
    state: { ids },
  } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState<ZoneItem[]>([]);
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const PAGE_SIZE = 20;

  const loadMoreData = () => {
    if (hasNextPage) {
      fetchMoreData();
    }
  };

  const fetchMoreData = async () => {
    try {
      const res = await api.getAllLuckyDraw(page, PAGE_SIZE, ids);
      if (res.kind === "ok") {
        if (res.data.list.length > 0) {
          setData((val) => [...val, ...res.data.list]);
          setPage(page + 1);
          setHasNextPage(page * PAGE_SIZE < res.data.total);
        } else {
          setHasNextPage(false);
        }
      } else {
        GToast.current.handleErrorResponse(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onRefresh = async () => {
    try {
      const res = await api.getAllLuckyDraw(1, PAGE_SIZE, ids);
      if (res.kind === "ok") {
        if (res.data.list.length > 0) {
          setData(res.data.list);
          setPage(page + 1);
          setHasNextPage(page * PAGE_SIZE < res.data.total);
        } else {
          setHasNextPage(false);
        }
      } else {
        GToast.current.handleErrorResponse(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setPage(1);
    setHasNextPage(true);
    setData([]);
    onRefresh();
  }, []);

  return (
    <div className={styles.container}>
      {/* <Touchable
        onClick={() => {
          navigate(-1);
        }}
        style={{ position: "absolute", left: fitSize(20), top: fitSize(20) }}
      >
        <img
          src={Images.components.back_white}
          alt=""
          style={{ width: fitSize(20), height: fitSize(20) }}
        />
      </Touchable> */}

      <div className={styles.content_usable} id="scrollableDiv">
        <EmptyItem
          isShow={data.length === 0}
          text={"空空如也"}
          type="not_all"
        />
        <InfiniteScroll
          dataLength={data.length}
          next={loadMoreData}
          hasMore={hasNextPage}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            minHeight: "100vh",
            backgroundImage: `url(${Images.mine.coupon_usable})`,
            backgroundSize: "contain",
            backgroundRepeat: "repeat",
            paddingTop: fitSize(20),
          }}
          loader={
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ReactLoading
                type={"bubbles"}
                color={"#62FFD0"}
                height={50}
                width={50}
              />
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            {/* <div
              style={{
                width: "100%",
                height: fitSize(247),
                backgroundImage: `url(${Images.mine.coupon_usable_top})`,
                backgroundSize: "100% 100%",
                backgroundColor: colors.transparent,
              }}
            /> */}
            {data?.map((item, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    width: fitSize(347),
                    height: fitSize(163),
                    marginBottom: fitSize(10),
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    alignSelf: "center",
                    backgroundImage: `url(${Images.components.coupon_usable_card})`,
                    backgroundSize: "100% 100%",
                  }}
                  key={index}
                >
                  <CardContent
                    info={item}
                    isBackgroundColor={false}
                    isHot={true}
                    title={"优惠卷"}
                  />
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
});
