import React, { useState, useEffect, ReactElement, memo, FC } from 'react'

import styles from './index.module.css'
import { observer } from 'mobx-react-lite'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { SimpleHeader } from '../../components'
import { Images, colors, fitSize, fontName, windowWidth } from '../../theme'
import dayjs from 'dayjs'
import { Touchable } from '../../components/Button'
import { ExchangeModal } from './components/ExchangeModal'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { OrderBuyItem, VirtualItem, api } from '../../services/api'
import { GToast } from '../../navigators'
import { EmptyItem } from '../../components/EmptyItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { TipModal } from '../LuckyDraw/components/TipModal'

interface VirtualPrizeProps {}

interface ItemProps {
  item: VirtualItem
  showIndex: number
  currentIndex: number
}

export const VirtualPrize: React.FC<VirtualPrizeProps> = observer(({}) => {
  useDocumentTitle('虚拟奖品')
  const [data, setData] = useState<VirtualItem[]>([])
  const [open, setOpen] = useState(false)
  const [exchangeId, setExchangeId] = useState()
  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)
  const PAGE_SIZE = 20
  const [openTip, setOpenTip] = useState(false)

  const fetchGetVirtualPrizeList = async () => {
    try {
      const res = await api.getVirtualList(page, PAGE_SIZE)
      if (res.kind === 'ok') {
        if (res.data.list.length > 0) {
          setData(val => [...val, ...res.data.list])
          setPage(page + 1)
          setHasNextPage(page * PAGE_SIZE < res.data.total)
        } else {
          setHasNextPage(false)
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const loadMoreData = () => {
    if (hasNextPage) {
      fetchGetVirtualPrizeList()
    }
  }

  const onRefresh = async () => {
    try {
      const res = await api.getVirtualList(1, PAGE_SIZE)
      if (res.kind === 'ok') {
        if (res.data.list.length > 0) {
          setData(res.data.list)
          setPage(page + 1)
          setHasNextPage(page * PAGE_SIZE < res.data.total)
        } else {
          setHasNextPage(false)
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    setPage(1)
    setHasNextPage(true)
    setData([])
    onRefresh()
  }, [])

  const onExchange = id => {
    setExchangeId(id)
    setOpen(true)
  }

  return (
    <div className={styles.container}>
      {/* <SimpleHeader title="虚拟奖品" /> */}
      <ExchangeModal
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        value={exchangeId}
      />
      <TipModal
        isShow={openTip}
        closeModal={() => {
          setOpenTip(false)
        }}
      />

      <div className={styles.content} id="scrollableDiv">
        <EmptyItem isShow={data.length === 0} text={'空空如也'} type="not_all" />
        <Touchable
          style={{
            width: fitSize(53),
            height: fitSize(53),
            position: 'absolute',
            right: 0,
            bottom: 100,
            zIndex: 2
          }}
          onClick={() => {
            setOpenTip(true)
          }}
        >
          <img src={Images.components.lucky_tip} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />

          <div style={{ fontSize: fitSize(12), textAlign: 'center', marginTop: '-8 px' }}>使用说明</div>
        </Touchable>
        <InfiniteScroll
          dataLength={data.length}
          next={loadMoreData}
          hasMore={hasNextPage}
          // endMessage={
          //   <p style={{ textAlign: "center" }}>
          //     <b>已经到最底下啦.....</b>
          //   </p>
          // }
          loader={
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <ReactLoading type={'bubbles'} color={'#62FFD0'} height={50} width={50} />
            </div>
          }
          scrollableTarget="scrollableDiv"
          style={{
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            display: data.length > 0 ? 'flex' : 'none',
            paddingBottom: 100
          }}
        >
          {data.map((item, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  minHeight: 'auto',
                  height: 'auto',
                  padding: `${fitSize(12)} ${fitSize(14)} ${fitSize(14)}`,
                  marginBottom: fitSize(10),
                  backgroundColor: colors.palette.neutral100,
                  position: 'relative'
                }}
                key={index}
              >
                <span
                  style={{
                    fontSize: fitSize(14),
                    fontWeight: 500,
                    color: colors.palette.neutral30,
                    fontFamily: fontName.PinFangSCSemibold,
                    marginLeft: fitSize(14),
                    marginTop: fitSize(12)
                  }}
                >
                  {item?.niudanji_name}
                </span>
                <div
                  style={{
                    width: fitSize(347),
                    height: fitSize(0),
                    alignSelf: 'center',
                    marginTop: fitSize(11),
                    marginBottom: fitSize(13),
                    borderWidth: 1,
                    borderStyle: 'dashed',
                    borderColor: '#F5F5F8'
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%'
                    // position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: fitSize(347),
                      height: fitSize(109),
                      display: 'flex',
                      flexDirection: 'row',
                      borderTop: '1px solid #F5F5F8',
                      alignSelf: 'center'
                    }}
                  >
                    <LazyLoadImage
                      alt={''}
                      height={fitSize(81)}
                      src={
                        item?.business_type === 1
                          ? item?.award_img
                          : item?.business_type === 2
                          ? Images.activityNew.item_card
                          : item?.business_type === 3
                          ? Images.activityNew.coupon_card
                          : ''
                      }
                      width={fitSize(81)}
                      effect="blur"
                      wrapperProps={{
                        style: { transitionDelay: '0.3s' }
                      }}
                      style={{
                        objectFit: 'scale-down',
                        border: '1px solid #F5F5F8',
                        marginTop: fitSize(14),
                        marginRight: fitSize(12)
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        marginTop: fitSize(14),
                        marginBottom: fitSize(14),
                        marginRight: fitSize(14)
                      }}
                    >
                      <span
                        style={{
                          fontSize: fitSize(13),
                          fontWeight: 400,
                          color: colors.palette.neutral30,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: fitSize(220)
                        }}
                      >
                        {item?.award_name}
                      </span>
                      <span
                        style={{
                          fontSize: fitSize(11),
                          fontWeight: 400,
                          color: '#aaaaaa',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: fitSize(220),
                          marginTop: 1,
                          marginBottom: 1
                        }}
                      >
                        x{item?.award_num}
                      </span>
                      <span
                        style={{
                          fontSize: fitSize(11),
                          fontWeight: 400,
                          color: '#aaaaaa',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: fitSize(220),
                          marginBottom: fitSize(7)
                        }}
                      >
                        {dayjs(item?.create_time * 1000).format('YYYY.MM.DD HH:mm:ss')}
                      </span>
                      {/* <div
                      style={{
                        marginRight: fitSize(14),
                        marginTop: fitSize(6),
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: fitSize(9),
                          fontWeight: 500,
                          color: colors.palette.neutral30,
                          fontFamily: fontName.PinFangSCSemibold,
                        }}
                      >
                        实付款：
                      </span>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: fitSize(9),
                            fontWeight: 500,
                            color: colors.palette.neutral30,
                            marginRight: fitSize(2),
                            fontFamily: fontName.PinFangSCSemibold,
                          }}
                        >
                          ¥
                        </span>
                        <span
                          style={{
                            fontSize: fitSize(18),
                            fontWeight: 500,
                            color: colors.palette.neutral30,
                            fontFamily: fontName.PinFangSCSemibold,
                          }}
                        >
                          {(item?.single_price / 100).toFixed(2)}
                        </span>
                      </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                  <div
                    style={{
                      marginRight: fitSize(14),
                      display: item?.cdk ? 'flex' : 'none',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Touchable
                      style={{
                        width: fitSize(77),
                        height: fitSize(32),
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundImage: `url(${Images.home.home_hot_btn})`,
                        backgroundSize: '100% 100%',
                        position: 'absolute',
                        bottom: 10,
                        right: 13
                      }}
                      onClick={() => {
                        onExchange(item?.cdk)
                      }}
                    >
                      <span
                        style={{
                          fontSize: fitSize(12),
                          color: '#212F3B',
                          fontWeight: 500,
                          fontFamily: fontName.PinFangSCSemibold,
                          marginTop: fitSize(6)
                        }}
                      >
                        CDKEY
                      </span>
                    </Touchable>
                  </div>
                </div>
              </div>
            )
          })}
        </InfiniteScroll>
      </div>
    </div>
  )
})
