import { FC, Fragment, useState } from 'react'
import { Images, colors, fitSize, fontName, windowWidth } from '../../../theme'
import dayjs from 'dayjs'
import { Touchable } from '../../../components/Button'
import { useNavigate } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { api, ZoneItem } from '../../../services/api'
import { GToast } from '../../../navigators'
import { showInfo } from '../../../utils/showUtil'

export const getActivityStartRestTimeDays = (time: number) => {
  if (time <= 0) return '00:00:00:00'

  const allS = Math.floor(time / 1000)
  const days = Math.floor(allS / 60 / 60 / 24)

  const hours = Math.floor((allS / 60 / 60) % 24)
  const minutes = Math.floor((allS / 60) % 60)
  const seconds = Math.floor(allS % 60)

  const timeString =
    (days < 10 ? '0' + days : days) +
    ':' +
    (hours < 10 ? '0' + hours : hours) +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes) +
    ':' +
    (seconds < 10 ? '0' + seconds : seconds)

  return timeString
}

interface SectionGroupProps {
  title: string
  list: ZoneItem[]
  cardType: 'NEW_PERSON' | 'TIME_LIMIT' | 'HOT_SALE'
  currentTime?: number
  isCustom?: boolean
  show?: boolean
  isBuyZone?: boolean
}

interface CardProps {
  info: ZoneItem
  currentTime: number
  title: string
  isCustom?: boolean
  isBuyZone?: boolean
}

export const SectionGroup: FC<SectionGroupProps> = props => {
  const { title, list, cardType, currentTime, isCustom, show, isBuyZone } = props

  return (
    <div
      style={{
        width: windowWidth,
        display: show ? 'flex' : 'none',
        alignItems: 'center',
        flexDirection: 'column',
        alignSelf: 'center',
        marginBottom: fitSize(13)
      }}
    >
      <div
        style={{
          width: windowWidth - fitSize(32),
          height: fitSize(47),
          display: 'flex',
          alignItems: 'center',
          position: 'relative'
        }}
      >
        <img
          src={Images.home.new_person}
          alt=""
          style={{
            width: fitSize(63),
            height: fitSize(23),
            position: 'absolute',
            zIndex: -1,
            left: 20
          }}
        />
        <span
          style={{
            fontSize: fitSize(18),
            color: colors.palette.neutral30,
            fontWeight: 600,
            fontFamily: fontName.PinFangSCSemibold
          }}
        >
          {title}
        </span>
        {/* <span
          style={{
            display: title === '福利专区' ? 'flex' : 'none',
            fontSize: fitSize(12),
            color: 'red',
            fontFamily: fontName.PinFangSCSemibold,
            marginLeft: 15
          }}
        >
          每位用户限购一次
        </span> */}
      </div>
      <div
        style={{
          width: windowWidth - fitSize(32),
          display: 'flex',
          flexDirection: 'column'
          // backgroundColor:  colors.palette.neutral100,
        }}
      >
        {list?.map((item, index) => {
          return (
            <Fragment key={index}>
              {cardType === 'NEW_PERSON' && <NewPersonCard info={item} currentTime={currentTime} isBuyZone={isBuyZone} title={title} />}
              {cardType === 'TIME_LIMIT' && <TimeLimitCard info={item} currentTime={currentTime} title={title} isBuyZone={isBuyZone} />}
              {cardType === 'HOT_SALE' && (
                <HotSaleCard info={item} currentTime={currentTime} isCustom={isCustom} title={title} isBuyZone={isBuyZone} />
              )}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export const NewPersonCard: FC<CardProps> = ({ info, isBuyZone, title }) => {
  const navigate = useNavigate()

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: fitSize(177),
        borderRadius: fitSize(6),
        marginBottom: fitSize(10),
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        boxShadow: '0px 2px 4px 0px rgba(230,230,230,0.5)',
        position: 'relative'
      }}
      onClick={() => {
        localStorage.setItem('title', title)
        if (title === '福利专区') {
          if (isBuyZone) {
            showInfo('很抱歉，此专区每位用户仅可购买一次')
          } else {
            navigate(`/activity-new/${info?.id}`)
            window._hmt.push(['_trackEvent', 'button', 'click', title])
          }
        } else {
          navigate(`/activity-new/${info?.id}`)
          window._hmt.push(['_trackEvent', 'button', 'click', title])
        }
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: fitSize(177),
          backgroundImage: `url(${Images.home.new_person_card})`,
          backgroundSize: '100% 100%',
          zIndex: 2
        }}
      />
      <div
        style={{
          display: 'flex',
          width: fitSize(335),
          height: fitSize(157),
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          zIndex: 1
        }}
      >
        <CardContent info={info} isClick={false} title={title} isBuyZone={isBuyZone} />
      </div>
    </div>
  )
}

export const TimeLimitCard: FC<CardProps> = ({ info, currentTime, title, isBuyZone }) => {
  const [days, hours, minutes, seconds] = getActivityStartRestTimeDays(dayjs(info?.end_time * 1000).diff(currentTime))
    .split(':')
    .map(Number)

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: fitSize(197),
        borderRadius: fitSize(6),
        marginBottom: fitSize(10),
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        flexDirection: 'column',
        backgroundColor: '#FFE56D',
        boxShadow: '0px 2px 4px 0px rgba(230,230,230,0.5)'
      }}
    >
      <div
        style={{
          display: 'flex',
          width: fitSize(334),
          height: fitSize(36),
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundImage: `url(${Images.home.time_limit_card})`,
          backgroundSize: '100% 100%',
          borderRadius: '2px 2px 0px 0px',
          marginTop: fitSize(3)
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: fitSize(13),
            marginTop: fitSize(2),
            alignSelf: 'flex-end'
          }}
        >
          <span
            style={{
              fontSize: fitSize(14),
              color: colors.palette.neutral30,
              fontWeight: 400,
              fontFamily: fontName.LiteraryBlack,
              marginRight: fitSize(8),
              marginTop: fitSize(3)
            }}
          >
            距结束
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: fitSize(20),
                height: fitSize(20),
                backgroundImage: `url(${Images.home.border})`,
                backgroundSize: '100% 100%'
              }}
            >
              {/* <div
                style={{
                  width: fitSize(18),
                  height: fitSize(18),
                  borderRadius: fitSize(1),
                  backgroundImage: `url(${Images.home.border})`,
                  backgroundSize: "100% 100%",
                  position: "absolute",
                  left: 2,
                  top: 2,
                  zIndex: 2,
                }}
              /> */}
              <span
                style={{
                  fontSize: fitSize(11),
                  color: colors.palette.primary100,
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                  marginTop: fitSize(1),
                  marginLeft: fitSize(2.5)
                }}
              >
                {days < 10 ? `0${days}` : days}
              </span>
            </div>
            <span
              style={{
                fontSize: fitSize(20),
                color: '#979797',
                fontWeight: 600,
                textAlign: 'center',
                marginBottom: fitSize(2),
                marginLeft: fitSize(6),
                marginRight: fitSize(4)
              }}
            >
              :
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: fitSize(20),
                height: fitSize(20),
                backgroundImage: `url(${Images.home.border})`,
                backgroundSize: '100% 100%'
              }}
            >
              <span
                style={{
                  fontSize: fitSize(11),
                  color: colors.palette.primary100,
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                  marginTop: fitSize(1),
                  marginLeft: fitSize(2.5)
                }}
              >
                {hours < 10 ? `0${hours}` : hours}
              </span>
            </div>
            <span
              style={{
                fontSize: fitSize(20),
                color: '#979797',
                fontWeight: 600,
                textAlign: 'center',
                marginBottom: fitSize(2),
                marginLeft: fitSize(6),
                marginRight: fitSize(4)
              }}
            >
              :
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: fitSize(20),
                height: fitSize(20),
                backgroundImage: `url(${Images.home.border})`,
                backgroundSize: '100% 100%'
              }}
            >
              <span
                style={{
                  fontSize: fitSize(11),
                  color: colors.palette.primary100,
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                  marginTop: fitSize(1),
                  marginLeft: fitSize(2.5)
                }}
              >
                {minutes < 10 ? `0${minutes}` : minutes}
              </span>
            </div>
            <span
              style={{
                fontSize: fitSize(20),
                color: '#979797',
                fontWeight: 600,
                textAlign: 'center',
                marginBottom: fitSize(2),
                marginLeft: fitSize(6),
                marginRight: fitSize(4)
              }}
            >
              :
            </span>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                // alignItems: "center",
                // justifyContent: "center",
                width: fitSize(20),
                height: fitSize(20),
                backgroundImage: `url(${Images.home.border})`,
                backgroundSize: '100% 100%'
              }}
            >
              <span
                style={{
                  fontSize: fitSize(11),
                  color: colors.palette.primary100,
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                  marginTop: fitSize(1),
                  marginLeft: fitSize(2.5)
                }}
              >
                {seconds < 10 ? `0${seconds}` : seconds}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: fitSize(336),
          height: fitSize(153),
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          borderRadius: 4,
          paddingBottom: 3
        }}
      >
        <CardContent info={info} title={title} isBuyZone={isBuyZone} />
      </div>
    </div>
  )
}

export const HotSaleCard: FC<CardProps> = ({ info, isCustom, title, isBuyZone }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: fitSize(177),
        marginBottom: fitSize(10),
        borderRadius: fitSize(6),
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        boxShadow: '0px 2px 4px 0px rgba(230,230,230,0.5)',
        backgroundImage: `url(${Images.home.hot_sale})`,
        backgroundColor: '#fff',
        backgroundSize: '100% 100%',
        zIndex: 1
      }}
    >
      <div
        style={{
          display: 'flex',
          width: fitSize(335),
          height: fitSize(157),
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          zIndex: 2
        }}
      >
        <CardContent title={title} info={info} isBackgroundColor={false} isHot={true} isBuyZone={isBuyZone} />
      </div>
    </div>
  )
}

export const CardContent = ({
  title,
  info,
  isBackgroundColor = true,
  isHot = false,
  isClick = true,
  isBuyZone
}: {
  title: string
  info: ZoneItem
  isBackgroundColor?: boolean
  isHot?: boolean
  isClick?: boolean
  isBuyZone?: boolean
}) => {
  const navigate = useNavigate()
  const icon = info?.award_imgs.includes(',') ? info?.award_imgs.split(',').map(url => url.trim()) : [info?.award_imgs]

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: isBackgroundColor ? colors.palette.neutral100 : undefined,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1
      }}
      onClick={() => {
        if (isClick) {
          localStorage.setItem('title', title)
          navigate(`/activity-new/${info?.id}`)
          window._hmt.push(['_trackEvent', 'button', 'click', title])
        }
      }}
    >
      <LazyLoadImage
        alt={''}
        height={fitSize(113)}
        src={info?.thumb_img}
        width={fitSize(109)}
        effect="blur"
        wrapperProps={{
          style: { transitionDelay: '0.3s' }
        }}
        style={{
          marginTop: fitSize(7),
          marginRight: fitSize(13),
          marginLeft: fitSize(13),
          objectFit: 'scale-down'
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: '100%'
        }}
      >
        <span
          style={{
            fontSize: fitSize(15),
            color: colors.palette.neutral30,
            fontWeight: 500,
            fontFamily: fontName.PinFangSCSemibold,
            marginTop: fitSize(10),
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: fitSize(180)
          }}
        >
          {info?.name}
        </span>
        <span
          style={{
            fontSize: fitSize(12),
            color: '#AAAAAA',
            fontWeight: 400,
            // fontFamily: fontName.PingFangSC,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: fitSize(180)
          }}
        >
          {info?.description}
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            overflowY: 'hidden',
            overflowX: 'auto',
            width: fitSize(190),
            marginTop: fitSize(3),
            height: fitSize(33)
          }}
        >
          {icon?.map((item, index) => {
            return (
              <LazyLoadImage
                key={index}
                alt={''}
                height={fitSize(33)}
                src={item}
                width={fitSize(33)}
                effect="blur"
                wrapperProps={{
                  style: { transitionDelay: '0.3s' }
                }}
                style={{
                  marginRight: fitSize(4),
                  objectFit: 'scale-down'
                }}
              />
            )
          })}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: fitSize(150),
            height: fitSize(19),
            marginTop: fitSize(6),
            backgroundImage: `url(${Images.home.card_percent})`,
            backgroundSize: '100% 100%',
            overflow: 'hidden'
          }}
        >
          <span
            style={{
              fontSize: fitSize(11),
              color: '#ffffff',
              fontWeight: 400,
              fontFamily: fontName.PinFangSCSemibold,
              paddingRight: fitSize(8),
              whiteSpace: 'nowrap',
              marginLeft: fitSize(5),
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            100%必中
          </span>
          <span
            style={{
              fontSize: fitSize(11),
              color: '#C187EB',
              fontWeight: 400,
              fontFamily: fontName.PinFangSCSemibold,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {`${info?.min_price / 100}~${(info?.max_price / 100).toFixed(2)}元`}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            width: fitSize(194),
            height: fitSize(31),
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: fitSize(7),
            backgroundColor: '#F5F5F866'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <span
                style={{
                  fontSize: fitSize(12),
                  color: '#FF1F02',
                  fontWeight: 400,
                  fontFamily: fontName.PingFangSC,
                  marginLeft: fitSize(7),
                  marginRight: fitSize(2),
                  marginTop: fitSize(2)
                }}
              >
                ¥
              </span>
              <span
                style={{
                  fontSize: fitSize(18),
                  color: '#FF1F02',
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                  maxWidth: fitSize(130),
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {(info?.price / 100).toFixed(2)}
              </span>
            </div>
          </div>
          <div
            style={{
              width: fitSize(55),
              height: fitSize(31),
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: title === '福利专区' && isBuyZone ? '#999999' : '#212121',
              boxShadow: 'inset 0px -3px 0px 0px #767E85'
            }}
          >
            <span
              style={{
                fontSize: fitSize(12),
                color: title === '福利专区' && isBuyZone ? colors.palette.neutral200 : colors.palette.neutral100,
                fontWeight: 500,
                fontFamily: fontName.PinFangSCSemibold
              }}
            >
              去扭蛋
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
