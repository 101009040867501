const env = {
  prod: {
    BASE_URL: "https://niudanji-api.cbatime.com",
  },
  test: {
    BASE_URL: "https://niudanji.test.cbatime.com.cn",
  },
  local: {
    BASE_URL: "https://niudanji.test.cbatime.com.cn",
  },
};

module.exports.env = env;
