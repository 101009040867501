import React, { FC, useEffect } from "react";
import Modal from "react-modal";
import { Images, colors, fitSize, windowHeight, windowWidth } from "../theme";
import { WebVideoCompatibility } from "../components";
import {
  Location,
  NavigationType,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { Touchable } from "../components/Button";

interface PrizeDrawModalProps {
  src: string;
  open: boolean;
  onClose: () => void;
  onVideoEnd?: () => void;
}

export const useBackListener = (callback: () => void) => {
  const location: Location = useLocation();
  const navType: NavigationType = useNavigationType();

  useEffect(() => {
    if (navType === "POP" && location.key !== "default") {
      callback();
    }
  }, [location]);
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: windowWidth,
    height: windowHeight,
    backgroundColor: colors.palette.neutral900 + "a6",
  },
  overlay: { zIndex: 1000 },
};

export const PrizeDrawModal: FC<PrizeDrawModalProps> = ({
  open = false,
  onClose = () => {},
  src = "",
  onVideoEnd = () => {},
}) => {
  const location: Location = useLocation();
  const navType: NavigationType = useNavigationType();

  useEffect(() => {
    if (navType === "POP" && location.key !== "default") {
      onClose();
    }
  }, [location]);

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById("root") as HTMLElement}
    >
      {/* <Touchable
        style={{
          position: "absolute",
          zIndex: 2147483647,
          top: fitSize(50),
          left: 30,
        }}
        onClick={onClose}
      >
        <img
          src={Images.component.header_more}
          alt="Close"
          style={{ width: fitSize(25), height: fitSize(25) }}
        />
      </Touchable> */}
      <WebVideoCompatibility
        uri={src}
        isPaused={false}
        muted={true}
        loop={false}
        w={windowWidth}
        h={windowHeight}
        onVideoEnd={onVideoEnd}
      />
    </Modal>
  );
};
