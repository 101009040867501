import React, { useState, useEffect, ReactElement, Dispatch, useContext, useRef } from 'react'
import { Images, fitSize, fontName } from '../../../theme'
import { CarouselContext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { RealTimeItem } from '../../../services/api'

interface RealTimePlaybackProps {
  wsData: RealTimeItem[]
}
const LEVEL_BG = {
  1: Images.activityNew.level_normal,
  2: Images.activityNew.level_rare,
  3: Images.activityNew.level_epic,
  4: Images.activityNew.level_legend
}

const LEVEL_LABEL = {
  1: Images.activityNew.level_normal_name,
  2: Images.activityNew.level_rare_name,
  3: Images.activityNew.level_epic_name,
  4: Images.activityNew.level_legend_name
}

export const RealTimePlayback: React.FC<RealTimePlaybackProps> = ({ wsData }): ReactElement => {
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <div
      style={{
        width: fitSize(184),
        height: fitSize(100),
        position: 'absolute',
        marginTop: fitSize(20),
        marginLeft: fitSize(15),
        zIndex: 1
      }}
    >
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={16}
        totalSlides={wsData.length}
        isPlaying={wsData.length > 3}
        interval={5000}
        orientation={'vertical'}
        infinite={true}
        visibleSlides={3}
        touchEnabled={false}
        dragEnabled={false}
      >
        <Content list={wsData} setCurrentIndex={setCurrentIndex} currentIndex={currentIndex} />
      </CarouselProvider>
    </div>
  )
}

const Content = ({
  list,
  setCurrentIndex,
  currentIndex
}: {
  list: RealTimeItem[]
  setCurrentIndex: Dispatch<React.SetStateAction<number>>
  currentIndex: number
}) => {
  const carouselContext = useContext(CarouselContext)
  const [data, setData] = useState<RealTimeItem[]>([])

  useEffect(() => {
    setData(list)
  }, [list])

  useEffect(() => {
    function onChange() {
      setCurrentIndex(carouselContext?.state?.currentSlide)
    }
    carouselContext?.subscribe(onChange)
    return () => carouselContext?.unsubscribe(onChange)
  }, [carouselContext])

  return (
    <Slider>
      {data?.map((item, index) => {
        return (
          <Slide
            index={index}
            key={index}
            style={{
              minWidth: fitSize(154)
            }}
          >
            <CarouselSlide item={item} />
          </Slide>
        )
      })}
    </Slider>
  )
}

const CarouselSlide = ({ item }: { item: RealTimeItem }) => {
  const spanRef = useRef(null)

  const [width, setWidth] = useState(0)

  useEffect(() => {
    setWidth(spanRef.current?.offsetWidth + fitSize(85) || fitSize(185))
  }, [item, spanRef])

  return (
    <div
      style={{
        display: 'flex',
        height: fitSize(24),
        backgroundImage: `url(${LEVEL_BG[item?.award_type]})`,
        backgroundSize: `${width}px 100%`,
        backgroundRepeat: 'no-repeat',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <span
        ref={spanRef}
        style={{
          fontSize: fitSize(11),
          color: '#ffffff',
          fontWeight: 400,
          fontFamily: fontName.PingFangSC,
          marginLeft: fitSize(8),
          display: 'inline-block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: fitSize(100)
        }}
      >{`恭喜${item?.nick_name} 获得`}</span>
      <div
        style={{
          width: fitSize(39),
          height: fitSize(11),
          backgroundImage: `url(${LEVEL_LABEL[item?.award_type]})`,
          backgroundSize: '100% 100%',
          marginLeft: fitSize(5)
        }}
      />
      <div
        style={{
          width: fitSize(20),
          height: fitSize(20),
          borderRadius: '50%',
          backgroundColor: '#ffffff',
          backgroundImage: `url(${item?.award_img})`,
          backgroundSize: '100% 100%',
          marginLeft: fitSize(2)
        }}
      />
    </div>
  )
}
