import { observer } from "mobx-react-lite";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./index.module.css";
import { SimpleHeader } from "../../components/SimpleHeader";
import { Images, colors, fitSize, fontName, windowWidth } from "../../theme";
import dayjs from "dayjs";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LuckyDrawRecordItem, OrderBuyItem, api } from "../../services/api";
import { GToast } from "../../navigators";
import ReactLoading from "react-loading";
import InfiniteScroll from "react-infinite-scroll-component";
import { EmptyItem } from "../../components/EmptyItem";
import { Touchable } from "../../components/Button";
import { TipModal } from "./components/TipModal";

export function groupAndFlattenByFieldWithTotal<T>(
  arr: T[],
  fieldName: keyof T,
  contentFieldName?: keyof T
): { title: string; type: string; value: T[] }[] {
  arr.sort((a, b) => {
    const fieldValueA = String(a[fieldName]);
    const fieldValueB = String(b[fieldName]);
    return fieldValueA.localeCompare(fieldValueB);
  });

  const groupedArray: { title: string; type: string; value: T[] }[] = [];
  let currentGroup: T[] = [];
  let currentType = arr[0][fieldName] as string;

  for (const item of arr) {
    if (item[fieldName] === currentType) {
      currentGroup.push(item);
    } else {
      if (currentGroup.length > 0) {
        groupedArray.push({
          title: currentType,
          type: item[contentFieldName] as string,
          value: currentGroup,
        });
      }
      currentGroup = [item];
      currentType = item[fieldName] as string;
    }
  }

  if (currentGroup.length > 0) {
    groupedArray.push({
      title: currentType,
      type: currentGroup[0][contentFieldName] as string,
      value: currentGroup,
    });
  }

  return groupedArray;
}

interface LuckyDrawRecordProps {}

export const LuckyDrawRecord: FC<LuckyDrawRecordProps> = observer(() => {
  useDocumentTitle("扭蛋记录");
  const { id } = useParams();
  const [data, setData] = useState<LuckyDrawRecordItem[]>([]);
  const [type, setType] = useState(id || 1);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const PAGE_SIZE = 20;

  const loadMoreData = () => {
    if (hasNextPage) {
      fetchGetLuckyDrawRecord();
    }
  };

  const fetchGetLuckyDrawRecord = async () => {
    try {
      const res = await api.getLuckyDrawRecord(page, PAGE_SIZE);
      if (res.kind === "ok") {
        if (res.data.list.length > 0) {
          setData((val) => [...val, ...res.data.list]);
          setPage(page + 1);
          setHasNextPage(page * PAGE_SIZE < res.data.total);
        } else {
          setHasNextPage(false);
        }
      } else {
        GToast.current.handleErrorResponse(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onRefresh = async () => {
    try {
      const res = await api.getLuckyDrawRecord(1, PAGE_SIZE);
      if (res.kind === "ok") {
        if (res.data.list.length > 0) {
          setData(res.data.list);
          setPage(page + 1);
          setHasNextPage(page * PAGE_SIZE < res.data.total);
        } else {
          setHasNextPage(false);
        }
      } else {
        GToast.current.handleErrorResponse(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setPage(1);
    setHasNextPage(true);
    setData([]);
    onRefresh();
  }, []);

  return (
    <div className={styles.container}>
      {/* <SimpleHeader title="扭蛋记录" /> */}
     
      <div className={styles.content} id="scrollableDiv">
        <EmptyItem
          isShow={data.length === 0}
          text={"还没有扭蛋记录"}
          type="not_coupon"
        />
        <InfiniteScroll
          dataLength={data.length}
          next={loadMoreData}
          hasMore={hasNextPage}
          // endMessage={
          //   <p style={{ textAlign: "center" }}>
          //     <b>已经到最底下啦.....</b>
          //   </p>
          // }
          loader={
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ReactLoading
                type={"bubbles"}
                color={"#62FFD0"}
                height={50}
                width={50}
              />
            </div>
          }
          scrollableTarget="scrollableDiv"
          style={{
            display: data.length > 0 ? "flex" : "none",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {data?.map((item, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  minHeight: fitSize(13),
                  padding: `${fitSize(12)} ${fitSize(14)} ${fitSize(14)}`,
                  backgroundColor: colors.palette.neutral100,
                  marginBottom: fitSize(10),
                }}
                key={index}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    style={{
                      fontSize: fitSize(14),
                      fontWeight: 500,
                      color: colors.palette.neutral30,
                      fontFamily: fontName.PinFangSCSemibold,
                      marginLeft: fitSize(14),
                      marginTop: fitSize(12),
                      marginBottom: fitSize(12),
                    }}
                  >
                    {item?.niudanji_name}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    // position: "relative",
                  }}
                >
                  {item?.awardList?.map((val, k) => {
                    return (
                      <div
                        style={{
                          width: fitSize(347),
                          height: fitSize(109),
                          display: "flex",
                          flexDirection: "row",
                          borderTop: "1px solid #F5F5F8",
                          alignSelf: "center",
                        }}
                        key={k}
                        onClick={() => {
                          navigate(`/lucky-draw-details/${val.order_no}`, {
                            state: {
                              award: val ?? {},
                            },
                          });
                        }}
                      >
                        <LazyLoadImage
                          alt={""}
                          height={fitSize(81)}
                          src={
                            val?.business_type === 1
                              ? val?.award_img
                              : val.business_type === 2
                              ? Images.activityNew.item_card
                              : val?.business_type === 3
                              ? Images.activityNew.coupon_card
                              : ""
                          }
                          width={fitSize(81)}
                          effect="blur"
                          wrapperProps={{
                            style: { transitionDelay: "0.3s" },
                          }}
                          style={{
                            objectFit: "scale-down",
                            border: "1px solid #F5F5F8",
                            marginTop: fitSize(14),
                            marginRight: fitSize(12),
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                            marginTop: fitSize(14),
                            marginBottom: fitSize(14),
                            marginRight: fitSize(14),
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              fontSize: fitSize(13),
                              fontWeight: 400,
                              color: colors.palette.neutral30,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: fitSize(220),
                            }}
                          >
                            {val?.award_name}
                          </span>
                          <span
                            style={{
                              fontSize: fitSize(11),
                              fontWeight: 400,
                              color: "#aaaaaa",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: fitSize(220),
                              marginTop: 1,
                              marginBottom: 1,
                            }}
                          >
                            x1
                          </span>
                          <span
                            style={{
                              fontSize: fitSize(11),
                              fontWeight: 400,
                              color: "#aaaaaa",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: fitSize(220),
                              marginBottom: fitSize(7),
                            }}
                          >
                            {dayjs(val?.create_time * 1000).format(
                              "YYYY.MM.DD HH:mm:ss"
                            )}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
});
