import { observer } from 'mobx-react-lite'
import { FC, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './index.module.css'
import { Images, fitSize } from '../../theme'

interface ActivityPosterProps {}

export const ActivityPoster: FC<ActivityPosterProps> = observer(() => {
  const navigate = useNavigate()
  const params = useParams()
  // 返回
  const onBack = () => {
    navigate(-1)
  }
  // 立即参与
  const onJoin = () => {
    navigate(`/activity-new/${params.id}`)
  }
  // 展开活动规则
  const showRules = () => {
    setShow(!show)
  }

  const content = [
    {
      title: 'post_03',
      children: ['2024年8月9日——2024年8月15日']
    },
    {
      title: 'post_04',
      children: [
        '用户需登录Metro大都会APP并授权进入扭蛋机活动页面。'
        // '免费扭蛋机每日00：00刷新，每位用户每天仅有1次抽取机会，当天有效，不可累积（使用道具除外）。',
        // '福利扭蛋机仅适用于【未付费抽取过扭蛋】的用户，且仅限使用1次。',
        // '活动期间连续7天抽取免费扭蛋机，系统将在第8天18:00前赠送用户指定付费扭蛋机免费使用券，有效期截至2024年7月31日24:00，过期不可使用。'
      ]
    },
    {
      title: 'post_05',
      children: [
        '不同扭蛋机奖池内的奖励不一，包括乘车券、M豆、实物礼品等。',
        `
        虚拟奖品：若抽中，可在【我的】页面—虚拟奖品中查看（乘车券也可在大都会-我的-优惠券中找到）；
        优惠券、乘车券等虚拟奖品有使用期限，过期自动作废。
        `,
        '实物奖品：若抽中，可在【提货】页面查看所有待提货的实物奖品。',
        `
        提货与物流：<br>
        a.点击所需提货的物品下方【提货】按钮，并输入 地址即可完成兑换；<br>
        b.实物礼品成功兑换后，系统将在3-7个工作日内完成发货，您可在【我的】-【我的订单】查看物流状态和单号。
        `
      ]
    },
    {
      title: 'post_06',
      children: [
        '活动期间，同一用户ID仅限使用一个账户参与。',
        '您需确保提供的个人信息真实有效，以便顺利领取奖励。',
        '严禁任何形式的作弊行为，一经发现，将取消其参与资格及所获奖励。',
        '如遇到任何问题，您可通过扭蛋机内的客服入口进行咨询与反馈。',
        '活动最终解释权归本公司所有。'
      ]
    }
  ]

  const [show, setShow] = useState(false)

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${Images.poster.post_bg})` }}>
      <div
        onClick={onBack}
        style={{
          paddingLeft: fitSize(14),
          paddingTop: fitSize(60)
        }}
      >
        <img src={Images.poster.post_back}></img>
      </div>
      <div
        style={{
          paddingTop: fitSize(119),
          paddingBottom: fitSize(100)
        }}
      >
        <img src={Images.poster.post_01} style={{ height: fitSize(45) }} />
        <div
          className={styles.item}
          style={{
            width: fitSize(323),
            borderRadius: fitSize(18),
            padding: fitSize(20),
            marginTop: fitSize(16),
            marginBottom: fitSize(24),
            fontSize: fitSize(12)
          }}
        >
          <div>
            <div>
              <div style={{ display: 'flex', paddingBottom: fitSize(8) }}>
                <div
                  className={styles.dot}
                  style={{
                    width: fitSize(15),
                    height: fitSize(15),
                    marginRight: fitSize(5)
                  }}
                >
                  1
                </div>
                活动期间，我们会给幸运用户随机赠送惊喜折扣券——六折券。该券适用于所有类型的扭蛋机，有效时间为24小时。
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  className={styles.dot}
                  style={{
                    width: fitSize(15),
                    height: fitSize(15),
                    marginRight: fitSize(5)
                  }}
                >
                  2
                </div>
                活动期间，所有扭蛋机奖品迎来一波大更新，苹果全家桶，华为手表等，更多礼遇等待着您。
              </div>
            </div>
          </div>
        </div>
        {/* <img src={Images.poster.post_02} style={{ height: fitSize(45) }} />
        <div
          className={styles.item}
          style={{
            width: fitSize(323),
            borderRadius: fitSize(18),
            padding: fitSize(20),
            marginTop: fitSize(16),
            marginBottom: fitSize(24),
            fontSize: fitSize(12)
          }}
        >
          <div>
            <div>活动期间，所有扭蛋机奖品迎来一波大更新，苹果全家桶，华为手表等，更多礼遇等待着您。</div>
          </div>
        </div> */}
        <div className={styles.flex}>
          <img src={Images.poster.post_join} style={{ width: fitSize(347), paddingBottom: fitSize(20), paddingTop: fitSize(20) }}></img>
        </div>
        {/* 活动规则按钮 */}
        <div className={styles['rules-btn']}>
          <img src={Images.poster.post_btn} style={{ width: fitSize(204), height: fitSize(63) }} />
        </div>
        {/* 活动规则内容*/}
        <div className={styles.rules}>
          {content.map((item, index) => {
            return (
              <div key={index}>
                <img src={Images.poster[item.title]} style={{ height: fitSize(45) }} />
                <div
                  className={styles.item}
                  style={{
                    width: fitSize(323),
                    borderRadius: fitSize(18),
                    padding: fitSize(20),
                    marginTop: fitSize(16),
                    marginBottom: fitSize(24),
                    fontSize: fitSize(12)
                  }}
                >
                  {item.children?.map((child, i) => {
                    return (
                      <div key={`${index}${i}`}>
                        {item.children?.length > 1 ? (
                          <div
                            className={styles.dot}
                            style={{
                              width: fitSize(15),
                              height: fitSize(15),
                              marginRight: fitSize(5)
                            }}
                          >
                            {i + 1}
                          </div>
                        ) : (
                          ''
                        )}
                        {item.children?.length - 1 != i ? (
                          <div style={{ paddingBottom: fitSize(8) }} dangerouslySetInnerHTML={{ __html: child }}></div>
                        ) : (
                          <div dangerouslySetInnerHTML={{ __html: child }}></div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {/* 参与按钮 */}
      <div
        className={styles.join}
        onClick={onJoin}
        style={{
          paddingTop: fitSize(14),
          paddingBottom: fitSize(32),
          fontSize: fitSize(20)
        }}
      >
        立即参与
      </div>
    </div>
  )
})
