import React, { useEffect, useContext, useState, Dispatch } from 'react'
import { CarouselProvider, Slider, Slide, CarouselContext } from 'pure-react-carousel'
import { Images, colors, fitSize, fontName, windowWidth } from '../../../theme'
import './index.css'
import { Touchable } from '../../../components/Button'
import { BannerItem } from '../../../services/api'
import { useNavigate } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Content = ({
  list,
  setCurrentIndex,
  currentIndex
}: {
  list: BannerItem[]
  setCurrentIndex: Dispatch<React.SetStateAction<number>>
  currentIndex: number
}) => {
  const carouselContext = useContext(CarouselContext)
  const [data, setData] = useState<BannerItem[]>([])

  useEffect(() => {
    setData(list)
  }, [list])

  useEffect(() => {
    function onChange() {
      setCurrentIndex(carouselContext?.state?.currentSlide)
    }
    carouselContext?.subscribe(onChange)
    return () => carouselContext?.unsubscribe(onChange)
  }, [carouselContext])

  return (
    <Slider
      style={{
        paddingTop: fitSize(75),
        // marginLeft: fitSize(windowWidth - 307) / 4,
        marginLeft: fitSize(14),
        width: fitSize(337),
        height: fitSize(154.75)
      }}
    >
      {data?.map((item, index) => {
        return (
          <Slide index={index} key={index}>
            <CarouselSlide url={item?.img} link={item?.url} type={item?.url_type} />
          </Slide>
        )
      })}
    </Slider>
  )
}

export const CarouselWithIndicator = ({ list }: { list: BannerItem[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <div
      style={{
        width: '100%',
        height: fitSize(200),
        alignSelf: 'center',
        position: 'relative'
      }}
    >
      {/* <img
        src={Images.home.home_top}
        alt=""
        style={{
          width: fitSize(90),
          height: fitSize(101),
          zIndex: 0,
          position: "absolute",
          top: 13,
          right: fitSize(49),
        }}
      /> */}
      <div
        style={{
          borderRadius: fitSize(6),
          width: fitSize(337),
          height: fitSize(145),
          right: fitSize(14),
          top: fitSize(83),
          backgroundColor: colors.palette.neutral100,
          position: 'absolute',
          zIndex: 0,
          borderWidth: fitSize(1),
          borderStyle: 'solid',
          borderColor: '#212F3B',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'self-start',
          justifyContent: 'flex-end'
        }}
      >
        {/* <span
          style={{
            fontSize: fitSize(9),
            fontFamily: fontName.PingFangSC,
            color: "#767E85",
            fontWeight: 400,
            marginLeft: fitSize(10),
            marginBottom: fitSize(1),
          }}
        >
          本产品由伊利牛奶倾情赞助
        </span> */}
      </div>
      <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={100} totalSlides={list.length} isPlaying={true} interval={5000}>
        <Content list={list} setCurrentIndex={setCurrentIndex} currentIndex={currentIndex} />
        {/* <div
          style={{
            position: "relative",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DotGroup className={"prc-dotGroup"} />
        </div> */}
      </CarouselProvider>
    </div>
  )
}

const CarouselSlide = ({ url, link, type }: { url: string; link: string; type: number }) => {
  const navigate = useNavigate()
  return (
    <Touchable
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: fitSize(337),
        height: fitSize(144.75)
      }}
      onClick={() => {
        if (link.includes('http')) {
          window.open(link)
        } else {
          navigate(link)
        }
      }}
    >
      <div
        style={{
          borderRadius: fitSize(6),
          objectFit: 'cover',
          width: fitSize(346.83),
          height: '100%',
          zIndex: 2,
          backgroundImage: `url(${url})`,
          backgroundSize: '100% 100%'
        }}
      />
    </Touchable>
  )
}
