import React from "react";
import debounce from "lodash.debounce";

const withPreventDoubleClick = (WrappedComponent) => {
  class PreventDoubleClick extends React.PureComponent {
    debouncedOnClick = () => {
      // @ts-ignore
      this.props.onClick && this.props.onClick();
    };

    onClick = debounce(this.debouncedOnClick, 300, {
      leading: true,
      trailing: false,
    });

    render() {
      return <WrappedComponent {...this.props} onClick={this.onClick} />;
    }
  }

  // @ts-ignore
  PreventDoubleClick.displayName = `withPreventDoubleClick(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;

  return PreventDoubleClick;
};

const CustomButton = ({ children, ...props }) => {
  return <button {...props}>{children}</button>;
};

export const ButtonWithPreventDoubleClick =
  withPreventDoubleClick(CustomButton);
