import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './index.module.css'
import { SimpleHeader } from '../../components'
import { Images, colors, fitSize, fontName } from '../../theme'
import { ContentPreview, TypeEnum } from './components/ContentPreview'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ActivityDetailItem } from '../../services/api/api.types'
import { GToast } from '../../navigators'
import { api } from '../../services/api'
import { PIPModal } from '../../modal/PIPModal'
import { isVideoOrImageLink } from '../../utils/regexFunction'

interface ActivityDetailProps {}

export const ActivityDetail: FC<ActivityDetailProps> = observer(() => {
  const { id, detailId } = useParams()
  const navigate = useNavigate()
  const [info, setInfo] = useState<ActivityDetailItem>()
  const [showPIP, setShowPIP] = useState(false)
  const [currentVideo, setCurrentVideo] = useState('')
  const [stayClose, setStayClose] = useState(false)

  useEffect(() => {
    const fetchGetActivityInfo = async () => {
      try {
        const res = await api.getActivityDetail(detailId)
        if (res.kind === 'ok') {
          setInfo(res.data)
          window._hmt.push(['_trackEvent', 'page', 'view', `商品详情:${res.data.name}`])
          window.document.title = res.data.name ?? ''
        } else {
          GToast.current.handleErrorResponse(res)
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    fetchGetActivityInfo()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const homeScrollElement = document.getElementById('barScroll')
    if (!homeScrollElement) {
      return
    }

    const handleScroll = () => {
      const scrollDistance = homeScrollElement.scrollTop
      if (scrollDistance > 550) {
        setShowPIP(true)
      } else {
        setShowPIP(false)
      }
    }

    homeScrollElement.addEventListener('scroll', handleScroll)
    return () => {
      homeScrollElement.removeEventListener('scroll', handleScroll)
    }
  }, [stayClose])

  const combinedArray = [
    ...(info?.videoes && info.videoes !== ''
      ? info.videoes.split(',').map(url => ({
          url: url.trim(),
          isPaused: true,
          isVideo: true
        }))
      : []),
    ...(info?.banner_imgs && info.banner_imgs !== ''
      ? info.banner_imgs.includes(',')
        ? info.banner_imgs.split(',').map(url => ({
            url: url.trim(),
            isPaused: true,
            isVideo: false
          }))
        : [
            {
              url: info?.banner_imgs,
              isPaused: true,
              isVideo: false
            }
          ]
      : [])
  ]

  return (
    <div className={styles.container}>
      {/* <SimpleHeader
        title={info?.name}
        textStyle={{
          display: "inline-block",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: fitSize(130),
        }}
      /> */}
      {showPIP && !stayClose && isVideoOrImageLink(currentVideo) === 'video' ? (
        <PIPModal
          onClose={() => {
            setStayClose(true)
            setShowPIP(false)
          }}
          video={currentVideo}
        />
      ) : null}
      <div className={styles.content_detail} id="barScroll">
        <ContentPreview
          list={combinedArray}
          type={TypeEnum.countBox}
          height={fitSize(375)}
          showBackgroundImage={false}
          setCurrentVideo={setCurrentVideo}
        />
        <div
          style={{
            width: fitSize(355),
            minHeight: 'auto',
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: colors.palette.neutral100,
            boxShadow: 'inset 0px -6px 0px 0px #62FFD0',
            paddingBottom: fitSize(12),
            marginTop: fitSize(10),
            marginBottom: fitSize(10),
            border: '2px solid #212F3B'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: fitSize(12),
              marginLeft: fitSize(12),
              alignItems: 'center',
              marginBottom: fitSize(5)
            }}
          >
            <span
              style={{
                fontSize: fitSize(11),
                fontWeight: 600,
                color: colors.palette.neutral30,
                marginRight: fitSize(6),
                fontFamily: fontName.PinFangSCSemibold
              }}
            >
              {info?.is_not_sale === 0 ? '参考价' : '非卖品'}
            </span>
            <div
              style={{
                display: info?.is_not_sale === 0 ? 'flex' : 'none',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <span
                style={{
                  fontSize: fitSize(19),
                  fontWeight: 600,
                  color: colors.palette.neutral30,
                  fontFamily: fontName.PinFangSCSemibold,
                  marginRight: fitSize(2)
                }}
              >
                ¥
              </span>
              <span
                style={{
                  fontSize: fitSize(19),
                  fontWeight: 600,
                  color: colors.palette.neutral30,
                  fontFamily: fontName.PinFangSCSemibold
                }}
              >
                {(info?.current_price / 100).toFixed(2)}
              </span>
            </div>
          </div>
          <span
            style={{
              fontSize: fitSize(13),
              fontWeight: 600,
              color: colors.palette.neutral30,
              fontFamily: fontName.PinFangSCSemibold,
              marginLeft: fitSize(12),
              overflow: 'hidden',
              overflowWrap: 'break-word',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical'
            }}
          >
            {info?.name}
          </span>
        </div>
        <div
          style={{
            width: fitSize(355),
            minHeight: 'auto',
            backgroundColor: colors.palette.neutral100,
            alignSelf: 'center',
            position: 'relative'
          }}
        >
          <img
            src={Images.home.new_person}
            alt=""
            style={{
              width: fitSize(63),
              height: fitSize(23),
              position: 'absolute',
              zIndex: 1,
              left: 15,
              top: 10
            }}
          />
          <div
            style={{
              width: fitSize(355),
              height: fitSize(40),
              paddingLeft: fitSize(12),
              marginTop: fitSize(11),
              zIndex: 2,
              position: 'relative'
            }}
          >
            <span
              style={{
                fontSize: fitSize(13),
                fontWeight: 600,
                color: colors.palette.neutral30,
                fontFamily: fontName.PinFangSCSemibold
              }}
            >
              商品详情
            </span>
          </div>
          {/* {info?.thumb_img.includes(",")
            ? info?.thumb_img.split(",").map((url) => url.trim())
            : [info?.thumb_img].map((item, index) => (
                <LazyLoadImage
                  alt={""}
                  key={index}
                  height={fitSize(200)}
                  src={item}
                  width={fitSize(355)}
                  effect="blur"
                  wrapperProps={{
                    style: { transitionDelay: "0.3s" },
                  }}
                  style={{
                    marginBottom: fitSize(12),
                    objectFit: "scale-down",
                  }}
                />
              ))} */}
          <div
            style={{
              width: '100%',
              flexWrap: 'wrap',
              color: '#999999',
              fontSize: fitSize(13),
              fontFamily: fontName.PingFangSC,
              fontWeight: '400',
              display: 'flex'
            }}
            className={styles.list}
            dangerouslySetInnerHTML={{
              __html: `${info?.detail}`.replace(/<p><img/g, '<p style="font-size: 0"><img') ?? ''
            }}
          />
        </div>
      </div>
    </div>
  )
})
