import React, { useState, useEffect, ReactElement } from 'react'

import styles from './index.module.css'
import { observer } from 'mobx-react-lite'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { SimpleHeader } from '../../components'
import { Images, colors, fitSize, fontName, windowWidth } from '../../theme'
import { useStores } from '../../models'
import { Touchable } from '../../components/Button'
import { useNavigate } from 'react-router-dom'

interface MoreSettingProps {}

export const MoreSetting: React.FC<MoreSettingProps> = observer(({}) => {
  useDocumentTitle('设置')
  const navigate = useNavigate()

  const reload = () => {
    var browserRule = /^.*((iPhone)|(iPad)|(Safari))+.*$/
    if (browserRule.test(navigator.userAgent)) {
      window.onpageshow = function (event) {
        if (event.persisted) {
          window.history.back()
        }
      }
    }
  }

  // useEffect(() => {
  //   reload()
  // }, [])

  return (
    <div className={styles.container}>
      {/* <SimpleHeader title="设置" /> */}
      <div className={styles.content}>
        <Touchable
          style={{
            display: 'flex',
            width: fitSize(347),
            height: fitSize(55),
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0px 14px',
            backgroundColor: colors.palette.neutral100,
            boxShadow: 'inset 0px 0px 0px 0px #F5F5F8',
            borderRadius: '2px 2px 2px 2px',
            marginTop: fitSize(14)
          }}
          onClick={() => {
            navigate('/my-frame')
            // useFlag(true)
            // window.location.href = 'https://work.weixin.qq.com/kfid/kfc6cc0ed559ab28c94'
          }}
        >
          <span
            style={{
              fontSize: fitSize(13),
              fontFamily: fontName.PingFangSC,
              fontWeight: 400,
              color: '#212F3B'
            }}
          >
            联系客服
          </span>
          <div
            style={{
              width: fitSize(14),
              height: fitSize(14),
              backgroundImage: `url(${Images.mine.mine_right})`,
              backgroundSize: '100% 100%'
            }}
          />
        </Touchable>
      </div>
    </div>
  )
})
