import { useEffect, useRef, useState } from "react";

import { api } from "../services/api";
import dayjs from "dayjs";

export function useSecond() {
  const [currentTime, setCurrentTime] = useState(() => new Date().getTime());
  const sub = useRef(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime((c) => c + 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const getTimeFromRemote = async () => {
      sub.current = true;
      const t = await api.getSystemTime();
      if (t.kind === "ok" && sub.current) {
        setCurrentTime(t.data * 1000);
      } else {
        console.log("时间获取出错，使用本地时间");
        setCurrentTime(new Date().getTime());
      }
    };
    getTimeFromRemote();
    return () => {
      sub.current = false;
    };
  }, []);

  return { currentTime };
}
