import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import styles from './index.module.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { Images, colors, fitSize, fontName } from '../../theme'
import { TakeDeliveryModal } from '../../modal/TakeDeliveryModal'
import { useNavigate } from 'react-router-dom'
import { Touchable } from '../../components/Button'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { StickinessHeader } from '../../components/StickinessHeader'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { StoreItem, api } from '../../services/api'
import { GToast } from '../../navigators'
import { EmptyItem } from '../../components/EmptyItem'

interface StoreScreenProps {}

const PAGE_SIZE = 20
export const StoreScreen: FC<StoreScreenProps> = observer(() => {
  useDocumentTitle('提货')
  const navigate = useNavigate()
  const [data, setData] = useState<StoreItem[]>([])
  const [open, setOpen] = useState(false)
  const [modalInfo, setModalInfo] = useState({
    id: 0,
    src: '',
    name: ''
  })
  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)
  const PAGE_SIZE = 20

  const loadMoreData = () => {
    if (hasNextPage) {
      fetchGetStoreData()
    }
  }

  const fetchGetStoreData = async () => {
    try {
      const res = await api.getStoreList(page, PAGE_SIZE)
      if (res.kind === 'ok') {
        if (res.data.list.length > 0) {
          setData(val => [...val, ...res.data.list])
          setPage(page + 1)
          setHasNextPage(page * PAGE_SIZE < res.data.total)
        } else {
          setHasNextPage(false)
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const onRefresh = async () => {
    try {
      const res = await api.getStoreList(1, PAGE_SIZE)
      if (res.kind === 'ok') {
        if (res.data.list.length > 0) {
          setData(res.data.list)
          setPage(page + 1)
          setHasNextPage(page * PAGE_SIZE < res.data.total)
        } else {
          setHasNextPage(false)
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    setPage(1)
    setHasNextPage(true)
    setData([])
    onRefresh()
  }, [])

  const onAcquire = item => {
    setOpen(true)
    setModalInfo({
      id: item?.exchange_no,
      src: item?.award_img,
      name: item?.award_name
    })
  }

  /**
   * TODO 提取资产
   * @param value 提取ID
   * 判断是否有地址 有则直接提取 无则提示添加地址
   */
  const fetchTakeDelivery = async (value: number) => {
    setOpen(false)
    const isAddress = false
    if (isAddress) {
    } else {
      navigate('/address-mange', {
        state: { type: 'update', value }
      })
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content} id="scrollableDiv">
        <EmptyItem isShow={data.length === 0} text={'空空如也'} type="not_all" />
        <InfiniteScroll
          dataLength={data.length}
          next={loadMoreData}
          hasMore={hasNextPage}
          loader={
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <ReactLoading type={'bubbles'} color={'#FFE442'} height={50} width={50} />
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          {/* <StickinessHeader
            title="仓库"
            isBack={true}
            id="scrollableDiv"
            isAbsolute={false}
            iconLeft={fitSize(63)}
            routeName="/"
          /> */}
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginTop: fitSize(13),
              marginBottom: fitSize(80)
            }}
          >
            {data?.map((item, index) => {
              return (
                <Touchable
                  key={index}
                  style={{
                    display: 'flex',
                    width: fitSize(168),
                    height: fitSize(234),
                    backgroundColor: colors.palette.neutral100,
                    marginLeft: fitSize(12),
                    marginBottom: fitSize(12),
                    // boxShadow: "0px 0px 13px 0px rgba(230,230,230,0.67)",
                    // borderRadius: fitSize(4),
                    flexDirection: 'column',
                    overflow: 'hidden',
                    position: 'relative'
                  }}
                  onClick={() => {
                    onAcquire(item)
                  }}
                >
                  <img
                    style={{
                      display: 'flex',
                      width: fitSize(168),
                      height: fitSize(234),
                      position: 'absolute',
                      zIndex: 1
                    }}
                    src={Images.store.store_card}
                    alt=""
                  />
                  <LazyLoadImage
                    alt={''}
                    key={index}
                    height={fitSize(166)}
                    src={item?.award_img}
                    width={fitSize(166)}
                    effect="blur"
                    wrapperProps={{
                      style: { transitionDelay: '0.3s' }
                    }}
                    style={{
                      objectFit: 'scale-down',
                      clipPath: 'polygon(0 30px, 30px 0, 100% 0, 100% 100%, 0 100%)'
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                      width: '100%'
                    }}
                  >
                    <span
                      style={{
                        width: '100%',
                        marginTop: fitSize(10),
                        marginLeft: fitSize(8),
                        fontSize: fitSize(13),
                        fontWeight: 400,
                        color: colors.palette.neutral30,
                        fontFamily: fontName.PingFangSC,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: fitSize(130)
                      }}
                    >
                      {item?.award_name}
                    </span>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <div
                        style={{
                          marginTop: fitSize(4),
                          display: 'flex',
                          alignSelf: 'flex-end',
                          width: fitSize(44),
                          height: fitSize(22),
                          borderRadius: fitSize(2),
                          backgroundColor: '#62FFD0',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: fitSize(8),
                          boxShadow: 'inset 0px -3px 0px 0px #62FFD0',
                          border: '1px solid #212F3B',
                          zIndex: 1
                        }}
                      >
                        <span
                          style={{
                            fontSize: fitSize(10),
                            fontWeight: 400,
                            color: colors.palette.neutral30,
                            fontFamily: fontName.PingFangSC
                          }}
                        >
                          提货
                        </span>
                      </div>
                    </div>
                  </div>
                </Touchable>
              )
            })}
          </div>
        </InfiniteScroll>
      </div>
      <TakeDeliveryModal
        id={modalInfo.id}
        src={modalInfo.src}
        name={modalInfo.name}
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        onTakeDelivery={fetchTakeDelivery}
      />
    </div>
  )
})
