import React, {
  Dispatch,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
  WithStore,
  CarouselContext,
} from "pure-react-carousel";
import { Images, colors, fitSize, windowWidth } from "../../../theme";
import { WebVideoCompatibility } from "../../../components";
import { isVideoOrImageLink } from "../../../utils/regexFunction";
import { Touchable } from "../../../components/Button";
import { RealTimePlayback } from "./RealTimePlayback";
import { RealTimeItem } from "../../../services/api";

export type ListItem = {
  url: string;
  isPaused: boolean;
  isVideo: boolean;
};

const Content = ({
  list,
  setCurrentIndex,
  currentIndex,
  showBackgroundImage,
  height,
  setCurrentVideo,
}: {
  list: ListItem[];
  setCurrentIndex: Dispatch<React.SetStateAction<number>>;
  currentIndex: number;
  showBackgroundImage: boolean;
  setCurrentVideo?: Dispatch<React.SetStateAction<string>>;
  height?: number;
}) => {
  const carouselContext = useContext(CarouselContext);
  const [data, setData] = useState([]);

  const setDataVideoState = () => {
    const arr: ListItem[] = data.map((item, index) => {
      if (index === currentIndex) {
        return { ...item, isPaused: !item.isPaused };
      } else {
        return { ...item, isPaused: true };
      }
    });
    setData(arr);
  };

  const setVideoPaused = () => {
    const updatedData = data.map((item, index) => {
      if (index === currentIndex) {
        return { ...item, isPaused: true };
      } else {
        return item;
      }
    });
    setData(updatedData);
  };

  useEffect(() => {
    setData(list);
  }, [list]);

  useEffect(() => {
    function onChange() {
      setCurrentIndex(carouselContext?.state?.currentSlide);
    }
    carouselContext?.subscribe(onChange);
    return () => carouselContext?.unsubscribe(onChange);
  }, [carouselContext]);

  useEffect(() => {
    setCurrentVideo && setCurrentVideo(list[currentIndex]?.url);
  }, [currentIndex]);

  return (
    <Slider>
      {data.map((item, index) => {
        return (
          <Slide index={index} key={index}>
            <PreviewSlide
              url={item.url}
              setDataVideoState={setDataVideoState}
              isPaused={item.isPaused}
              isVideo={item.isVideo}
              height={height}
              showBackgroundImage={showBackgroundImage}
            />
          </Slide>
        );
      })}
    </Slider>
  );
};

export enum TypeEnum {
  progressBar = "progressBar",
  countBox = "countBox",
}

interface ContentPreviewProps {
  list: ListItem[];
  type: TypeEnum;
  setCurrentVideo?: Dispatch<React.SetStateAction<string>>;
  showBackgroundImage?: boolean;
  height?: number;
  isWs?: boolean;
  wsData?: RealTimeItem[];
}

// 滚动抽奖组件
export const ContentPreview = ({
  list,
  type,
  showBackgroundImage = true,
  height = fitSize(552),
  setCurrentVideo,
  isWs = false,
  wsData = [],
}: ContentPreviewProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <div
      style={{
        width: "100%",
        height: height,
        backgroundImage: showBackgroundImage
          ? `url(${Images.activityNew.activity_new_bg})`
          : "",
        backgroundSize: "100% 100%",
        backgroundColor: colors.palette.neutral100,
        position: "relative",
      }}
    >
      {isWs ? <RealTimePlayback wsData={wsData} /> : null}
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={showBackgroundImage ? 145 : 100}
        totalSlides={list.length}
        visibleSlides={1}
      >
        <Content
          list={list}
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
          height={height}
          showBackgroundImage={showBackgroundImage}
          setCurrentVideo={setCurrentVideo}
        />
        {/* 滚动条 */}
        {type === TypeEnum.progressBar ? (
          <div
            style={{
              position: "relative",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              backgroundColor: colors.transparent,
              top: fitSize(-10),
              left: -fitSize(14),
            }}
          >
            <div
              style={{
                width: fitSize(347),
                height: fitSize(2),
                backgroundColor: "#ffffff66",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {Array.from({ length: list.length }).map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      width: fitSize(347 / list.length),
                      height: fitSize(2),
                      backgroundColor:
                        currentIndex === index
                          ? colors.palette.neutral100
                          : colors.transparent,
                    }}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: -35,
                right: 10,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#F5F5F8",
                borderRadius: 9,
                width: fitSize(36),
                height: fitSize(17),
                boxShadow: "0px 0px 5px 1px #F5F5F9",
                border: "1px solid #212F3B",
              }}
            >
              <span
                style={{
                  color: "#767E85",
                  fontWeight: 500,
                  fontSize: fitSize(10),
                }}
              >
                {`${currentIndex + 1}/${list.length}`}
              </span>
            </div>
          </div>
        )}
      </CarouselProvider>
    </div>
  );
};

const PreviewSlide = ({
  url,
  isPaused,
  setDataVideoState,
  showBackgroundImage,
  height,
  isVideo,
}: {
  url: string;
  isPaused: boolean;
  setDataVideoState: () => void;
  showBackgroundImage: boolean;
  isVideo: boolean;
  height?: number;
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: height,
      }}
    >
      {isPaused && isVideoOrImageLink(url) === "video" ? (
        <Touchable
          onClick={setDataVideoState}
          style={{
            zIndex: 2147483647,
            position: "absolute",
            left: fitSize(375) / 2 - fitSize(10),
            top: fitSize(375) / 1.5,
          }}
        >
          <img
            style={{
              objectFit: "scale-down",
              opacity: 0.7,
            }}
            src={Images.components.time_video_pause}
            alt=""
            width={fitSize(40)}
            height={fitSize(40)}
          />
        </Touchable>
      ) : null}
      {isVideoOrImageLink(url) === "video" ? (
        <Fragment>
          <Touchable
            onClick={setDataVideoState}
            disabled={isPaused}
            style={{
              width: "100%",
              height: "100%",
              zIndex: 2147483647,
              position: "absolute",
              display: isPaused ? "none" : "flex",
            }}
          />
          <WebVideoCompatibility
            uri={url}
            isPaused={isPaused}
            muted={true}
            loop={true}
            w={"100%"}
            h={"100%"}
            isReset={false}
          />
        </Fragment>
      ) : (
        <img
          style={{
            // objectFit: "fill",
            objectFit: "scale-down",
            width: !showBackgroundImage ? "100%" : fitSize(218),
            height: !showBackgroundImage ? "100%" : fitSize(229),
          }}
          src={encodeURI(url)}
          alt=""
          width={"100%"}
          height={"100%"}
        />
      )}
    </div>
  );
};
