import { ErrorBoundary } from "./screens/ErrorScreen/ErrorBoundary";
import Config from "./config";
import { AppNavigator } from "./navigators";
import { useInitialRootStore } from "./models";
import "pure-react-carousel/dist/react-carousel.es.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-loading-skeleton/dist/skeleton.css";
import { useEffect, useState } from "react";
import { Images, colors, fitSize, windowHeight, windowWidth } from "./theme";

const App = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { rehydrated } = useInitialRootStore(() => {});

  const handleResize = () => {
    setIsMobile(window.innerWidth < 769);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!rehydrated) return null;
  if (!isMobile) {
    return (
      <div
        style={{
          display: "flex",
          width: windowWidth,
          height: windowHeight,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={Images.component.pc_not_render}
          alt=""
          style={{ width: fitSize(300), height: fitSize(213) }}
        />
        <span
          style={{
            fontSize: fitSize(16),
            fontWeight: 400,
            color: colors.palette.neutral30,
            marginTop: fitSize(30),
          }}
        >
          请前往手机浏览器内展示
        </span>
      </div>
    );
  }

  return (
    <ErrorBoundary catchErrors={Config.catchErrors}>
      <AppNavigator />
    </ErrorBoundary>
  );
};

export default App;
