const IP = `http`
export const Images = {
  test: {
    alert_icon: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/alert_icon.png`
  },
  components: {
    header_back: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/header_back.png`,
    back_white: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/back_white.png`,
    close: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/close.png`,
    modal_back: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/modal_back.png`,
    free_coupon_modal: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/free_coupon_modal.png`,
    paid_coupon_modal: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/paid_coupon_modal.png`,
    time_video_pause: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/time_video_pause.png`,
    video_pause: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/video_pause.png`,
    not_coupon: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/not_coupon.png`,
    not_order: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/not_coupon.png`,
    not_data: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/not_data.png`,
    not_network: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/not_network.png`,
    not_all: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/not_all.png`,
    coupon_usable_card: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/coupon_usable_card.png`,
    lucky_tip: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/lucky_tip.png`
  },
  common: {
    ok: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/ok.png`
  },
  tab: {
    home_a: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/tab_home_a.png`,
    home_u: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/tab_home_u.png`,
    store_a: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/tab_store_a.png`,
    store_u: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/tab_store_u.png`,
    mine_a: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/tab_mine_a.png`,
    mine_u: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/tab_mine_u.png`
  },
  component: {
    header_more: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/header_more.png`,
    pc_not_render: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/pc_not_render.png`
  },
  home: {
    new_person_card: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/new_person_card.png`,
    time_limit_card: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/time_limit_card.png`,
    card_percent: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/card_percent.png`,
    home_top: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/home_top.png`,
    go_back: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/go_back.png`,
    home_logo: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/home_logo.png`,
    more: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/more.png`,
    gratuitous_goods_card: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/gratuitous_goods_card.png`,
    new_person: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/new_person.png`,
    hot_sale: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/hot_sale.png`,
    home_hot_btn: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/home_hot_btn.png`,
    home_bar: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/home_bar.png`,
    border: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/border.png`,
    lucky_draw_bg: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/lucky_draw_bg.png`,
    home_bar2: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/home_bar2.png`,
    auto_list: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/auto_list.png`,
    continue_btn: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/continue_btn.png`
  },
  store: {
    store_extract: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/store_extract.png`,
    store_card: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/store_card.png`,
    store_modal: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/store_modal.png`,
    store_modal_bg1: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/store_modal_bg1.png`,
    store_modal_bg2: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/store_modal_bg2.png`,
    address_btn: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/address_btn.png`,
    save_btn: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/save_btn.png`
  },
  mine: {
    mine_bg: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/mine_bg.png`,
    record_list: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/record_list.png`,
    prize_redemption: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/prize_redemption.png`,
    customer_service: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/customer_service.png`,
    address_list: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/address_list.png`,
    wait_for_delivery: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/wait_for_delivery.png`,
    receive_goods: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/receive_goods.png`,
    coupon: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/coupon.png`,
    item_card: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/item_card.png`,
    mine_right: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/mine_right.png`,
    address_edit: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/address_edit.png`,
    address_check_a: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/address_check_a.png`,
    address_check_u: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/address_check_u.png`,
    card1: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/card1.png`,
    card2: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/card2.png`,
    card_bg1: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/card_bg1.png`,
    card_bg2: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/card_bg2.png`,
    stage_property_card: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/stage_property_card.png`,
    coupon_usable: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/coupon_usable.png`,
    mine_more: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/mine_more.png`,
    tab_a: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/tab_a.png`,
    coupon_u: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/coupon_u.png`,
    coupon_a: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/coupon_a.png`,
    coupon_usable_top: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/coupon_usable_top.png`,
    line: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/line.png`,
    exchange_modal: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/exchange_modal.png`,
    order_right: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/order_right.png`,
    type_received_address: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/type_received_address.png`,
    type_received: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/type_received.png`,
    user_logo: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/user_logo.png`,
    wechat: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/wechat.png`
  },
  activityNew: {
    activity_new_bar: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/activity_new_bar.png`,
    legend: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/legend.png`,
    epic: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/epic.png`,
    rare: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/rare.png`,
    normal: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/normal.png`,
    legend_label: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/legend_label.png`,
    epic_label: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/epic_label.png`,
    rare_label: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/rare_label.png`,
    normal_label: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/normal_label.png`,
    halo: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/halo.png`,
    replace: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/replace.png`,
    halo2: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/halo2.png`,
    blind_box: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/blind_box.png`,
    activity_new_bg1: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/activity_new_bg1.jpg`,
    activity_new_bg: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/activity_new_bg.jpg`,
    activity_new_bg2: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/activity_new_bg2.jpg`,
    activity_new_title: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/activity_new_title.png`,
    single_draw_button: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/single_draw_button.png`,
    take_draw_button1: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/take_draw_button1.png`,
    single_draw_button1: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/single_draw_button1.png`,
    more_draw_button: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/more_draw_button.png`,
    take_draw_button: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/more_draw_button.png`,
    more_draw_button1: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/more_draw_button1.png`,
    lang_btn: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/lang_btn.png`,
    n_select: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/n_select.png`,
    y_select: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/y_select.png`,
    ali_pay: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/ali_pay.png`,
    wx_pay: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/wx_pay.png`,
    order_btn: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/order_btn.png`,
    useCard: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/use_card.png`,
    use_card_close: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/use_card_close.png`,
    halo_bg: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/halo_bg.png`,
    bowknot: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/bowknot.png`,
    discount_modal: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/discount_modal.png`,
    prop_modal: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/prop_modal.png`,
    item_card: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/item_card1.png`,
    coupon_card: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/coupon_card.png`,
    level_epic: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/level_epic.png`,
    level_legend: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/level_legend.png`,
    level_rare: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/level_rare.png`,
    level_normal: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/level_normal.png`,
    level_epic_name: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/level_epic_name.png`,
    level_legend_name: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/level_legend_name.png`,
    level_rare_name: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/level_rare_name.png`,
    level_normal_name: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/level_normal_name.png`,
    tip_btn: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/tip_btn.png`,
    text_00: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/text_00.png`,
    text_01: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/text_01.png`,
    text_02: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/text_02.png`,
    text_03: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/text_03.png`,
    text_04: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/text_04.png`,
    text_05: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/text_05.png`,
    egg_bg: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/egg_bg.png`
  },
  poster: {
    post_back: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/post_back.png`,
    post_btn: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/post_btn.png`,
    post_01: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/post_01.png`,
    post_02: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/post_02.png`,
    post_03: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/post_03.png`,
    post_04: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/post_04.png`,
    post_05: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/post_05.png`,
    post_06: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/post_06.png`,
    post_join: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/post_join.png`,
    post_bg: `${IP}://ndj.img.cbatime.com.cn/niudanji_static/images/post_bg.png`
  }
}
