import React, { useRef, useState } from "react";
import Draggable from "react-draggable";
import classes from "./index.module.css";
import { fitSize, windowHeight, windowWidth } from "../theme";

const centerY = (windowHeight - fitSize(250)) / 2;
const centerX = windowWidth - fitSize(150);
export const PIP = ({ children }) => {
  const [position, setPosition] = useState({ x: centerX, y: centerY });

  const handleBounds = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const padding = 0;
    const minWidth = fitSize(150);
    const minHeight = fitSize(250);

    const maxX = screenWidth - minWidth - padding;
    const maxY = screenHeight - minHeight - padding;

    return {
      top: padding,
      right: Math.min(position.x + minWidth, maxX),
      bottom: Math.min(position.y + minHeight, maxY),
      left: padding,
    };
  };

  const handleDrag = (e, ui) => {
    const { x, y } = ui;
    setPosition({ x, y });
  };

  return (
    <Draggable position={position} bounds={handleBounds()} onDrag={handleDrag}>
      <div className={classes.pipWindow}>{children}</div>
    </Draggable>
  );
};
