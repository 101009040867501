import { PhoneNumberUtil } from "google-libphonenumber";

export const phoneUtil = PhoneNumberUtil.getInstance();

/**
 * 辅助函数：验证电话号码是否正确 确保是一个全球性电话号码
 * @param phoneNumber string
 * @returns 返回 true 或 false
 */
export function isValidPhoneNumber(phoneNumber: string) {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, "CN");
    return phoneUtil.isValidNumber(number);
  } catch (error) {
    return false;
  }
}

/**
 * 辅助函数：验证日期是否正确 确保日期存在并且格式为 DD/MM/YYYY
 * @param dateBirth string 辅助函数：验证日期是否正确
 * @returns 返回 true 或 false
 */
export function isValidDateBirth(dateBirth: string) {
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  return dateRegex.test(dateBirth);
}

/**
 * 辅助函数：验证短信长度是不是 6 位
 * @param code string
 * @returns 返回 true 或 false
 * @description 用于验证短信验证码长度是否为 6 位
 */
export function isValidCode(code: string) {
  const codeRegex = /^\d{6}$/;
  return codeRegex.test(code);
}

/**
 * 辅助函数：必须是6-20个字符之间，包含英文字母至少一个大写字母一个小写字母+数字+标点符号（除空格）
 * @param password string
 * @returns 返回 true 或 false
 * @description 用于验证密码长度是否为 6-20 位
 */
export function isValidPassword(password: string) {
  if (password.length < 6 || password.length > 20) {
    return false;
  }
  if (password.length < 6 || password.length > 20) {
    return false;
  }
  let hasUppercase = false;
  let hasLowercase = false;
  let hasNumber = false;
  let hasPunctuation = false;

  for (let i = 0; i < password.length; i++) {
    const char = password[i];
    if (/[A-Z]/.test(char)) {
      hasUppercase = true;
    } else if (/[a-z]/.test(char)) {
      hasLowercase = true;
    } else if (/\d/.test(char)) {
      hasNumber = true;
    } else if (/[^\w\s]/.test(char)) {
      hasPunctuation = true;
    }
  }
  return hasUppercase && hasLowercase && hasNumber && hasPunctuation;
}

export function isVideoOrImageLink(link: string) {
  // 正则表达式模式，用于匹配常见的视频和图片链接格式
  const videoPattern = /\.(mp4|webm|ogg)$/i;
  const imagePattern = /\.(jpg|jpeg|png|gif)$/i;

  // 使用正则表达式测试链接
  if (videoPattern.test(link)) {
    return "video"; // 匹配视频链接
  } else if (imagePattern.test(link)) {
    return "image"; // 匹配图片链接
  } else {
    return "unknown"; // 未匹配到视频或图片链接
  }
}
