import React, { useState } from "react";
import { PIP } from "./PIP";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import classes from "./index.module.css";
import { Images, fitSize } from "../theme";

export const ResizablePIP = ({
  children,
  width = 500,
  height = 500,
  minConstraints = [300, 300],
  maxConstraints = [800, 800],
  setIsPaused,
  isPaused,
  onClose,
}) => {
  const [size, setSize] = useState({ width, height });

  const handleResize = (e, data) => {
    setSize({ width: data.size.width, height: data.size.height });
  };

  const handleSomething = (e) => {
    setIsPaused((v) => !v);
  };

  const close = (e) => {
    onClose();
  };

  return (
    <PIP>
      <button
        style={{
          position: "absolute",
          zIndex: 2147483647,
          height: fitSize(15),
          width: fitSize(15),
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          top: 5,
          right: 5,
          borderWidth: 1,
          borderRadius: "50%",
          backgroundColor: "#ffffff",
          padding: 3,
        }}
        onClick={(e) => close(e)}
        onTouchStart={(e) => close(e)}
      >
        <img
          style={{
            objectFit: "cover",
            opacity: 0.7,
          }}
          src={Images.components.close}
          alt=""
          width={fitSize(7)}
          height={fitSize(7)}
        />
      </button>
      <button
        style={{
          position: "absolute",
          zIndex: 2147483647,
          width: fitSize(145),
          height: fitSize(220),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          top: 20,
        }}
        // onClick={() => {
        //   setIsPaused(true);
        //   console.log("ssr");
        // }}
        onClick={(e) => handleSomething(e)}
        onTouchStart={(e) => handleSomething(e)}
      >
        <img
          style={{
            objectFit: "cover",
            opacity: 0.7,
            display: isPaused ? "flex" : "none",
          }}
          src={Images.components.time_video_pause}
          alt=""
          width={fitSize(20)}
          height={fitSize(20)}
        />
      </button>
      <ResizableBox
        className={classes.pipResizableContent}
        width={size.width}
        height={size.height}
        minConstraints={[minConstraints[0], minConstraints[1]]}
        maxConstraints={[maxConstraints[0], maxConstraints[1]]}
      >
        {children}
      </ResizableBox>
    </PIP>
  );
};
