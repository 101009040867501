import BaseConfig from "./config.base"
import ProdConfig from "./config.prod"
import DevConfig from "./config.dev"
import generateEnv from "../generateEnv"

let ExtraConfig = ProdConfig

if (generateEnv.arg === "test") {
  ExtraConfig = DevConfig
}

const Config = { ...BaseConfig, ...ExtraConfig }

export default Config
