import { observer } from "mobx-react-lite";
import React, { FC, useReducer, useState } from "react";
import "./NewAddress.css";
import { SimpleHeader } from "../../components/SimpleHeader";
import { Images, colors, fitSize, fontName } from "../../theme";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { Touchable } from "../../components/Button";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { isValidPhoneNumber } from "../../utils/regexFunction";
import { showInfo } from "../../utils/showUtil";
import { ProvinceCityAreaSelect } from "../../components/ProvinceCityAreaSelect";
import { api } from "../../services/api";
import { GToast } from "../../navigators";

interface NewAddressProps {}

export const NewAddress: FC<NewAddressProps> = observer(() => {
  useDocumentTitle("新建地址");
  const [open, setOpen] = useState<boolean>(false);
  const {
    state: { type, item },
  } = useLocation();
  const navigate = useNavigate();
  const [info, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "deliver_name":
          return { ...state, deliver_name: action.payload };
        case "deliver_phone":
          return { ...state, deliver_phone: action.payload };
        case "deliver_area":
          return { ...state, deliver_area: action.payload };
        case "deliver_address":
          return { ...state, deliver_address: action.payload };
        case "is_default":
          return { ...state, is_default: action.payload };
        default:
          return state;
      }
    },
    item ?? {
      deliver_name: "",
      deliver_phone: "",
      deliver_area: "",
      deliver_address: "",
      is_default: 0,
    }
  );

  const onAdd = async () => {
    try {
      const res = await api.addAddress(
        info.deliver_name,
        info.deliver_phone,
        info.deliver_area,
        info.deliver_address,
        info.is_default
      );
      if (res.kind === "ok") {
        navigate(-1);
        showInfo("新增地址完成", "OK");
      } else {
        GToast.current.handleErrorResponse(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onUpdate = async () => {
    try {
      const res = await api.editAddress(
        item.id,
        info.deliver_name,
        info.deliver_phone,
        info.deliver_area,
        info.deliver_address,
        info.is_default
      );
      if (res.kind === "ok") {
        navigate(-1);
        showInfo("地址修改完成", "OK");
      } else {
        GToast.current.handleErrorResponse(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const isDisabled =
    !info?.deliver_name ||
    !isValidPhoneNumber(info.deliver_phone) ||
    !info?.deliver_area ||
    !info?.deliver_address;

  return (
    <div className={"container"}>
      {/* <SimpleHeader title={type === "add" ? "创建新地址" : "编辑地址"} /> */}
      <div className={"content"}>
        <div
          style={{
            width: fitSize(355),
            height: fitSize(266),
            borderRadius: fitSize(4),
            backgroundColor: colors.palette.neutral100,
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: fitSize(333),
              height: fitSize(48),
              borderBottom: `1px solid #F5F5F8`,
            }}
          >
            <div
              style={{
                width: fitSize(86),
                height: fitSize(48),
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: fitSize(14),
                  color: colors.palette.neutral30,
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                }}
              >
                收货人
              </span>
            </div>
            <input
              type="text"
              placeholder="姓名"
              value={info?.deliver_name ?? ""}
              onChange={(e) => {
                dispatch({ type: "deliver_name", payload: e.target.value });
              }}
              style={{
                display: "flex",
                flexGrow: 1,
                fontSize: fitSize(14),
                color: colors.palette.neutral10,
                fontWeight: 400,
                outlineStyle: "none",
              }}
              className="custom-input"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: fitSize(333),
              height: fitSize(48),
              borderBottom: `1px solid #F5F5F8`,
            }}
          >
            <div
              style={{
                width: fitSize(86),
                height: fitSize(48),
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: fitSize(14),
                  color: colors.palette.neutral30,
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                }}
              >
                联系电话
              </span>
            </div>
            <input
              type="text"
              placeholder="手机号码"
              value={info?.deliver_phone ?? ""}
              onChange={(e) => {
                dispatch({ type: "deliver_phone", payload: e.target.value });
              }}
              style={{
                display: "flex",
                flexGrow: 1,
                fontSize: fitSize(14),
                color: colors.palette.neutral10,
                fontWeight: 400,
                outlineStyle: "none",
              }}
              className="custom-input"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: fitSize(333),
              height: fitSize(48),
              borderBottom: `1px solid #F5F5F8`,
            }}
          >
            <div
              style={{
                width: fitSize(86),
                height: fitSize(48),
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: fitSize(14),
                  color: colors.palette.neutral30,
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                }}
              >
                所在地区
              </span>
            </div>
            <Touchable
              style={{
                display: "flex",
                flexGrow: 1,
                color: colors.palette.neutral10,
                fontWeight: 400,
                alignItems: "center",
              }}
              onClick={() => {
                setOpen(true);
              }}
            >
              <span
                style={{
                  fontSize: fitSize(14),
                  color: info?.deliver_area
                    ? colors.palette.neutral10
                    : "#cccccc",
                  fontWeight: 400,
                }}
              >
                {info?.deliver_area || "省、市、区、街道"}
              </span>
            </Touchable>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: fitSize(333),
              height: fitSize(64),
              borderBottom: `1px solid #F5F5F8`,
            }}
          >
            <div
              style={{
                width: fitSize(86),
                height: fitSize(64),
                display: "flex",
                marginTop: fitSize(13),
              }}
            >
              <span
                style={{
                  fontSize: fitSize(14),
                  color: colors.palette.neutral30,
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                }}
              >
                详细地址
              </span>
            </div>
            <textarea
              placeholder="小区、门牌号等"
              value={info?.deliver_address ?? ""}
              onChange={(e) => {
                dispatch({ type: "deliver_address", payload: e.target.value });
              }}
              style={{
                display: "flex",
                flexGrow: 1,
                fontSize: fitSize(14),
                color: colors.palette.neutral10,
                fontWeight: 400,
                outlineStyle: "none",
                height: fitSize(35),
                paddingTop: fitSize(13),
              }}
              className={"custom-input"}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: fitSize(333),
              height: fitSize(64),
            }}
          >
            <div
              style={{
                width: fitSize(86),
                height: fitSize(64),
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: fitSize(14),
                  color: colors.palette.neutral30,
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                }}
              >
                设置默认地址
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Switch
                checked={info?.is_default === 1 ?? false}
                onChange={(checked) => {
                  dispatch({ type: "is_default", payload: checked ? 1 : 0 });
                }}
                checkedIcon={false}
                id="normal-switch"
                className="my-switch"
                uncheckedIcon={false}
                offColor={"#C6C9CC"}
                onColor={"#62FFD0"}
                handleDiameter={10}
                height={17}
                width={32}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: fitSize(90),
          display: "flex",
          justifyContent: "center",
          backgroundColor: colors.palette.neutral100,
          position: "fixed",
          bottom: 0,
        }}
      >
        <Touchable
          style={{
            display: "flex",
            width: fitSize(347),
            height: fitSize(46),
            justifyContent: "center",
            backgroundImage: `url(${
              isDisabled ? Images.store.address_btn : Images.store.save_btn
            })`,
            backgroundSize: "100% 100%",
            marginTop: fitSize(10),
          }}
          onClick={() => {
            if (isDisabled) {
              showInfo("请填写完整信息");
            } else {
              if (type === "add") {
                onAdd();
              } else {
                onUpdate();
              }
            }
          }}
        >
          <span
            style={{
              fontSize: fitSize(13),
              color: "#212F3B",
              fontWeight: 500,
              fontFamily: fontName.PingFangSC,
              marginTop: fitSize(11),
            }}
          >
            保存地址
          </span>
        </Touchable>
      </div>
      <ProvinceCityAreaSelect
        open={open}
        setOpen={setOpen}
        onSubmit={(value) => {
          dispatch({ type: "deliver_area", payload: value });
        }}
      />
    </div>
  );
});
