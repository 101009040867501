import { observer } from "mobx-react-lite";
import React, { FC, useState, useEffect } from "react";
import styles from "./index.module.css";
import { SimpleHeader } from "../../components";
import { Images, colors, fitSize, fontName } from "../../theme";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Touchable } from "../../components/Button";
import { AwardItem, OrderBuyItem, api } from "../../services/api";
import { GToast } from "../../navigators";

interface LuckyDrawDetailsProps {}

type OrderInfo = {
  exchange_no: string;
  create_time: number;
  order_no: string; //订单编号
  user_id: number; //用户id
  order_status: number; //订单状态  0.取消状态  1.待付款   2.待发货(已付款)  3.发货中  4.已完成
  pay_time: number; //付款时间
  niudanji: number; //扭蛋机id
  niudanji_name: string; //扭蛋机名称
  niudanji_img: string; //扭蛋机图片
  coupon_id: number; //优惠券id
  coupon_name: string; //优惠券名称
  single_price: number; //单价 以分为单位
  nums: number; //数量
  price: number; //实付价格,以分为单位    (优惠价格:price-nums*single_price)
  award_list: AwardItem[];
};

const status = {
  0: "已取消",
  1: "待付款",
  2: "待发货",
  3: "发货中",
  4: "已完成",
};

export const LuckyDrawDetails: FC<LuckyDrawDetailsProps> = observer(({}) => {
  useDocumentTitle("扭蛋详情");
  const { id } = useParams();
  const [order, setOrder] = useState<OrderInfo>();
  const {
    state: { award },
  } = useLocation();

  const fetchInfo = async () => {
    try {
      const res = await api.getUserOrderInfo(id);
      if (res.kind === "ok") {
        setOrder(res.data);
      } else {
        GToast.current.handleErrorResponse(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <div className={styles.container}>
      {/* <SimpleHeader title="订单详情" /> */}
      <div className={styles.content}>
        <div
          style={{
            width: fitSize(355),
            minHeight: fitSize(162),
            backgroundColor: colors.palette.neutral100,
            alignSelf: "center",
            borderRadius: 2,
            boxShadow: "0px 0px 10px 0px rgba(218,218,230,0.2)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              height: fitSize(50),
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 10",
              paddingLeft: fitSize(12),
            }}
          >
            <span
              style={{
                fontSize: fitSize(14),
                color: "#212F3B",
                fontWeight: 500,
                fontFamily: fontName.PinFangSCSemibold,
              }}
            >
              {order?.niudanji_name}
            </span>
            <span
              style={{
                fontSize: fitSize(14),
                color: "#777777",
                fontWeight: 400,
                fontFamily: fontName.PingFangSC,
                paddingRight: fitSize(21),
              }}
            >
              {status[order?.order_status]}
            </span>
          </div>
          <div
            style={{
              width: fitSize(331),
              height: 1,
              border: "1px solid #F5F5F8",
              marginBottom: fitSize(13),
              alignSelf: "center",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: fitSize(81),
              alignItems: "center",
              justifyContent: "center",
              marginLeft: fitSize(12),
            }}
          >
            <img
              src={
                award?.business_type === 1
                  ? award?.award_img
                  : award.business_type === 2
                  ? Images.activityNew.item_card
                  : award?.business_type === 3
                  ? Images.activityNew.coupon_card
                  : ""
              }
              alt=""
              style={{
                width: fitSize(81),
                height: fitSize(81),
                border: "1px solid #F5F5F8",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: fitSize(14),
                flexGrow: 1,
              }}
            >
              <span
                style={{
                  fontSize: fitSize(13),
                  color: "#212F3B",
                  fontWeight: 400,
                  marginBottom: fitSize(2),
                  fontFamily: fontName.PingFangSC,
                }}
              >
                {award?.award_name}
              </span>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {/* <span
                  style={{
                    fontSize: fitSize(11),
                    color: "#aaaaaa",
                    fontFamily: fontName.PingFangSC,
                    fontWeight: 500,
                  }}
                >
                  <span
                    style={{
                      fontSize: fitSize(11),
                      color: "#aaaaaa",
                      fontFamily: fontName.PingFangSC,
                      fontWeight: 500,
                      marginRight: fitSize(3),
                    }}
                  >
                    ¥
                  </span>
                  {(order?.single_price / 100).toFixed(2)}
                </span> */}
                <span
                  style={{
                    fontSize: fitSize(11),
                    color: "#aaaaaa",
                    fontWeight: 400,
                    fontFamily: fontName.PingFangSC,
                    marginLeft: fitSize(2),
                  }}
                >
                  x1
                </span>
              </div>
              <span
                style={{
                  fontSize: fitSize(11),
                  color: "#aaaaaa",
                  fontWeight: 400,
                  marginBottom: fitSize(8),
                  fontFamily: fontName.PingFangSC,
                  marginTop: fitSize(1),
                }}
              >
                {dayjs(order?.create_time * 1000).format("YYYY.MM.DD HH:mm:ss")}
              </span>
            </div>
          </div>
          {/* <div
            style={{
              width: "100%",
              height: fitSize(50),
              display:
                order?.order_status === 2 ||
                order?.order_status === 3 ||
                order?.order_status === 4
                  ? "flex"
                  : "none",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "0 10",
              paddingLeft: fitSize(10),
            }}
          >
            <span
              style={{
                fontSize: fitSize(11),
                color: "#212F3B",
                fontWeight: 400,
                fontFamily: fontName.PingFangSC,
              }}
            >
              实付款：
            </span>
            <span
              style={{
                fontSize: fitSize(18),
                color: "#212F3B",
                fontWeight: 500,
                fontFamily: fontName.PinFangSCSemibold,
                paddingRight: fitSize(21),
              }}
            >
              <span
                style={{
                  fontSize: fitSize(18),
                  color: "#212F3B",
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                  marginRight: fitSize(2),
                }}
              >
                ¥
              </span>
              {(order?.price / 100).toFixed(2)}
            </span>
          </div> */}
        </div>
        <div
          style={{
            width: fitSize(355),
            minHeight: fitSize(136),
            backgroundColor: "#ffffff",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            marginTop: fitSize(8),
            borderRadius: 2,
            zIndex: 5,
          }}
        >
          <div
            style={{
              marginTop: fitSize(14),
              marginLeft: fitSize(12),
              position: "relative",
              marginBottom: fitSize(18),
              zIndex: 4,
            }}
          >
            <span
              style={{
                fontSize: fitSize(16),
                color: "#212F3B",
                fontWeight: 500,
                fontFamily: fontName.PinFangSCSemibold,
                zIndex: 3,
              }}
            >
              订单信息
            </span>
            <img
              src={Images.home.new_person}
              alt=""
              style={{
                width: fitSize(63),
                height: fitSize(23),
                position: "absolute",
                left: 10,
                top: 0,
                zIndex: -1,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: fitSize(15),
            }}
          >
            <span
              style={{
                fontSize: fitSize(12),
                color: "#212F3B",
                fontWeight: 400,
                fontFamily: fontName.PingFangSC,
                marginLeft: fitSize(12),
              }}
            >
              订单编号
            </span>
            <span
              style={{
                fontSize: fitSize(13),
                color: "#aaaaaa",
                fontWeight: 400,
                fontFamily: fontName.PingFangSC,
                marginRight: fitSize(12),
                wordBreak: "normal",
                display: "block",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                overflow: "hidden",
                width: fitSize(200),
                textAlign: "end",
              }}
            >
              {order?.order_no}
            </span>
          </div>
          <div
            style={{
              display:
                order?.order_status === 2 ||
                order?.order_status === 3 ||
                order?.order_status === 4
                  ? "flex"
                  : "none",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: fitSize(15),
            }}
          >
            <span
              style={{
                fontSize: fitSize(12),
                color: "#212F3B",
                fontWeight: 400,
                fontFamily: fontName.PingFangSC,
                marginLeft: fitSize(12),
              }}
            >
              奖号
            </span>
            <span
              style={{
                fontSize: fitSize(13),
                color: "#aaaaaa",
                fontWeight: 400,
                fontFamily: fontName.PingFangSC,
                marginRight: fitSize(12),
                wordBreak: "normal",
                display: "block",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                overflow: "hidden",
                width: fitSize(200),
                textAlign: "end",
              }}
            >
              {award?.exchange_no ?? "-"}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: fitSize(15),
            }}
          >
            <span
              style={{
                fontSize: fitSize(12),
                color: "#212F3B",
                fontWeight: 400,
                fontFamily: fontName.PingFangSC,
                marginLeft: fitSize(12),
              }}
            >
              创建时间
            </span>
            <span
              style={{
                fontSize: fitSize(13),
                color: "#aaaaaa",
                fontWeight: 400,
                fontFamily: fontName.PingFangSC,
                marginRight: fitSize(12),
                wordBreak: "normal",
                display: "block",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                overflow: "hidden",
                width: fitSize(200),
                textAlign: "end",
              }}
            >
              {dayjs(order?.create_time * 1000).format("YYYY.MM.DD HH:mm:ss")}
            </span>
          </div>
          <div
            style={{
              display:
                order?.order_status === 2 ||
                order?.order_status === 3 ||
                order?.order_status === 4
                  ? "flex"
                  : "none",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: fitSize(15),
            }}
          >
            <span
              style={{
                fontSize: fitSize(12),
                color: "#212F3B",
                fontWeight: 400,
                fontFamily: fontName.PingFangSC,
                marginLeft: fitSize(12),
              }}
            >
              成交时间
            </span>
            <span
              style={{
                fontSize: fitSize(13),
                color: "#aaaaaa",
                fontWeight: 400,
                fontFamily: fontName.PingFangSC,
                marginRight: fitSize(12),
                wordBreak: "normal",
                display: "block",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                overflow: "hidden",
                width: fitSize(200),
                textAlign: "end",
              }}
            >
              {dayjs(order?.pay_time * 1000).format("YYYY.MM.DD HH:mm:ss")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});
