import { observer } from 'mobx-react-lite'
import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styles from './index.module.css'
import { ContentPreview, TypeEnum } from './components/ContentPreview'
import { MyLuckyWheel } from './components/LuckyWheel'
import { Images, colors, fitSize, fontName } from '../../theme'
import Masonry from 'react-responsive-masonry'
import { Touchable } from '../../components/Button'
import { GToast } from '../../navigators'
import { MultipleRewardsModal } from '../../modal/MultipleRewardsModal'
import { StickinessHeader } from '../../components/StickinessHeader'
import { RewardItem, ShowOrder } from '../PayProcess/ShowOrder'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ActivityNewItem, AwardItem, FreeTimesItem, RealTimeItem, api } from '../../services/api'
import { PIPModal } from '../../modal/PIPModal'
import { isVideoOrImageLink } from '../../utils/regexFunction'
import { hideLoad, showInfo, showLoad } from '../../utils/showUtil'
import { RealTimePlayback } from './components/RealTimePlayback'
import { loadString, saveString } from '../../utils/storage'
import { ready } from '../../hooks/useInit'
import { useStores } from '../../models'
import _ from 'lodash'

const TYPE_ICON = {
  4: Images.activityNew.legend_label,
  3: Images.activityNew.epic_label,
  // 2: Images.activityNew.rare_label,
  1: Images.activityNew.normal_label
}

export const TYPE_CLASS = {
  1: '普通',
  // 2: "稀有",
  3: '史诗',
  4: '传说'
}

export const pauseAllItems = array => {
  return array.map(item => ({ ...item, isPaused: true }))
}

interface ActivityNewProps {}

export const ActivityNew: FC<ActivityNewProps> = observer(() => {
  const { id } = useParams()
  const { state } = useLocation()
  const { accountStore } = useStores()
  const nonPay = state?.nonPay || false
  const nonPayNum = state?.nonPayNum || 1
  const nonPayId = state?.nonPayId || ''
  const infoId = state?.infoId || ''
  const atOnce = state?.atOnce || false
  const submitValue = state?.submit
  const navigate = useNavigate()
  const [info, setInfo] = useState<ActivityNewItem>()
  const [showModal, setShowModal] = useState(false)
  const [cardList, setCardList] = useState<AwardItem[]>([])
  const [whetherUseCard, setWhetherUseCard] = useState<0 | 1 | 2>(0)
  const [prevInfo, setPrevInfo] = useState<any>({})
  const [isUseCard, setIsUseCard] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [openOrder, setOpenOrder] = useState(false)
  const [orderId, setOrderId] = useState<string>()
  const [orderType, setOrderType] = useState<1 | 3>()
  const [showPIP, setShowPIP] = useState(false)
  const [currentVideo, setCurrentVideo] = useState('')
  const [stayClose, setStayClose] = useState(false)
  const [orderInfo, setOrderInfo] = useState<RewardItem>()
  const [startBuying, setStartBuying] = useState(false)
  const intervalId = useRef(null)
  const [free, setFree] = useState('')
  const fetchNum = useRef(0)
  const [realTimeData, setRealTimeData] = useState<RealTimeItem[]>([])
  const [freeTimes, setFreeTimes] = useState<FreeTimesItem>()

  const fetchRealTimeData = useCallback(async (id: number) => {
    try {
      const res = await api.getRealTimeData(id)
      if (res.kind === 'ok') {
        setRealTimeData(res.data)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }, [])

  const fetchGetFreeTimes = async (id: number, info: ActivityNewItem) => {
    if ((await loadString('first')) !== 'true') return
    try {
      const res = await api.getFreeTimes(id)
      if (res.kind === 'ok') {
        setFreeTimes(res.data)
        if (atOnce) {
          if (res.data.remain_times > 0) {
            setOrderType(1)
            try {
              const res = await api.createOrder(info?.id, 1, info?.is_set_continuous === 2 || info?.is_set_continuous === 3 ? 1 : 0)
              if (res.kind === 'ok') {
                if (info?.price === 0) {
                  showLoad('加载中...')
                  setFree(res.data)
                  setStartBuying(true)
                } else {
                  setOpenOrder(true)
                  setOrderId(res.data)
                }
              } else {
                GToast.current.handleErrorResponse(res)
              }
            } catch (error) {
              console.log('error', error)
            }
          } else {
            showInfo('今日免费扭蛋机次数已用完，明天再来吧')
          }
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  // 获取商品详情
  const fetchGetActivityInfo = async () => {
    try {
      const res = await api.getActivityNew(nonPay ? infoId : id)
      if (res.kind === 'ok') {
        window._hmt.push(['_trackEvent', 'page', 'view', `商品详情:${res.data.name}`])
        setInfo(res.data)
        fetchRealTimeData(res.data.id)
        const videoArray = [
          ...(res.data?.videos && res.data.videos !== ''
            ? res.data.videos.split(',').map(url => ({
                url: url.trim(),
                isPaused: true,
                isVideo: true
              }))
            : [])
        ]
        setCurrentVideo(videoArray[0]?.url ?? '')
        if (nonPay) {
          setOpenOrder(true)
          setOrderType(nonPayNum)
          setOrderId(nonPayId)
        }
        if (res.data.type === 2) {
          fetchGetFreeTimes(res.data.id, res.data)
        }
        window.document.title = res.data.name ?? ''
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    fetchGetActivityInfo()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const checkOrder = async () => {
    if (fetchNum.current >= 14) {
      hideLoad()
      clearInterval(intervalId.current)
      setStartBuying(false)
      return
    }
    try {
      const res = await api.getUserOrderInfo(free)
      if (res.kind === 'ok') {
        setOpenOrder(false)
        if (res.data.award_list?.length > 0) {
          hideLoad()
          clearInterval(intervalId.current)
          setStartBuying(false)
          setCurrentSlide(0)
          setShowModal(true)
          setOrderInfo(res.data)
          setCardList(
            res.data.award_list.map(i => {
              return { ...i, isShow: false, status: 0 }
            })
          )
        } else {
          setStartBuying(false)
          showInfo('已售完')
        }
      } else {
        setStartBuying(false)
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  // 创建订单
  const fetchCreateOrder = async (num: 1 | 3) => {
    try {
      const res = await api.createOrder(info?.id, num, info?.is_set_continuous === 2 || info?.is_set_continuous === 3 ? 1 : 0)
      if (res.kind === 'ok') {
        // 百度埋点
        window._hmt.push(['_trackEvent', 'page', 'view', `${info.name}_${num === 1 ? '单抽' : '连抽'}`])
        const countPrice = info?.price - info?.discount_amount
        if (countPrice === 0) {
          showLoad('加载中...')
          setFree(res.data)
          setStartBuying(true)
        } else {
          setOpenOrder(true)
          setOrderId(res.data)
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const fetchLoginInfo = async (token: string, type: 1 | 3) => {
    const res = await api.getLoginInfo(token)
    if (res.kind === 'ok' && !_.isEmpty(res.data)) {
      accountStore.doMessageLogin(res.data.authorization)
      accountStore.setUserInfo({
        authorization: res.data.authorization,
        userId: res.data.user_id,
        ddhId: res.data.ddh_id,
        userName: res.data.user_name
      })
      api.apisauce.setHeader('Authorization', res.data.authorization)
      fetchGetActivityInfo()
      setOrderType(type)
      fetchCreateOrder(type)
    }
  }

  const init = async (type: 1 | 3) => {
    if ((await loadString('first')) !== 'true') {
      const authCodeList = (await loadString('first')) === 'true' ? [] : ['metroUserId', 'mobile']
      ready(() => {
        // 调用大都会的授权
        ;(window as any).AlipayJSBridge.call(
          'metro_thirdPartyAuthToken',
          {
            appid: 2024011014213318,
            thirdCode: 'niudanCode',
            businessName: 'niudanCode',
            authCodeList
          },
          result => {
            const { code, data } = result
            if (code === 1000) {
              fetchLoginInfo(data.token, type)
              saveString('first', 'true')
            }
          }
        )
      })
    }
  }

  // 开始抽奖
  const onShowOrder = (type: 1 | 3) => {
    init(type)
    if (info?.price === 0 && freeTimes?.remain_times <= 0) {
      showInfo('今日免费扭蛋机次数已用完，明天再来吧')
      return
    }
    setOrderType(type)
    fetchCreateOrder(type)
  }

  const fetchStartAgain = async (index: number, type: 0 | 1 | 2, popId: string) => {
    setPrevInfo(cardList[index])
    setWhetherUseCard(type)
    try {
      const res = await api.RedrawCard(cardList[index].exchange_no, popId)
      if (res.kind === 'ok') {
        GToast.current.showPrizeDraw('https://ndj.img.cbatime.com.cn/niudanji_static/video/video.mp4', true, () => {
          setCardList(prev =>
            prev.map((current, n) =>
              n === index
                ? {
                    ...res.data,
                    status: current.status,
                    isShow: true
                  }
                : current
            )
          )
          setShowModal(true)
        })
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const onReplaceItem = (id: number) => {
    if (cardList.length > 1) {
      setCardList(prev => prev.filter(current => current.id !== id))
    } else {
      setShowModal(false)
      fetchGetCoupon()
      setWhetherUseCard(0)
      fetchGetActivityInfo()
      setIsUseCard(false)
    }
  }

  const fetchGetCoupon = async () => {
    setIsUseCard(false)
    try {
      const res = await api.claimCoupon(orderInfo.order_no)
      if (res.kind === 'ok') {
        if (res.data.value > 0 || res.data.business_type === 2) {
          GToast.current.showGetCoupon(
            true,
            res.data.business_type === 3 || res.data.business_type === 1 ? res.data.value / 100 : res.data.value,
            1,
            res.data.business_name,
            res.data.coupon_type === 1 ? res.data.start_time : 0,
            res.data.coupon_type === 1 ? res.data.end_time : 0,
            'Paid',
            res.data.coupon_type,
            () => {
              window._hmt.push(['_trackEvent', 'button', 'click', `领取天降好礼`])
              // 1.商品 2.道具 3.优惠券
              if (res.data.business_type === 3) {
                navigate('/coupon-center')
              } else if (res.data.business_type === 2) {
                navigate('/stage-property-card')
              } else {
                navigate('/')
              }
            },
            res.data.business_type === 2,
            res.data.thumb_img
          )
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const onShowCard = (id: number) => {
    setShowModal(false)
    GToast.current.showPrizeDraw('https://ndj.img.cbatime.com.cn/niudanji_static/video/video.mp4', true, () => {
      setCardList(prev => prev.map(current => (current.id === id ? { ...current, isShow: true } : current)))
      setShowModal(true)
    })
  }

  const reserveCard = () => {
    setCardList(prev => prev.map(current => (current.id === prevInfo.id ? prevInfo : current)))
  }

  const setCardStatus = (status: 0 | 1 | 2, id: number) => {
    setCardList(prev =>
      prev.map(current => {
        return current.id === id ? { ...current, status } : current
      })
    )
  }

  useEffect(() => {
    const homeScrollElement = document.getElementById('barScroll')
    if (!homeScrollElement) {
      return
    }

    const handleScroll = () => {
      const scrollDistance = homeScrollElement.scrollTop
      if (scrollDistance > 550) {
        setShowPIP(true)
      } else {
        setShowPIP(false)
      }
    }

    homeScrollElement.addEventListener('scroll', handleScroll)
    return () => {
      homeScrollElement.removeEventListener('scroll', handleScroll)
    }
  }, [stayClose])

  useEffect(() => {
    if (startBuying) {
      intervalId.current = setInterval(() => {
        fetchNum.current++
        checkOrder()
      }, 1000)
    }
    return () => {
      clearInterval(intervalId.current)
    }
  }, [startBuying])

  const finishPay = (value: RewardItem) => {
    setOpenOrder(false)
    setCurrentSlide(0)
    setShowModal(true)
    setOrderInfo(value)
    setCardList(
      value.award_list.map(i => {
        return { ...i, isShow: false, status: 0 }
      })
    )
  }

  // 支付成功逻辑
  useEffect(() => {
    if (submitValue) {
      finishPay(submitValue)
    }
  }, [submitValue])

  const combinedArray = [
    ...(info?.videos && info.videos !== ''
      ? info.videos.split(',').map(url => ({
          url: url.trim(),
          isPaused: true,
          isVideo: true
        }))
      : []),
    ...(info?.award_imgs && info.award_imgs !== ''
      ? info.award_imgs.includes(',')
        ? info.award_imgs.split(',').map(url => ({
            url: url.trim(),
            isPaused: true,
            isVideo: false
          }))
        : [
            {
              url: info?.award_imgs,
              isPaused: true,
              isVideo: false
            }
          ]
      : [])
  ]

  const isFree = info?.price === 0
  const showDoubleStyle = info?.is_set_continuous === 3 ? fitSize(162) : fitSize(347)

  // 更多扭蛋
  const getMore = url => {
    localStorage.setItem('scrollPosition', '480')
    navigate(`/`)
  }

  return (
    <div className={styles.container}>
      {showPIP && !stayClose && isVideoOrImageLink(currentVideo) === 'video' ? (
        <PIPModal
          onClose={() => {
            setStayClose(true)
            setShowPIP(false)
          }}
          video={currentVideo}
        />
      ) : null}
      <ShowOrder
        orderId={orderId}
        open={openOrder}
        onClose={() => {
          setOpenOrder(false)
        }}
        id={id}
        onSubmit={(value: RewardItem) => {
          finishPay(value)
        }}
        orderType={orderType}
        // orderInfo={info}
      />
      <MultipleRewardsModal
        open={showModal}
        list={cardList}
        onClose={() => {
          setShowModal(false)
        }}
        setOpen={setShowModal}
        whetherUseCard={whetherUseCard}
        setWhetherUseCard={setWhetherUseCard}
        fetchGetCoupon={fetchGetCoupon}
        onReplaceItem={onReplaceItem}
        onShowCard={onShowCard}
        fetchStartAgain={fetchStartAgain}
        reserveCard={reserveCard}
        isUseCard={isUseCard}
        setIsUseCard={setIsUseCard}
        setCardStatus={setCardStatus}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
      />
      {/* <StickinessHeader title="扭蛋机" scrollHeight={550} routeName="/" /> */}
      <div className={styles.content} id="barScroll">
        <MyLuckyWheel
          list={combinedArray}
          type={TypeEnum.progressBar}
          setCurrentVideo={setCurrentVideo}
          isWs={true}
          wsData={realTimeData}
        />
        {/* <ContentPreview
          list={combinedArray}
          type={TypeEnum.progressBar}
          setCurrentVideo={setCurrentVideo}
          isWs={true}
          wsData={realTimeData}
        /> */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            alignSelf: 'center',
            width: '100%',
            // height: fitSize(42),
            backgroundColor: '#f5f5f8',
            position: 'relative'
          }}
        ></div>
        {/* 保底机制，超过1000不显示 */}
        {info?.product_config?.mechanism?.draw_nums < 1000 && (
          <div
            style={{
              display: info?.product_config?.mechanism?.draw_nums === 0 ? 'none' : 'flex',
              width: '100%',
              flexDirection: 'column',
              backgroundImage: `url(${Images.activityNew.activity_new_bar})`,
              backgroundSize: '100% 100%',
              justifyContent: 'center',
              paddingBottom: fitSize(10)
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: fitSize(14),
                paddingTop: fitSize(10)
              }}
            >
              <span
                style={{
                  fontSize: fitSize(11),
                  fontWeight: 400,
                  fontFamily: fontName.YouSheBiaoTiHei2,
                  marginRight: fitSize(3),
                  color: '#212F3B'
                }}
              >
                已抽
              </span>
              <div>
                <span
                  style={{
                    fontSize: fitSize(18),
                    fontWeight: 400,
                    fontFamily: fontName.YouSheBiaoTiHei2,
                    color: '#212F3B'
                  }}
                >
                  {info?.already_open_times}
                </span>
                <span
                  style={{
                    fontSize: fitSize(18),
                    fontWeight: 400,
                    fontFamily: fontName.YouSheBiaoTiHei2,
                    color: '#212F3B'
                  }}
                >
                  /{info?.product_config?.mechanism?.draw_nums}
                </span>
              </div>
            </div>
            <span
              style={{
                fontSize: fitSize(11),
                color: '#767E85',
                fontWeight: 400,
                marginLeft: fitSize(14),
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: fitSize(250)
              }}
            >
              保底机制：抽{info?.product_config?.mechanism?.draw_nums}个，必中
              {TYPE_CLASS[info?.product_config?.mechanism?.award_type_more]}
              款及以上
            </span>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            width: '100%',
            flexDirection: 'column',
            backgroundColor: '#F5F5F8'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
              width: fitSize(350),
              height: fitSize(34),
              // backgroundColor: "#F5F5F8",
              alignSelf: 'center',
              marginTop: fitSize(14),
              // borderRadius: fitSize(4),
              paddingLeft: fitSize(7),
              backgroundColor: colors.palette.neutral100,
              borderRadius: 2
            }}
          >
            <div
              style={{
                width: fitSize(87),
                height: fitSize(34),
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: fitSize(1)
              }}
            >
              <img
                src={Images.activityNew.legend}
                alt=""
                style={{
                  width: fitSize(26),
                  height: fitSize(18),
                  objectFit: 'cover'
                }}
              />
              <span
                style={{
                  fontSize: fitSize(12),
                  fontWeight: 400,
                  color: colors.palette.neutral30,
                  marginLeft: fitSize(4)
                }}
              >
                {info?.product_config?.chance_sum[4]}%
              </span>
            </div>

            <div
              style={{
                width: fitSize(87),
                height: fitSize(34),
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: fitSize(1)
              }}
            >
              <img
                src={Images.activityNew.epic}
                alt=""
                style={{
                  width: fitSize(26),
                  height: fitSize(18),
                  objectFit: 'cover'
                }}
              />
              <span
                style={{
                  fontSize: fitSize(12),
                  fontWeight: 400,
                  color: colors.palette.neutral30,
                  marginLeft: fitSize(4)
                }}
              >
                {info?.product_config?.chance_sum[3]}%
              </span>
            </div>

            {/* <div
              style={{
                width: fitSize(87),
                height: fitSize(34),
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: fitSize(1),
              }}
            >
              <img
                src={Images.activityNew.rare}
                alt=""
                style={{
                  width: fitSize(26),
                  height: fitSize(18),
                  objectFit: "cover",
                }}
              />
              <span
                style={{
                  fontSize: fitSize(12),
                  fontWeight: 400,
                  color: colors.palette.neutral30,
                  marginLeft: fitSize(4),
                }}
              >
                {info?.product_config.chance_sum[2]}%
              </span>
            </div> */}

            <div
              style={{
                width: fitSize(87),
                height: fitSize(34),
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: fitSize(1)
              }}
            >
              <img
                src={Images.activityNew.normal}
                alt=""
                style={{
                  width: fitSize(26),
                  height: fitSize(18),
                  objectFit: 'cover'
                }}
              />
              <span
                style={{
                  fontSize: fitSize(12),
                  fontWeight: 400,
                  color: colors.palette.neutral30,
                  marginLeft: fitSize(4)
                }}
              >
                {info?.product_config.chance_sum[1]}%
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              width: fitSize(355),
              marginTop: fitSize(9),
              alignSelf: 'center',
              alignItems: 'center',
              // justifyContent: 'center',
              paddingBottom: fitSize(20)
            }}
          >
            {info?.product_config?.list.length > 0 && (
              <Masonry columnsCount={2}>
                {info?.product_config?.list.map((item, index) => (
                  <Touchable
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: fitSize(175),
                      minHeight: fitSize(207),
                      marginBottom: fitSize(10),
                      borderRadius: fitSize(4),
                      alignSelf: 'center',
                      overflow: 'hidden',
                      position: 'relative'
                    }}
                    onClick={() => {
                      navigate(`/activity-new/${id}/detail/${item.business_id}`)
                    }}
                    disabled={item?.business_type !== 1}
                  >
                    <img
                      src={TYPE_ICON[item.award_type]}
                      alt=""
                      style={{
                        width: fitSize(45),
                        height: fitSize(26),
                        objectFit: 'cover',
                        // marginTop: fitSize(12),
                        position: 'absolute',
                        top: 12,
                        zIndex: 1,
                        left: 1
                      }}
                    />
                    <LazyLoadImage
                      alt={''}
                      height={fitSize(168)}
                      src={
                        item?.business_type === 1
                          ? item?.thumb_img
                          : item?.business_type === 2
                          ? Images.activityNew.item_card
                          : item?.business_type === 3
                          ? Images.activityNew.coupon_card
                          : ''
                      }
                      width={fitSize(165)}
                      effect="blur"
                      wrapperProps={{
                        style: { transitionDelay: '0.3s' }
                      }}
                      style={{
                        alignSelf: 'center',
                        position: 'absolute',
                        backgroundColor: colors.palette.neutral100,
                        left: 5
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        width: fitSize(168),
                        marginTop: fitSize(168),
                        backgroundColor: colors.palette.neutral100,
                        marginLeft: fitSize(4)
                      }}
                    >
                      <span
                        style={{
                          fontSize: fitSize(13),
                          fontWeight: 400,
                          color: colors.palette.neutral30,
                          marginTop: fitSize(10),
                          marginLeft: fitSize(8),
                          maxWidth: fitSize(152),
                          overflow: 'hidden',
                          overflowWrap: 'break-word',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical'
                        }}
                      >
                        {item?.business_name}
                      </span>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginLeft: fitSize(8),
                          marginTop: fitSize(4),
                          marginBottom: fitSize(10)
                        }}
                      >
                        <span
                          style={{
                            fontSize: fitSize(11),
                            fontWeight: 400,
                            color: '#aaaaaa',
                            marginRight: fitSize(4)
                          }}
                        >
                          {item?.is_not_sale === 0 ? '参考价' : '非卖品'}
                        </span>
                        <div
                          style={{
                            display: item?.is_not_sale === 0 ? 'flex' : 'none',
                            flexDirection: 'row',
                            alignItems: 'center'
                          }}
                        >
                          <span
                            style={{
                              fontSize: fitSize(13),
                              fontWeight: 500,
                              color: colors.palette.neutral30,
                              fontFamily: fontName.PinFangSCSemibold,
                              marginRight: fitSize(2)
                            }}
                          >
                            ¥
                          </span>
                          <span
                            style={{
                              fontSize: fitSize(13),
                              fontWeight: 500,
                              color: colors.palette.neutral30,
                              fontFamily: fontName.PinFangSCSemibold
                            }}
                          >
                            {(item.price / 100).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Touchable>
                ))}
              </Masonry>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          height: fitSize(66),
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-around',
          backgroundColor: colors.palette.neutral100,
          position: 'fixed',
          bottom: 0,
          boxShadow: 'inset 0px 1px 0px 0px rgba(0,0,0,0.08)',
          zIndex: 3
        }}
      >
        <div
          style={{
            width: fitSize(355),
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-around'
          }}
        >
          {info?.is_set_continuous === 1 || info?.is_set_continuous === 3 ? (
            <Touchable
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: showDoubleStyle,
                height: fitSize(46),
                backgroundImage: `url(${
                  info?.is_set_continuous === 1 ? Images.activityNew.single_draw_button1 : Images.activityNew.single_draw_button
                })`,
                backgroundSize: '100% 100%',
                position: 'relative'
              }}
              onClick={() => {
                onShowOrder(1)
              }}
            >
              {info?.discount_amount !== 0 && !isFree && (
                <span
                  style={{
                    fontSize: fitSize(11),
                    fontWeight: 400,
                    color: '#aaaaaa',
                    marginRight: fitSize(4),
                    alignSelf: 'flex-end',
                    textDecoration: 'line-through',
                    position: 'absolute',
                    top: 2,
                    right: 20
                  }}
                >
                  ¥{(info?.price / 100).toFixed(2)}
                </span>
              )}
              <span
                style={{
                  fontSize: fitSize(13),
                  fontWeight: 500,
                  color: '#fff',
                  marginRight: fitSize(4),
                  marginTop: fitSize(13)
                }}
              >
                {!isFree ? '单抽' : ''}
              </span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: fitSize(13)
                }}
              >
                <span
                  style={{
                    fontSize: fitSize(13),
                    fontWeight: 500,
                    color: '#fff',
                    marginRight: fitSize(2)
                  }}
                >
                  {!isFree ? '¥' : ''}
                </span>
                <span
                  style={{
                    fontSize: fitSize(13),
                    fontWeight: 500,
                    color: '#fff'
                  }}
                >
                  {!isFree ? (
                    // 单抽不使用优惠券
                    //  (info?.price / 100).toFixed(2)
                    // 单抽使用优惠券
                    <span
                      style={{
                        fontSize: fitSize(13),
                        fontWeight: 500,
                        color: '#fff',
                        fontFamily: fontName.PinFangSCSemibold
                      }}
                    >
                      {(info?.price / 100 - info?.discount_amount / 100).toFixed(2)}
                    </span>
                  ) : (
                    `扭一扭 ${
                      freeTimes?.remain_times <= 0
                        ? `(剩余免费扭蛋次数：${0})`
                        : freeTimes?.remain_times
                        ? `(剩余免费扭蛋次数：${freeTimes?.remain_times})`
                        : ''
                    }`
                  )}
                </span>
              </div>
            </Touchable>
          ) : null}
          {info?.is_set_continuous === 2 || info?.is_set_continuous === 3 ? (
            <Touchable
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: showDoubleStyle,
                height: fitSize(46),
                backgroundImage: `url(${
                  info?.is_set_continuous === 2 ? Images.activityNew.lang_btn : Images.activityNew.more_draw_button
                })`,
                backgroundSize: '100% 100%',
                position: 'relative'
              }}
              onClick={() => {
                onShowOrder(3)
              }}
            >
              {info?.multi_discount_amount !== 0 && (
                <span
                  style={{
                    fontSize: fitSize(11),
                    fontWeight: 400,
                    color: '#aaaaaa',
                    marginRight: fitSize(4),
                    alignSelf: 'flex-end',
                    textDecoration: 'line-through',
                    position: 'absolute',
                    top: 2,
                    right: info?.is_set_continuous === 2 ? 100 : 12
                  }}
                >
                  ¥{((info?.price * 3) / 100).toFixed(2)}
                </span>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  marginTop: fitSize(13)
                }}
              >
                <span
                  style={{
                    fontSize: fitSize(13),
                    fontWeight: 500,
                    color: '#e31500',
                    fontFamily: fontName.PinFangSCSemibold,
                    marginRight: fitSize(4)
                  }}
                >
                  3连抽
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <span
                    style={{
                      fontSize: fitSize(13),
                      fontWeight: 500,
                      color: '#e31500',
                      marginRight: fitSize(2),
                      fontFamily: fontName.PinFangSCSemibold
                    }}
                  >
                    ¥
                  </span>
                  <span
                    style={{
                      fontSize: fitSize(13),
                      fontWeight: 500,
                      color: '#e31500',
                      fontFamily: fontName.PinFangSCSemibold
                    }}
                  >
                    {((info?.price * 3) / 100 - info?.multi_discount_amount / 100).toFixed(2)}
                  </span>
                </div>
              </div>
            </Touchable>
          ) : null}
        </div>
      </div>
      {/* 悬浮按钮 */}
      <div className={styles.btn} onClick={getMore}>
        <img src={Images.home.continue_btn} style={{ width: fitSize(72), height: fitSize(61) }}></img>
      </div>
    </div>
  )
})
