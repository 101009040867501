import { observer } from 'mobx-react-lite'
import React, { FC, useState, useEffect, useRef } from 'react'
import { SimpleHeader } from '../../components'
import { Images, colors, fitSize, fontName, windowHeight, windowWidth } from '../../theme'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { Touchable } from '../../components/Button'
import Modal from 'react-modal'
import { animated, useSpring } from '@react-spring/web'
import { Location, NavigationType, useLocation, useNavigate, useNavigationType } from 'react-router-dom'
import { AgreementModal } from '../../modal/AgreementModal'
import { platform } from '../../utils/util'
import { ActivityNewItem, AwardItem, api } from '../../services/api'
import { GToast } from '../../navigators'
import { showInfo, showLoad } from '../../utils/showUtil'
import _ from 'lodash'
import ReactLoading from 'react-loading'
import { remove, saveString } from '../../utils/storage'

type InfoValue = {
  exchange_no: string
  create_time: number
  order_no: string //订单编号
  user_id: number //用户id
  order_status: number //订单状态  0.取消状态  1.待付款   2.待发货(已付款)  3.发货中  4.已完成
  pay_time: number //付款时间
  niudanji: number //扭蛋机id
  niudanji_name: string //扭蛋机名称
  niudanji_img: string //扭蛋机图片
  coupon_id: number //优惠券id
  coupon_name: string //优惠券名称
  single_price: number //单价 以分为单位
  nums: number //数量
  price: number //实付价格,以分为单位    (优惠价格:price-nums*single_price)
  award_list: AwardItem[]
}

export type RewardItem = {
  order_no: string //订单编号
  user_id: number //用户id
  order_status: number //订单状态 0取消中
  award_list: AwardItem[]
}

interface ShowOrderProps {
  id: string
  orderId: string
  open: boolean
  onClose: () => void
  onSubmit: (value: RewardItem) => void
  orderType: number
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: windowWidth,
    height: windowHeight,
    backgroundColor: '#F5F5F8',
    overflow: 'hidden'
  },
  overlay: { zIndex: 1000 }
}

export const ShowOrder: FC<ShowOrderProps> = observer(({ open, onClose, orderId, onSubmit, orderType, id }) => {
  // useDocumentTitle("订单确认");
  const [selectPay, setSelectPay] = useState<'ALI' | 'WX'>('ALI')
  const [consentAgreement, setConsentAgreement] = useState(false)
  const [showAgreement, setShowAgreement] = useState(false)
  const location: Location = useLocation()
  const navType: NavigationType = useNavigationType()
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false)
  const [startBuying, setStartBuying] = useState(false)
  const intervalId = useRef(null)
  const fetchNum = useRef(0)
  const [orderInfo, setOrderInfo] = useState<InfoValue>()

  const onPay = async () => {
    if (!consentAgreement) {
      setShadowStyle({
        boxShadow: '0 0 10px 5px rgba(255, 0, 0, 0.8)',
        borderRadius: fitSize(16)
      })
      return
    }
    try {
      const res = await api.createAliOrder(orderId)
      if (res.kind === 'ok') {
        window._hmt.push(['_trackEvent', 'button', 'click', `购买:${orderInfo?.niudanji_name}`])
        // window.open(res.data, "_blank");
        navigate(`/web-view`, {
          state: {
            url: res.data,
            title: orderInfo?.niudanji_name,
            orderId: orderId,
            startBuying: true,
            activityId: id
          }
        })
        setStartBuying(true)
      } else {
        setStartBuying(false)
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const checkOrder = async () => {
    if (fetchNum.current >= 14) {
      setSuccess(false)
      setStartBuying(false)
      navigate('/order-center', {
        state: {
          id: 1
        }
      })
      clearInterval(intervalId.current)
      return
    }
    try {
      const res = await api.getUserOrderInfo(orderId)
      if (res.kind === 'ok') {
        if (res.data?.award_list.length > 0) {
          setSuccess(true)
          setStartBuying(false)
          setShowAgreement(false)
          setConsentAgreement(false)
          setSelectPay('ALI')
          onSubmit(res.data)
        }
      } else {
        GToast.current.handleErrorResponse(res)
        setSuccess(false)
      }
    } catch (error) {
      setSuccess(false)
      console.log('error', error)
    }
  }

  const fetchInfo = async () => {
    try {
      const res = await api.getUserOrderInfo(orderId)
      if (res.kind === 'ok') {
        setOrderInfo(res.data)
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (navType === 'POP' && location.key !== 'default') {
      close()
    }
  }, [location])

  useEffect(() => {
    if (open) {
      setShadowStyle({
        boxShadow: `0 0 0px 0px ${colors.transparent}`,
        borderRadius: fitSize(16)
      })
      setSuccess(false)
      fetchInfo()
    }
    return () => {
      setSuccess(false)
    }
  }, [open])

  const [shadowStyle, setShadowStyle] = useSpring(() => ({
    to: {
      boxShadow: '0 0 10px 5px rgba(255, 0, 0, 0.8)',
      borderRadius: fitSize(16)
    },
    from: {
      boxShadow: `0 0 0px 0px ${colors.transparent}`,
      borderRadius: fitSize(16)
    },
    reset: true,
    onRest: () => {
      // 当动画完成时，等待2秒后取消动画
      setTimeout(() => {
        setShadowStyle({ boxShadow: '0 0 0px 0px rgba(255, 0, 0, 0)' })
      }, 2000)
    }
  }))

  const close = () => {
    setShowAgreement(false)
    setConsentAgreement(false)
    setSelectPay('ALI')
    onClose()
    setSuccess(false)
    setStartBuying(false)
    clearInterval(intervalId.current)
  }

  useEffect(() => {
    if (startBuying) {
      intervalId.current = setInterval(() => {
        fetchNum.current++
        checkOrder()
      }, 2000)
    }
    if (success) clearInterval(intervalId.current)
    return () => {
      clearInterval(intervalId.current)
    }
  }, [startBuying])

  return (
    <Modal
      isOpen={open}
      onRequestClose={close}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById('root') as HTMLElement}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          paddingTop: fitSize(55)
        }}
      >
        <div
          style={{
            display: startBuying ? 'flex' : 'none',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            position: 'absolute',
            zIndex: 9999
          }}
        >
          <div
            style={{
              display: 'flex',
              alignSelf: 'center',
              borderRadius: fitSize(10),
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: fitSize(120),
              backgroundColor: colors.palette.neutral900,
              width: fitSize(150),
              height: fitSize(150)
            }}
          >
            <ReactLoading type={'bars'} color={'#62FFD0'} height={80} width={80} />
            <span
              style={{
                fontSize: fitSize(16),
                color: '#62FFD0',
                fontWeight: 500,
                marginBottom: fitSize(10),
                fontFamily: fontName.PingFangSC,
                marginTop: 20
              }}
            >
              支付中...
            </span>
            {/* <div
                style={{
                  width: "100%",
                  height: fitSize(102),
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    fontSize: fitSize(16),
                    color: colors.palette.neutral100,
                    fontWeight: 500,
                    marginBottom: fitSize(10),
                    fontFamily: fontName.PingFangSC,
                    alignSelf: "center",
                    marginTop: 10,
                  }}
                >
                  是否完成订单购买
                </span>
              </div>
              <div
                style={{
                  height: fitSize(30),
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Touchable
                  style={{
                    height: fitSize(30),
                    width: fitSize(127),
                    borderWidth: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #ffffff77",
                  }}
                  onClick={() => {
                    checkOrder();
                    setStartBuying(false);
                  }}
                >
                  <span
                    style={{
                      fontSize: fitSize(13),
                      color: colors.palette.neutral100,
                      fontWeight: 500,
                      marginBottom: fitSize(10),
                      fontFamily: fontName.PingFangSC,
                      alignSelf: "center",
                      marginTop: 10,
                    }}
                  >
                    取消
                  </span>
                </Touchable>
                <Touchable
                  style={{
                    height: fitSize(30),
                    width: fitSize(127),
                    borderWidth: 1,
                    borderColor: "red",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #ffffff77",
                  }}
                  onClick={() => {
                    checkOrder();
                    setStartBuying(false);
                  }}
                >
                  <span
                    style={{
                      fontSize: fitSize(13),
                      color: colors.palette.neutral100,
                      fontWeight: 500,
                      marginBottom: fitSize(10),
                      fontFamily: fontName.PingFangSC,
                      alignSelf: "center",
                      marginTop: 10,
                    }}
                  >
                    确认
                  </span>
                </Touchable>
              </div> */}
          </div>
        </div>
        {/* <SimpleHeader title="订单确认" leftAction={close} /> */}
        <div
          style={{
            width: fitSize(355),
            height: fitSize(195),
            backgroundColor: colors.palette.neutral100,
            alignSelf: 'center',
            borderRadius: 2,
            boxShadow: '0px 0px 10px 0px rgba(218,218,230,0.2)',
            // marginLeft fitSize(10),
            // paddingRight: fitSize(11),
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              height: fitSize(81),
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: fitSize(14),
              marginLeft: fitSize(10)
            }}
          >
            <img
              src={orderInfo?.niudanji_img}
              alt=""
              style={{
                width: fitSize(81),
                height: fitSize(81)
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: fitSize(14),
                flexGrow: 1
              }}
            >
              <span
                style={{
                  fontSize: fitSize(16),
                  color: colors.palette.neutral30,
                  fontWeight: 500,
                  marginBottom: fitSize(10),
                  fontFamily: fontName.PingFangSC
                }}
              >
                {orderInfo?.niudanji_name}
              </span>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span
                  style={{
                    fontSize: fitSize(16),
                    color: '#FF1F02',
                    fontFamily: fontName.PinFangSCSemibold,
                    fontWeight: 500
                  }}
                >
                  <span
                    style={{
                      fontSize: fitSize(12),
                      color: '#FF1F02',
                      fontFamily: fontName.PinFangSCSemibold,
                      fontWeight: 500,
                      marginRight: fitSize(3)
                    }}
                  >
                    ¥
                  </span>
                  {(orderInfo?.single_price / 100).toFixed(2)}
                </span>
                <span
                  style={{
                    fontSize: fitSize(13),
                    color: '#aaaaaa',
                    fontWeight: 400,
                    fontFamily: fontName.PingFangSC,
                    paddingRight: fitSize(21)
                  }}
                >
                  x{orderInfo?.nums}
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              height: fitSize(50),
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 10',
              marginTop: fitSize(3),
              paddingLeft: fitSize(10)
            }}
          >
            <span
              style={{
                fontSize: fitSize(14),
                color: '#333333',
                fontWeight: 400,
                fontFamily: fontName.PingFangSC
              }}
            >
              优惠劵
            </span>
            <span
              style={{
                fontSize: fitSize(13),
                color: '#FF1F02',
                fontWeight: 400,
                fontFamily: fontName.PingFangSC,
                paddingRight: fitSize(21)
              }}
            >
              <span
                style={{
                  fontSize: fitSize(13),
                  color: '#FF1F02',
                  fontWeight: 400,
                  fontFamily: fontName.PingFangSC,
                  marginRight: fitSize(2)
                }}
              >
                -
              </span>
              <span
                style={{
                  fontSize: fitSize(13),
                  color: '#FF1F02',
                  fontWeight: 400,
                  fontFamily: fontName.PingFangSC,
                  marginRight: fitSize(2)
                }}
              >
                ¥
              </span>
              {((orderInfo?.single_price * orderInfo?.nums) / 100 - orderInfo?.price / 100).toFixed(2)}
            </span>
          </div>
          <div
            style={{
              width: '100%',
              height: fitSize(50),
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 10',
              paddingLeft: fitSize(10)
            }}
          >
            <span
              style={{
                fontSize: fitSize(14),
                color: '#333333',
                fontWeight: 400,
                fontFamily: fontName.PingFangSC
              }}
            >
              合计
            </span>
            <span
              style={{
                fontSize: fitSize(13),
                color: '#212F3B',
                fontWeight: 400,
                fontFamily: fontName.PingFangSC,
                paddingRight: fitSize(21)
              }}
            >
              <span
                style={{
                  fontSize: fitSize(13),
                  color: '#212F3B',
                  fontWeight: 400,
                  fontFamily: fontName.PingFangSC,
                  marginRight: fitSize(2)
                }}
              >
                ¥
              </span>
              {(orderInfo?.price / 100).toFixed(2)}
            </span>
          </div>
        </div>
        <Touchable
          style={{
            width: fitSize(355),
            height: fitSize(50),
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 10',
            alignSelf: 'center',
            backgroundColor: colors.palette.neutral100,
            marginTop: fitSize(10)
          }}
          onClick={() => {
            setSelectPay('ALI')
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: fitSize(10),
              alignItems: 'center'
            }}
          >
            <img
              src={Images.activityNew.ali_pay}
              alt=""
              style={{
                width: fitSize(22),
                height: fitSize(22),
                marginRight: fitSize(8)
              }}
            />
            <span
              style={{
                fontSize: fitSize(14),
                color: '#333333',
                fontWeight: 400,
                fontFamily: fontName.PingFangSC
              }}
            >
              支付宝
            </span>
          </div>
          <img
            src={selectPay === 'ALI' ? Images.activityNew.y_select : Images.activityNew.n_select}
            alt=""
            style={{
              width: fitSize(16),
              height: fitSize(16),
              paddingRight: fitSize(11)
            }}
          />
        </Touchable>
        {/* <Touchable
            style={{
              width: fitSize(355),
              height: fitSize(50),
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 10",
              alignSelf: "center",
              backgroundColor: colors.palette.neutral100,
            }}
            onClick={() => {
              setSelectPay("WX");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: fitSize(10),
                alignItems: "center",
              }}
            >
              <img
                src={Images.activityNew.wx_pay}
                alt=""
                style={{
                  width: fitSize(22),
                  height: fitSize(22),
                  marginRight: fitSize(8),
                }}
              />
              <span
                style={{
                  fontSize: fitSize(14),
                  color: "#333333",
                  fontWeight: 400,
                  fontFamily: fontName.PingFangSC,
                }}
              >
                微信
              </span>
            </div>
            <img
              src={
                selectPay === "WX"
                  ? Images.activityNew.y_select
                  : Images.activityNew.n_select
              }
              alt=""
              style={{
                width: fitSize(16),
                height: fitSize(16),
                paddingRight: fitSize(11),
              }}
            />
          </Touchable> */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            // alignSelf: "center",
            marginTop: fitSize(10),
            marginLeft: fitSize(10)
          }}
        >
          <span
            style={{
              fontSize: fitSize(12),
              fontWeight: 400,
              fontFamily: fontName.PingFangSC,
              color: '#aaaaaa'
            }}
          >
            若完成交易代表您已同意以下约定
          </span>
          <span
            style={{
              fontSize: fitSize(12),
              fontWeight: 400,
              fontFamily: fontName.PingFangSC,
              color: '#aaaaaa'
            }}
          >
            1.本平台禁止未成年消费。
          </span>
          <span
            style={{
              fontSize: fitSize(12),
              fontWeight: 400,
              fontFamily: fontName.PingFangSC,
              color: '#aaaaaa'
            }}
          >
            2.由于扭蛋商品特殊属性，购买后不支持退款。
          </span>
          <span
            style={{
              fontSize: fitSize(12),
              fontWeight: 400,
              fontFamily: fontName.PingFangSC,
              color: '#aaaaaa'
            }}
          >
            3.港澳台地区及部分偏远地区无法配送奖品。
          </span>
          <span
            style={{
              fontSize: fitSize(12),
              fontWeight: 400,
              fontFamily: fontName.PingFangSC,
              color: '#aaaaaa'
            }}
          >
            4.实物奖品将在申请提货后的3-7个工作日内发货，如有疑问请随时咨询在线客服。
          </span>
          <span
            style={{
              fontSize: fitSize(12),
              fontWeight: 400,
              fontFamily: fontName.PingFangSC,
              color: '#aaaaaa'
            }}
          >
            5.因技术原因，IOS用户在购买成功后需要手动切换回Metro大都会APP进行扭蛋，给您带来的不便敬请谅解。
          </span>
        </div>
        <div
          style={{
            height: fitSize(129),
            width: '100%',
            backgroundColor: colors.palette.neutral100,
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            bottom: 50,
            paddingLeft: fitSize(14)
          }}
        >
          <Touchable
            style={{
              width: fitSize(347),
              height: fitSize(46),
              backgroundImage: `url(${Images.activityNew.order_btn})`,
              backgroundSize: '100% 100%',
              marginTop: fitSize(10),
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row'
            }}
            onClick={() => {
              onPay()
            }}
          >
            <span
              style={{
                fontSize: fitSize(13),
                fontFamily: fontName.PinFangSCSemibold,
                fontWeight: 500,
                color: '#212F3B',
                marginRight: fitSize(4),
                marginTop: fitSize(11)
              }}
            >
              立即支付
            </span>
            <span
              style={{
                fontSize: fitSize(13),
                fontFamily: fontName.PinFangSCSemibold,
                fontWeight: 500,
                color: '#212F3B',
                marginTop: fitSize(11)
              }}
            >
              <span
                style={{
                  fontSize: fitSize(13),
                  fontFamily: fontName.PinFangSCSemibold,
                  fontWeight: 500,
                  color: '#212F3B',
                  marginRight: fitSize(2)
                }}
              >
                ¥
              </span>
              {(orderInfo?.price / 100).toFixed(2)}
            </span>
          </Touchable>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: fitSize(12),
              paddingLeft: fitSize(53)
            }}
          >
            <animated.div
              style={{
                width: fitSize(16),
                height: fitSize(16),
                marginRight: fitSize(11),
                borderRadius: fitSize(16),
                ...shadowStyle
              }}
            >
              <img
                src={consentAgreement ? Images.activityNew.y_select : Images.activityNew.n_select}
                alt=""
                style={{
                  width: fitSize(16),
                  height: fitSize(16)
                }}
                onClick={() => {
                  setConsentAgreement(v => !v)
                }}
              />
            </animated.div>
            <span
              style={{
                marginLeft: fitSize(2),
                fontSize: fitSize(12),
                fontFamily: fontName.PinFangSCSemibold,
                fontWeight: 400,
                color: '#AAAAAA'
              }}
            >
              我已满18岁，已阅读并同意
            </span>
            <span
              style={{
                fontSize: fitSize(12),
                fontFamily: fontName.PinFangSCSemibold,
                fontWeight: 400,
                color: '#333333'
              }}
              onClick={() => {
                setShowAgreement(true)
              }}
            >
              《用户协议》
            </span>
          </div>
        </div>
      </div>

      <AgreementModal
        open={showAgreement}
        onClose={() => {
          setShowAgreement(false)
        }}
      />
    </Modal>
  )
})
