import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import style from "./index.module.css";
import { Images, colors, fitSize, fontName, maxWebWidth } from "../theme";

interface BottomTabNavigatorProps {}

const tabList = [
  {
    path: "/",
    title: "首页",
    iconActive: Images.tab.home_a,
    iconStatic: Images.tab.home_u,
  },
  {
    path: "/store",
    title: "提货",
    iconActive: Images.tab.store_a,
    iconStatic: Images.tab.store_u,
  },
  {
    path: "/mine",
    title: "我的",
    iconActive: Images.tab.mine_a,
    iconStatic: Images.tab.mine_u,
  },
];

export const BottomTabNavigator: FC<BottomTabNavigatorProps> = observer(() => {
  const CURRENT_TAB = ["/", "/store", "/mine"];
  const location = useLocation();
  const [showTab, setShowTab] = useState(true);

  useEffect(() => {
    setShowTab(CURRENT_TAB.includes(location.pathname));
  }, [location.pathname]);

  return (
    <div
      style={{
        height: 60,
        display: showTab ? "flex" : "none",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: "#ffffff",
        boxShadow: "inset 0px 1px 0px 0px rgba(0,0,0,0.08)",
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 999,
      }}
    >
      {tabList.map((item) => {
        return (
          <NavLink
            key={item.path}
            to={item.path}
            className={style.tab}
            style={{
              color:
                location.pathname === item.path
                  ? colors.palette.neutral900
                  : colors.palette.neutral50,
            }}
          >
            <img
              src={
                location.pathname === item.path
                  ? item.iconActive
                  : item.iconStatic
              }
              alt=""
              style={{
                width: fitSize(20),
                height: fitSize(20),
                marginBottom: 5,
              }}
            />
            <span
              style={{
                fontSize: fitSize(10),
                fontWeight: 400,
                color: location.pathname === item.path ? "#767E85" : "#C6C9CC",
                // fontFamily: fontName.PingFangSC,
              }}
            >
              {item.title}
            </span>
          </NavLink>
        );
      })}
    </div>
  );
});
