import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const userInfoModel = types.model("userInfoStore").props({
  authorization: types.maybe(types.string),
  userId: types.maybe(types.number),
  userName: types.maybe(types.string),
  ddhId: types.maybe(types.string),
});

type userInfoStoreType = Instance<typeof userInfoModel>;
export interface userInfoStore extends userInfoStoreType {}
type userInfoStoreSnapshotType = SnapshotOut<typeof userInfoModel>;
export interface userInfoStoreSnapshot extends userInfoStoreSnapshotType {}
export const createuserInfoStoreDefaultModel = () =>
  types.optional(userInfoModel, {});
