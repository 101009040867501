import { observer } from "mobx-react-lite";
import React, { FC, useState, useEffect } from "react";
import "./logisticsDetails.css";
import { SimpleHeader } from "../../components";
import { Images, colors, fitSize, fontName } from "../../theme";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import dayjs from "dayjs";
import { doCopy } from "../../utils/doCopy";
import { showInfo } from "../../utils/showUtil";
import { Touchable } from "../../components/Button";
import { OrderData, api } from "../../services/api";
import { GToast } from "../../navigators";
import { useLocation, useParams } from "react-router-dom";
import { useStores } from "../../models";

interface LogisticsDetailsProps {}

export const LogisticsDetails: FC<LogisticsDetailsProps> = observer(({}) => {
  useDocumentTitle("物流详情");
  const { logisticsId } = useParams();
  const { accountStore } = useStores();
  const {
    state: { award_img },
  } = useLocation();
  const [info, setInfo] = useState<OrderData>();
  const fetchGetLogisticsDetails = async () => {
    try {
      const res = await api.LogisticsDetails(logisticsId);
      if (res.kind === "ok") {
        setInfo(res.data);
        console.log("res.data", res.data);
      } else {
        GToast.current.handleErrorResponse(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchGetLogisticsDetails();
  }, []);

  return (
    <div className={"container"}>
      {/* <SimpleHeader title="物流详情" /> */}
      <div className={"content"}>
        <div
          style={{
            width: "100%",
            height: fitSize(130),
            backgroundColor: colors.palette.neutral100,
            alignSelf: "center",
            borderRadius: 2,
            boxShadow: "0px 0px 10px 0px rgba(218,218,230,0.2)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: fitSize(14),
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: fitSize(90),
              alignItems: "center",
              justifyContent: "center",
              marginLeft: fitSize(12),
            }}
          >
            <img
              src={award_img}
              alt=""
              style={{
                width: fitSize(90),
                height: fitSize(90),
                border: "1px solid #F5F5F8",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: fitSize(14),
                flexGrow: 1,
              }}
            >
              <span
                style={{
                  fontSize: fitSize(12),
                  color: "#212F3B",
                  fontWeight: 500,
                  marginBottom: fitSize(2),
                  fontFamily: fontName.PinFangSCSemibold,
                }}
              >
                物流状态：{info?.info?.[0]?.logisticsStatusDesc}
              </span>
              <span
                style={{
                  fontSize: fitSize(12),
                  color: "#212F3B",
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                  marginBottom: fitSize(14),
                  marginTop: fitSize(14),
                }}
              >
                物流公司：{info?.info?.[0]?.logisticsCompanyName}
              </span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span
                  style={{
                    fontSize: fitSize(12),
                    color: "#212F3B",
                    fontWeight: 500,
                    fontFamily: fontName.PinFangSCSemibold,
                  }}
                >
                  物流单号：{logisticsId}
                </span>
                <Touchable
                  style={{
                    width: fitSize(46),
                    height: fitSize(18),
                    display: "flex",
                    justifyContent: "center",
                    backgroundImage: `url(${Images.home.home_hot_btn})`,
                    backgroundSize: "100% 100%",
                    marginRight: fitSize(20),
                  }}
                  onClick={() => {
                    doCopy(logisticsId);
                    showInfo("复制成功", "OK");
                  }}
                >
                  <span
                    style={{
                      fontSize: fitSize(11),
                      color: "#212F3B",
                      fontWeight: 400,
                      fontFamily: fontName.PingFangSC,
                    }}
                  >
                    复制
                  </span>
                </Touchable>
              </div>
            </div>
          </div>
        </div>
        <div className="package-info">
          <ul className="package-status">
            {info?.info?.[0]?.logisticsTraceDetailList
              .sort((a, b) => b.time - a.time)
              .filter((item, index, array) => {
                return (
                  item.logisticsStatus !== "SIGN" ||
                  index === 0 ||
                  item.logisticsStatus !== array[index - 1].logisticsStatus
                );
              })
              .map((item, index) => {
                return (
                  <li className="package-status-list" key={index}>
                    <div
                      style={
                        item?.logisticsStatus === "ACCEPT" ||
                        item?.logisticsStatus === "SIGN"
                          ? {
                              width: fitSize(20),
                              height: fitSize(20),
                              borderRadius: "50%",
                              backgroundColor: "#ffffff",
                              display: "inline-block",
                              position: "absolute",
                              left: -8,
                            }
                          : {
                              width: fitSize(12),
                              height: fitSize(12),
                              borderRadius: "50%",
                              backgroundColor: "#ffffff",
                              display: "inline-block",
                              position: "absolute",
                              left: -5,
                            }
                      }
                    >
                      {item?.logisticsStatus === "ACCEPT" ||
                      item?.logisticsStatus === "SIGN" ? (
                        <i
                          className={
                            index === 0 ? "icon-package-fh" : "icon-package-lj"
                          }
                        ></i>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <div
                      className="status-title"
                      style={
                        item?.logisticsStatus === "ACCEPT"
                          ? {
                              fontSize: fitSize(13),
                              color: "#212F3B",
                              fontWeight: 500,
                              fontFamily: fontName.PinFangSCSemibold,
                            }
                          : item?.logisticsStatus === "SIGN"
                          ? {
                              fontSize: fitSize(13),
                              color: "#F9D700",
                              fontWeight: 500,
                              fontFamily: fontName.PinFangSCSemibold,
                            }
                          : {
                              fontSize: fitSize(11),
                              color: "#777777",
                              fontWeight: 400,
                              fontFamily: fontName.PingFangSC,
                            }
                      }
                    >
                      {item?.logisticsStatus === "ACCEPT"
                        ? "收货地址"
                        : item?.logisticsStatus === "SIGN"
                        ? `已签收 ${dayjs(item?.time).format("YYYY.MM.DD")}`
                        : dayjs(item?.time).format("YYYY.MM.DD")}
                    </div>
                    <div className="status-details">{item?.desc}</div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
});
