import { useState, useEffect, FC } from "react";

let timer: NodeJS.Timeout;
export const useCountdown = (t = 60, openUserCard = () => {}) => {
  const [showVerification, setShowVerification] = useState(false);
  const [time, setTime] = useState(t);

  const onTime = () => {
    timer = setInterval(() => setTime((pre) => pre - 1), 1000);
    setShowVerification(true);
  };

  useEffect(() => {
    if (time > 0 && time < t) {
      return;
    } else {
      clearInterval(timer);
      setShowVerification(false);
      setTime(t);
      openUserCard();
    }
  }, [time]);

  useEffect(() => {
    clearInterval(timer);
    return () => clearInterval(timer);
  }, []);

  const onReset = () => {
    clearInterval(timer);
    setShowVerification(false);
    setTime(t);
  };

  return { showVerification, time, onTime, onReset };
};
