import React, { Component, ErrorInfo } from "react";
import { colors } from "../../theme";
import { Touchable } from "../../components/Button";

interface FallbackProps {
  errorMessage: string;
  resetError: () => void;
  copyErrorToClipboard: () => void;
  openTicket: () => void;
}

const Fallback: React.FC<FallbackProps> = (props) => {
  const reportStepStyles = { ...styles.reportStep, ...styles.text };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <div style={styles.header}>
          <h1 style={styles.title}>出错了</h1>
          <p style={styles.subtitle}>以下是错误信息</p>
        </div>
        <div style={styles.errorContainer}>
          <p style={styles.error}>{props.errorMessage ?? "not is error"}</p>
        </div>
        <div style={styles.header}>
          <Touchable style={styles.button} onClick={props.resetError}>
            重试
          </Touchable>
        </div>
        <div style={styles.textContainer}>
          <p style={styles.text}>如有必要请向我们反馈错误:</p>
          <div style={styles.reportTextContainer}>
            <p style={styles.text}>提供错误截图</p>
            <p style={reportStepStyles}>
              <Touchable
                onClick={props.copyErrorToClipboard}
                style={styles.link}
              >
                复制
              </Touchable>{" "}
              错误到剪贴板
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

interface Props {
  children: React.ReactNode;
  catchErrors: "always" | "test" | "prod" | "ppe";
}

interface State {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export class ErrorBoundary extends Component<Props, State> {
  state = { error: null, errorInfo: null };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  resetError = () => {
    this.setState({ error: null, errorInfo: null });
  };

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>
  ): boolean {
    return nextState.error !== this.state.error;
  }

  getErrorMessage = () =>
    this.state.error === null
      ? null
      : `View: ${this.props.children}\n${this.state.error.toString()}`;

  copyErrorToClipboard = async () => {
    await navigator.clipboard.writeText(this.getErrorMessage());
  };

  openTicket = () => {
    const url = "https://www.cbatime.com/";
    window.open(url);
  };

  render() {
    return this.state.error ? (
      <Fallback
        errorMessage={this.getErrorMessage()}
        resetError={this.resetError}
        copyErrorToClipboard={this.copyErrorToClipboard}
        openTicket={this.openTicket}
      />
    ) : (
      this.props.children
    );
  }
}

const styles = {
  container: {
    flex: 1,
    marginTop: 44,
  },
  content: {
    padding: "0 24px",
    flex: 1,
  },
  header: {
    alignItems: "center",
  },
  errorImage: {
    width: 50,
    height: 50,
    marginTop: 24,
  },
  title: {
    color: colors.palette.black,
    fontSize: 24,
    lineHeight: "34px", // Adjusted lineHeight value
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 14,
    lineHeight: "20px", // Adjusted lineHeight value
    color: colors.palette.lightGrey,
    marginTop: 8,
    textAlign: "center" as "center", // Fix the type issue here
  },
  errorContainer: {
    backgroundColor: colors.palette.orange,
    borderRadius: 8,
    marginTop: 24,
  },
  error: {
    color: colors.palette.black,
    padding: 8,
    fontSize: 14,
    lineHeight: "20px", // Adjusted lineHeight value
  },
  button: {
    marginTop: 24,
    borderColor: colors.palette.orangeDarker,
    borderWidth: 1,
    borderRadius: 50,
    padding: 12,
    cursor: "pointer",
    fontWeight: "500",
    width: "100%",
  },
  buttonText: {
    color: colors.palette.orangeDarker,
    textAlign: "center" as "center", // Fix the type issue here
  },
  textContainer: {
    marginTop: 24,
  },
  text: {
    lineHeight: "20px", // Adjusted lineHeight value
  },
  link: {
    color: colors.palette.orangeDarker,
    cursor: "pointer",
  },
  reportTextContainer: {
    paddingLeft: 14,
    marginTop: 16,
    marginBottom: 24,
  },
  reportStep: {
    marginTop: 14,
  },
};

export default ErrorBoundary;
