export const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf("Apple") > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf("CriOS") == -1 &&
  navigator.userAgent.indexOf("FxiOS") == -1;

export const isIos =
  /^iP/.test(navigator.platform) ||
  (/^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4);

export const isBaiDu = navigator.userAgent.toLowerCase().indexOf("baidu") > 0;

export const isQuarkBrowser = navigator.userAgent
  .toLowerCase()
  .includes("quark");

export const isUCBrowser = navigator.userAgent
  .toLowerCase()
  .includes("ucbrowser");

export const isQQBrowser =
  navigator.userAgent.indexOf("MQQBrowser") > -1 ||
  navigator.userAgent.indexOf("QQTheme") > -1;

export const isMIBrowser = /MiuiBrowser/i.test(navigator.userAgent);

export const isChromeBrowser = navigator.userAgent.indexOf("Chrome") > -1;

export const platform = (): "Browser" | "Webview" => {
  const standalone = (window.navigator as any).standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) {
    if (!standalone && safari) {
      // Safari
      return "Browser";
    } else if (!standalone && !safari) {
      // iOS webview
      return "Webview";
    }
  } else {
    if (userAgent.includes("wv")) {
      // Android webview
      return "Webview";
    } else {
      // Chrome
      return "Browser";
    }
  }
};
