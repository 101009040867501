import React, { useEffect } from "react";
import { Images, colors, fitSize, fontName } from "../theme";
import ReactLoading from "react-loading";

export const LoadCircleModal = ({ msg }) => {
  return (
    <div
      style={{
        display: "flex",
        alignSelf: "center",
        borderRadius: fitSize(10),
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.palette.neutral900,
        width: fitSize(150),
        height: fitSize(150),
      }}
    >
      <ReactLoading type={"bars"} color={"#62FFD0"} height={80} width={80} />
      <span
        style={{
          fontSize: fitSize(16),
          color: "#62FFD0",
          fontWeight: 500,
          marginBottom: fitSize(10),
          fontFamily: fontName.PingFangSC,
          marginTop: 20,
        }}
      >
        {msg || "Loading"}
      </span>
    </div>
  );
};
