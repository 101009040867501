import Modal from "react-modal";
import React, { useEffect } from "react";
import {
  Location,
  NavigationType,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import {
  Images,
  colors,
  fitSize,
  fontName,
  windowHeight,
  windowWidth,
} from "../../../theme";
import { Touchable } from "../../../components/Button";

interface TipModalProps {
  isShow: boolean;
  closeModal: () => void;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: windowWidth,
    height: windowHeight,
    backgroundColor: colors.palette.neutral900 + "77",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  overlay: { zIndex: 1000 },
};

export const TipModal: React.FC<TipModalProps> = ({ closeModal, isShow }) => {
  const location: Location = useLocation();
  const navType: NavigationType = useNavigationType();

  useEffect(() => {
    if (navType === "POP" && location.key !== "default") {
      closeModal();
    }
  }, [location]);

  return (
    <Modal
      isOpen={isShow}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById("root") as HTMLElement}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#ffffff",
          width: fitSize(305),
          height: fitSize(358),
          alignSelf: "center",
          borderRadius: fitSize(4),
          position: "relative",
        }}
      >
        <Touchable
          onClick={closeModal}
          style={{
            width: fitSize(14),
            height: fitSize(14),
            alignSelf: "flex-end",
            marginRight: 20,
            marginTop: 10,
          }}
        >
          <img
            src={Images.components.close}
            style={{
              width: "100%",
              height: "100%",
            }}
            alt=""
          />
        </Touchable>
        <span
          style={{
            fontSize: fitSize(16),
            color: "#212F3B",
            fontWeight: 500,
            fontFamily: fontName.PingFangSC,
            width: "100%",
            textAlign: "center",
          }}
        >
          虚拟奖品说明
        </span>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "90%",
              height: fitSize(205),
              overflowX: "hidden",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              marginTop: fitSize(14),
            }}
          >
            <span
              style={{
                fontSize: fitSize(12),
                color: "#767E85",
                fontWeight: 400,
                fontFamily: fontName.PinFangSCSemibold,
                marginBottom: fitSize(4),
              }}
            >
              1、如果您抽到的是优惠券、重抽卡类虚拟奖品，可在“我的-优惠券”或者“我的-道具卡”页面查看。
            </span>
            <span
              style={{
                fontSize: fitSize(12),
                color: "#767E85",
                fontWeight: 400,
                fontFamily: fontName.PinFangSCSemibold,
                marginBottom: fitSize(4),
              }}
            >
              2、如果您抽到的是需要根据“CDKEY”来兑换的虚拟物品，可点击“查看CDKEY”，复制完成后，前往对应的APP，前去兑换。
            </span>
            <span
              style={{
                fontSize: fitSize(12),
                color: "#767E85",
                fontWeight: 400,
                fontFamily: fontName.PinFangSCSemibold,
                marginBottom: fitSize(4),
              }}
            >
              3、如果您抽到的是大都会M豆、地铁乘车次卡、地铁乘车码皮肤，这些奖品将会直接发往您的大都会账户，可通过以下路径，进行查看：
            </span>
            <span
              style={{
                fontSize: fitSize(12),
                color: "#767E85",
                fontWeight: 400,
                fontFamily: fontName.PinFangSCSemibold,
                marginBottom: fitSize(4),
              }}
            >
              M豆：我的-M豆
            </span>
            <span
              style={{
                fontSize: fitSize(12),
                color: "#767E85",
                fontWeight: 400,
                fontFamily: fontName.PinFangSCSemibold,
                marginBottom: fitSize(4),
              }}
            >
              皮肤：我的-换肤
            </span>
            <span
              style={{
                fontSize: fitSize(12),
                color: "#767E85",
                fontWeight: 400,
                fontFamily: fontName.PinFangSCSemibold,
                marginBottom: fitSize(4),
              }}
            >
              通用券：我的-优惠券
            </span>
          </div>
          <Touchable
            style={{
              width: "90%",
              height: fitSize(52),
              backgroundImage: `url(${Images.activityNew.tip_btn})`,
              backgroundSize: "100% 100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: fitSize(20),
            }}
            onClick={closeModal}
          >
            <span
              style={{
                fontSize: fitSize(13),
                color: "#212F3B",
                fontWeight: 500,
                fontFamily: fontName.PingFangSC,
                marginBottom: fitSize(6),
              }}
            >
              我知道了
            </span>
          </Touchable>
        </div>
      </div>
    </Modal>
  );
};
