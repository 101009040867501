import React, {
  useState,
  useEffect,
  ReactElement,
  useRef,
  Fragment,
} from "react";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import { Images, colors, fitSize, fontName } from "../theme";
import { Touchable } from "./Button";
import styles from "./index.module.css";

interface ProvinceCityAreaSelectProps {
  onSubmit: (value: string) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Area {
  code: string;
  name: string;
}

interface City {
  code: string;
  name: string;
  children: Area[];
}

interface Province {
  code: string;
  name: string;
  children: City[];
}

export const ProvinceCityAreaSelect: React.FC<ProvinceCityAreaSelectProps> = ({
  onSubmit,
  open,
  setOpen,
}): ReactElement => {
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [select, setSelect] = useState({
    province: "",
    city: "",
    area: "",
  });
  const sheetRef = useRef<BottomSheetRef>();
  const focusRef = useRef<HTMLButtonElement>();
  const H = fitSize(600);

  useEffect(() => {
    const getData = async () => {
      fetch("/provinceCityArea.json")
        .then((response) => response.json())
        .then((data) => setProvinces(data))
        .catch((error) => console.error("加载数据时出错：", error));
    };
    getData();
  }, []);

  const handleProvinceChange = (value: string) => {
    setSelectedProvince(value);
    setSelectedCity("");
    setSelectedArea("");
    setSelect((value) => {
      return { ...value, city: "", area: "" };
    });
  };

  const handleCityChange = (value: string) => {
    setSelectedCity(value);
    setSelectedArea("");
    setSelect((value) => {
      return { ...value, area: "" };
    });
  };

  const handleAreaChange = (value: string, address: string) => {
    setSelectedArea(value);
    onSubmit(`${select.province} ${select.city} ${address}`);
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedProvince("");
    setSelectedArea("");
    setSelectedCity("");
    setSelect({
      province: "",
      city: "",
      area: "",
    });
  };

  return (
    <BottomSheet
      open={open}
      skipInitialTransition
      ref={sheetRef}
      initialFocusRef={focusRef}
      defaultSnap={({ height }) => {
        return H;
      }}
      snapPoints={({ height, maxHeight }) => {
        return [H, H];
      }}
      scrollLocking={true}
      // expandOnContentDrag
      style={{ display: open ? "flex" : "none" }}
      blocking={false}
    >
      <div
        style={{
          width: "100%",
          height: H,
          display: "flex",
          flexDirection: "column",
          borderTopRightRadius: fitSize(10),
          borderTopLeftRadius: fitSize(10),
          backgroundColor: colors.palette.neutral100,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: fitSize(40),
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: fitSize(5),
          }}
        >
          <div style={{ width: fitSize(30), height: fitSize(30) }} />
          <span
            style={{
              fontSize: fitSize(16),
              fontWeight: 600,
              color: colors.palette.neutral30,
              fontFamily: fontName.PinFangSCSemibold,
            }}
          >
            选择所在地区
          </span>
          <Touchable
            style={{
              width: fitSize(12),
              height: fitSize(12),
              marginRight: fitSize(15),
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            onClick={closeModal}
          >
            <img
              src={Images.components.close}
              alt=""
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Touchable>
        </div>
        <div className={styles.package_status}>
          <div className={styles.status_box}>
            <ul className={styles.status_list}>
              <li style={{ display: selectedProvince.length === 0 && "none" }}>
                <Touchable
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  onClick={() => {
                    handleProvinceChange("");
                  }}
                >
                  <div className={styles.status_content_before}>
                    {select.province}
                  </div>
                  <div className={styles.status_content_before}>
                    <img
                      src={Images.mine.mine_right}
                      alt=""
                      style={{
                        width: fitSize(10),
                        height: fitSize(10),
                        marginRight: fitSize(10),
                      }}
                    />
                  </div>
                </Touchable>
                {/* <div className={styles.status_line}></div> */}
              </li>
              <li style={{ display: selectedCity.length === 0 && "none" }}>
                <Touchable
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                  onClick={() => {
                    handleCityChange("");
                  }}
                >
                  <div className={styles.status_content_before}>
                    {select.city}
                  </div>
                  <div className={styles.status_content_before}>
                    <img
                      src={Images.mine.mine_right}
                      alt=""
                      style={{
                        width: fitSize(10),
                        height: fitSize(10),
                        marginRight: fitSize(10),
                      }}
                    />
                  </div>
                </Touchable>
                {/* <div className={styles.status_time_before}>
                    2017-08-18 09:11 周五
                  </div> */}
                {/* <div className={styles.status_line}></div> */}
              </li>
              <li style={{ display: selectedArea.length === 0 && "none" }}>
                <Touchable
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  onClick={() => {
                    // handleAreaChange("");
                  }}
                >
                  <div className={styles.status_content_before}>
                    {select.area}
                  </div>
                  <div className={styles.status_content_before}>
                    <img
                      src={Images.mine.mine_right}
                      alt=""
                      style={{
                        width: fitSize(10),
                        height: fitSize(10),
                        marginRight: fitSize(10),
                      }}
                    />
                  </div>
                </Touchable>
                {/* <div className={styles.status_line}></div> */}
              </li>
            </ul>
          </div>
        </div>
        <div
          style={{
            height: 1,
            width: "100%",
            backgroundColor: "#9a9a9a",
            marginTop: fitSize(30),
            marginBottom: fitSize(10),
          }}
        />
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            width: "100%",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          {selectedProvince === "" &&
          selectedCity === "" &&
          selectedArea === "" ? (
            <Fragment>
              {provinces.map((province) => (
                <div
                  style={{
                    display: "flex",
                    width: fitSize(327),
                    flexDirection: "column",
                    alignSelf: "center",
                  }}
                  key={province.code}
                >
                  <Touchable
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: fitSize(5),
                    }}
                    onClick={() => {
                      handleProvinceChange(province.code);
                      setSelect((value) => {
                        return { ...value, province: province.name };
                      });
                    }}
                  >
                    <span
                      style={{
                        marginTop: fitSize(15),
                        marginLeft: fitSize(10),
                        fontSize: fitSize(14),
                        fontWeight: 600,
                        color: colors.palette.neutral30,
                        fontFamily: fontName.PinFangSCSemibold,
                        textAlign: "center",
                      }}
                    >
                      {province.name}
                    </span>
                    <div
                      style={{
                        width: fitSize(12),
                        height: fitSize(12),
                        backgroundImage: `url(${
                          province.code === selectedProvince
                            ? Images.mine.address_check_a
                            : Images.mine.address_check_u
                        })`,
                        backgroundSize: "100% 100%",
                        marginRight: fitSize(15),
                      }}
                    />
                  </Touchable>
                  <div
                    style={{
                      height: 1,
                      width: "100%",
                      backgroundColor: "#C6C9CC99",
                      marginBottom: fitSize(15),
                    }}
                  />
                </div>
              ))}
            </Fragment>
          ) : selectedProvince !== "" &&
            selectedCity === "" &&
            selectedArea === "" ? (
            <Fragment>
              {provinces
                .find((province) => province.code === selectedProvince)
                ?.children.map((city) => (
                  <div
                    style={{
                      display: "flex",
                      width: fitSize(327),
                      flexDirection: "column",
                      alignSelf: "center",
                    }}
                    key={city.code}
                  >
                    <Touchable
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: fitSize(5),
                      }}
                      onClick={() => {
                        handleCityChange(city.code);
                        setSelect((value) => {
                          return { ...value, city: city.name };
                        });
                      }}
                    >
                      <span
                        style={{
                          marginTop: fitSize(15),
                          marginLeft: fitSize(10),
                          fontSize: fitSize(14),
                          fontWeight: 600,
                          color: colors.palette.neutral30,
                          fontFamily: fontName.PinFangSCSemibold,
                          textAlign: "center",
                        }}
                      >
                        {city.name}
                      </span>
                      <div
                        style={{
                          width: fitSize(12),
                          height: fitSize(12),
                          backgroundImage: `url(${
                            city.code === selectedCity
                              ? Images.mine.address_check_a
                              : Images.mine.address_check_u
                          })`,
                          backgroundSize: "100% 100%",
                          marginRight: fitSize(15),
                        }}
                      />
                    </Touchable>
                    <div
                      style={{
                        height: 1,
                        width: "100%",
                        backgroundColor: "#C6C9CC99",
                        marginBottom: fitSize(15),
                      }}
                    />
                  </div>
                ))}
            </Fragment>
          ) : (
            <Fragment>
              {provinces
                .find((province) => province.code === selectedProvince)
                ?.children.find((city) => city.code === selectedCity)
                ?.children.map((city) => (
                  <div
                    style={{
                      display: "flex",
                      width: fitSize(327),
                      flexDirection: "column",
                      alignSelf: "center",
                    }}
                    key={city.code}
                  >
                    <Touchable
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: fitSize(5),
                      }}
                      onClick={() => {
                        handleAreaChange(city.code, city.name);
                        setSelect((value) => {
                          return { ...value, area: city.name };
                        });
                      }}
                    >
                      <span
                        style={{
                          marginTop: fitSize(15),
                          marginLeft: fitSize(10),
                          fontSize: fitSize(14),
                          fontWeight: 600,
                          color: colors.palette.neutral30,
                          fontFamily: fontName.PinFangSCSemibold,
                          textAlign: "center",
                        }}
                      >
                        {city.name}
                      </span>
                      <div
                        style={{
                          width: fitSize(12),
                          height: fitSize(12),
                          backgroundImage: `url(${
                            city.code === selectedArea
                              ? Images.mine.address_check_a
                              : Images.mine.address_check_u
                          })`,
                          backgroundSize: "100% 100%",
                          marginRight: fitSize(15),
                        }}
                      />
                    </Touchable>
                    <div
                      style={{
                        height: 1,
                        width: "100%",
                        backgroundColor: "#C6C9CC99",
                        marginBottom: fitSize(15),
                      }}
                    />
                  </div>
                ))}
            </Fragment>
          )}
        </div>
      </div>
    </BottomSheet>
  );
};
