export interface ConfigBaseProps {
  persistNavigation: "always" |  "test" | "prod" | "ppe"
  catchErrors: "always" | "test" | "prod" | "ppe"
  exitRoutes: string[]
}

export type PersistNavigationConfig = ConfigBaseProps["persistNavigation"]

const BaseConfig: ConfigBaseProps = {
  persistNavigation: "test",
  catchErrors: "always",
  exitRoutes: ["Welcome"],
}

export default BaseConfig
