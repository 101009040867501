import { observer } from "mobx-react-lite";
import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { SimpleHeader } from "../../components/SimpleHeader";
import { Images, colors, fitSize, fontName } from "../../theme";
import { useLocation, useNavigate } from "react-router-dom";
import { showInfo } from "../../utils/showUtil";
import { Touchable } from "../../components/Button";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { AllAddressItem, api } from "../../services/api";
import { GToast } from "../../navigators";

interface AddressManageProps {}

export const AddressManage: FC<AddressManageProps> = observer(() => {
  useDocumentTitle("地址管理");
  const {
    state: { type, value },
  } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState<AllAddressItem[]>([]);

  const fetchAddressManage = async () => {
    try {
      const res = await api.getAllAddress();
      if (res.kind === "ok") {
        setData(res.data);
      } else {
        GToast.current.handleErrorResponse(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchAddressManage();
  }, []);

  const onEdit = (item) => {
    navigate("/new-address", { state: { type: "edit", item } });
  };

  const onDelete = async (item) => {
    try {
      const res = await api.deleteAddress(item.id);
      if (res.kind === "ok") {
        showInfo("地址删除成功", "OK");
        fetchAddressManage();
      } else {
        GToast.current.handleErrorResponse(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onCheck = async (item) => {
    try {
      const res = await api.editAddressDefault(
        item.id,
        item?.is_default === 1 ? 0 : 1
      );
      if (res.kind === "ok") {
        showInfo("默认地址修改成功", "OK");
        fetchAddressManage();
      } else {
        GToast.current.handleErrorResponse(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSelect = async (item) => {
    if (type !== "add") {
      try {
        const res = await api.userExchangeGoods(value, item.id);
        if (res.kind === "ok") {
          window._hmt.push([
            "_trackEvent",
            "button",
            "click",
            `提货:${item.award_name}`,
          ]);
          showInfo("提货成功 请前往订单查看", "OK");
          navigate(-1);
        } else {
          GToast.current.handleErrorResponse(res);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  return (
    <div className={styles.container}>
      {/* <SimpleHeader title={type === "add" ? "地址管理" : "选择收货地址"} /> */}
      <div
        className={styles.content}
        style={{
          paddingBottom: fitSize(89),
        }}
      >
        {data.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                width: "100%",
                height: fitSize(100),
                flexDirection: "column",
                marginBottom: fitSize(5),
                backgroundColor: colors.palette.neutral100,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: fitSize(14),
                  marginLeft: fitSize(14),
                  marginRight: fitSize(14),
                }}
              >
                <Touchable
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: fitSize(304),
                  }}
                  onClick={() => {
                    onSelect(item);
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        fontSize: fitSize(12),
                        color: colors.palette.neutral30,
                        fontFamily: fontName.PingFangSC,
                        fontWeight: 400,
                        marginRight: fitSize(8),
                      }}
                    >
                      {item?.deliver_name}
                    </span>
                    <span
                      style={{
                        fontSize: fitSize(12),
                        color: "#aaaaaa",
                        fontWeight: 400,
                        fontFamily: fontName.PingFangSC,
                      }}
                    >
                      {item?.deliver_phone}
                    </span>
                  </div>
                  <span
                    style={{
                      fontSize: fitSize(11),
                      color: colors.palette.neutral10,
                      fontWeight: 400,
                      marginTop: fitSize(5),
                      fontFamily: fontName.PingFangSC,
                    }}
                  >
                    {item?.deliver_area}
                  </span>
                </Touchable>
                <div
                  style={{
                    width: fitSize(1),
                    height: fitSize(21),
                    backgroundColor: "#D8D8D8",
                    marginTop: fitSize(17),
                    marginLeft: fitSize(19),
                  }}
                />
                <Touchable
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: fitSize(14),
                  }}
                  onClick={() => {
                    onEdit(item);
                  }}
                >
                  <img
                    src={Images.mine.address_edit}
                    alt=""
                    style={{
                      width: fitSize(17),
                      height: fitSize(17),
                    }}
                  />
                </Touchable>
              </div>
              <div
                style={{
                  width: fitSize(347),
                  height: fitSize(1),
                  backgroundColor: "#F5F5F8",
                  marginTop: fitSize(10),
                  marginBottom: fitSize(12),
                  alignSelf: "center",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: fitSize(14),
                  marginRight: fitSize(14),
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Touchable
                    style={{
                      width: fitSize(12),
                      height: fitSize(12),
                      backgroundImage: `url(${
                        item?.is_default === 1
                          ? Images.mine.address_check_a
                          : Images.mine.address_check_u
                      })`,
                      backgroundSize: "100% 100%",
                    }}
                    onClick={() => {
                      onCheck(item);
                    }}
                  />
                  <span
                    style={{
                      fontSize: fitSize(11),
                      color: "#aaaaaa",
                      fontWeight: 400,
                      marginLeft: fitSize(2),
                    }}
                  >
                    {item?.is_default === 1 ? "当前默认地址" : "默认地址"}
                  </span>
                </div>
                <Touchable
                  style={{
                    fontSize: fitSize(11),
                    color: colors.palette.neutral30,
                    fontWeight: 400,
                    marginLeft: fitSize(2),
                    fontFamily: fontName.PingFangSC,
                  }}
                  onClick={() => {
                    onDelete(item);
                  }}
                >
                  删除
                </Touchable>
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          width: "100%",
          height: fitSize(90),
          display: "flex",
          justifyContent: "center",
          backgroundColor: colors.palette.neutral100,
          position: "fixed",
          bottom: 0,
        }}
      >
        <Touchable
          style={{
            display: "flex",
            width: fitSize(347),
            height: fitSize(46),
            justifyContent: "center",
            backgroundImage: `url(${Images.store.save_btn})`,
            backgroundSize: "100% 100%",
            marginTop: fitSize(10),
          }}
          onClick={() => {
            navigate("/new-address", { state: { type: "add" } });
          }}
        >
          <span
            style={{
              fontSize: fitSize(13),
              color: "#212F3B",
              fontWeight: 500,
              fontFamily: fontName.PingFangSC,
              marginTop: fitSize(11),
            }}
          >
            添加地址
          </span>
        </Touchable>
      </div>
    </div>
  );
});
