import React, { useState, useEffect, ReactElement } from "react";

import styles from "./index.module.css";
import { observer } from "mobx-react-lite";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { SimpleHeader } from "../../components";
import { Images, colors, fitSize, fontName, windowWidth } from "../../theme";
import { useStores } from "../../models";
import { Touchable } from "../../components/Button";
import { useNavigate } from "react-router-dom";

interface SettingScreenProps {}

export const SettingScreen: React.FC<SettingScreenProps> = observer(({}) => {
  useDocumentTitle("设置");
  const { accountStore } = useStores();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      {/* <SimpleHeader title="设置" /> */}
      <div className={styles.content}>
        <div
          style={{
            display: "flex",
            width: fitSize(347),
            height: fitSize(55),
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: colors.palette.neutral100,
            boxShadow: "inset 0px 0px 0px 0px #F5F5F8",
            borderRadius: "2px 2px 0px 0px",
            marginTop: fitSize(10),
          }}
        >
          <span
            style={{
              fontSize: fitSize(13),
              fontFamily: fontName.PingFangSC,
              fontWeight: 400,
              color: "#212F3B",
              marginLeft: fitSize(14),
            }}
          >
            头像
          </span>
          <div
            style={{
              width: fitSize(34),
              height: fitSize(34),
              borderRadius: "50%",
              backgroundImage: `url(${Images.mine.user_logo})`,
              backgroundSize: "100% 100%",
              backgroundColor: "#2D73C8",
              marginRight: fitSize(14),
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            width: fitSize(347),
            height: fitSize(55),
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: colors.palette.neutral100,
            boxShadow: "inset 0px 0px 0px 0px #F5F5F8",
            borderRadius: "0px 0px 2px 2px",
          }}
        >
          <span
            style={{
              fontSize: fitSize(13),
              fontFamily: fontName.PingFangSC,
              fontWeight: 400,
              color: "#212F3B",
              marginLeft: fitSize(14),
            }}
          >
            昵称
          </span>
          <span
            style={{
              fontSize: fitSize(13),
              fontFamily: fontName.PingFangSC,
              fontWeight: 400,
              color: "#666666",
              marginRight: fitSize(14),
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: fitSize(200),
            }}
          >
            {accountStore.user.userName}
          </span>
        </div>
        <Touchable
          style={{
            display: "flex",
            width: fitSize(347),
            height: fitSize(55),
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: colors.palette.neutral100,
            boxShadow: "inset 0px 0px 0px 0px #F5F5F8",
            borderRadius: "2px 2px 2px 2px",
            marginTop: fitSize(14),
          }}
          onClick={() => {
            navigate("/more-setting");
          }}
        >
          <span
            style={{
              fontSize: fitSize(13),
              fontFamily: fontName.PingFangSC,
              fontWeight: 400,
              color: "#212F3B",
              marginLeft: fitSize(14),
            }}
          >
            更多
          </span>
          <div
            style={{
              width: fitSize(14),
              height: fitSize(14),
              backgroundImage: `url(${Images.mine.mine_right})`,
              backgroundSize: "100% 100%",
              marginRight: fitSize(14),
            }}
          />
        </Touchable>
      </div>
    </div>
  );
});
