import { observer } from "mobx-react-lite";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import { Images, colors, fitSize, fontName, windowHeight } from "../../theme";
import dayjs from "dayjs";
import Tab from "../../components/Tab";
import { Touchable } from "../../components/Button";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { StickinessHeader } from "../../components/StickinessHeader";
import { CouponItem, api } from "../../services/api";
import { GToast } from "../../navigators";
import ReactLoading from "react-loading";
import InfiniteScroll from "react-infinite-scroll-component";
import { EmptyItem } from "../../components/EmptyItem";

interface CouponCenterProps {}

const TAB_CONTENT = [
  {
    title: "待使用",
  },
  {
    title: "已过期",
  },
  {
    title: "已使用",
  },
];

const LABEL = {
  0: "指定扭蛋机可用",
  1: "指定扭蛋机可用",
};

export const CouponCenter: FC<CouponCenterProps> = observer(() => {
  useDocumentTitle("优惠劵");
  const [type, setType] = useState<1 | 2 | 3>(1);
  const [data, setData] = useState<CouponItem[]>([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const PAGE_SIZE = 20;
  const isUsable = type === 1;

  const loadMoreData = () => {
    fetchGetCoupon();
  };

  const fetchGetCoupon = async () => {
    try {
      const res = await api.getCouponList(page, PAGE_SIZE, type);
      if (res.kind === "ok") {
        if (res.data.list.length > 0) {
          setData((val) => [...val, ...res.data.list]);
          setPage(page + 1);
          setHasNextPage(page * PAGE_SIZE < res.data.total);
        } else {
          setHasNextPage(false);
        }
      } else {
        GToast.current.handleErrorResponse(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onRefresh = async (currentType: 1 | 2 | 3) => {
    try {
      const res = await api.getCouponList(1, PAGE_SIZE, currentType);
      if (res.kind === "ok") {
        if (res.data.list.length > 0) {
          setData(res.data.list);
          setPage(page + 1);
          setHasNextPage(page * PAGE_SIZE < res.data.total);
        } else {
          setHasNextPage(false);
        }
      } else {
        GToast.current.handleErrorResponse(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    onRefresh(type);
  }, [type]);

  return (
    <div className={styles.container}>
      {/* <StickinessHeader
        title="优惠劵"
        isBack={true}
        id="scrollableDiv"
        isAbsolute={false}
        iconLeft={fitSize(63)}
        routeName="/mine"
      /> */}
      <Tab
        active={0}
        callback={(index) => {
          setType((index + 1) as 1 | 2 | 3);
          setPage(1);
          setHasNextPage(true);
          setData([]);
        }}
        contentLayout="space-around"
      >
        {TAB_CONTENT.map((tab, idx) => (
          <Tab.TabPane tab={tab.title} key={idx}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                width: "100%",
                height: windowHeight,
                overflowY: "scroll",
                touchAction: "none",
              }}
              id="scrollableDiv"
            >
              <EmptyItem
                isShow={data.length === 0}
                text={"还没有优惠劵"}
                type="not_coupon"
              />
              <InfiniteScroll
                dataLength={data.length}
                next={loadMoreData}
                hasMore={hasNextPage}
                // endMessage={
                //   <p style={{ textAlign: "center" }}>
                //     <b>已经到最底下啦.....</b>
                //   </p>
                // }
                loader={
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ReactLoading
                      type={"bubbles"}
                      color={"#62FFD0"}
                      height={50}
                      width={50}
                    />
                  </div>
                }
                scrollableTarget="scrollableDiv"
                style={{
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  backgroundColor: colors.palette.neutral100,
                  marginTop: fitSize(10),
                  paddingTop: fitSize(10),
                  display: data.length > 0 ? "flex" : "none",
                  paddingBottom: 100,
                }}
              >
                {data?.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: fitSize(347),
                        height: fitSize(90),
                        alignSelf: "center",
                        backgroundImage: `url(${
                          isUsable ? Images.mine.coupon_a : Images.mine.coupon_u
                        })`,
                        backgroundSize: "100% 100%",
                        marginBottom: fitSize(10),
                        position: "relative",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          width: fitSize(84),
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <span
                            style={{
                              fontSize: fitSize(14),
                              color: isUsable ? "#FF1F02" : "#AAAAAA",
                              fontWeight: 600,
                            }}
                          >
                            {item?.coupon_type === 1 ? "" : "¥"}
                          </span>
                          <span
                            style={{
                              fontSize: fitSize(24),
                              color: isUsable ? "#FF1F02" : "#AAAAAA",
                              fontWeight: 600,
                              fontFamily: fontName.PoppinsExtraBoldItalic,
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {item?.coupon_type === 1
                              ? `${item?.value}折`
                              : item?.value / 100}
                          </span>
                        </div>
                        <span
                          style={{
                            fontSize: fitSize(10),
                            color: colors.palette.neutral30,
                            fontWeight: 400,
                          }}
                        >
                          满{(item?.use_min_limit / 100).toFixed(2)}可用
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexGrow: 1,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            minWidth: fitSize(147),
                            height: fitSize(46),
                            marginLeft: fitSize(16),
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: fontName.PinFangSCSemibold,
                                fontSize: fitSize(13),
                                color: colors.palette.neutral30,
                                fontWeight: 500,
                                marginRight: fitSize(3),
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: fitSize(100),
                              }}
                            >
                              {item?.name}
                            </span>
                            <span
                              style={{
                                fontSize: fitSize(10),
                                color: "#6632FC",
                                fontWeight: 400,
                              }}
                            >
                              {LABEL[1]}
                            </span>
                          </div>
                          <span
                            style={{
                              fontSize: fitSize(10),
                              color: "#AAAAAA",
                              fontFamily: fontName.PingFangSC,
                              fontWeight: 400,
                            }}
                          >
                            {dayjs(item?.start_time * 1000).format(
                              "YYYY.MM.DD HH:mm"
                            )}
                            -
                            {dayjs(item?.end_time * 1000).format(
                              "YYYY.MM.DD HH:mm"
                            )}
                          </span>
                        </div>
                        <Touchable
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: fitSize(56),
                            height: fitSize(28),
                            borderRadius: fitSize(1),
                            position: "absolute",
                            right: fitSize(14),
                            border: `1px solid ${
                              isUsable ? "#212F3B" : "#AAAAAA"
                            }`,
                          }}
                          onClick={() => {
                            navigate(`/coupon-usable`, {
                              state: {
                                ids: item?.machines,
                              },
                            });
                            window._hmt.push([
                              "_trackEvent",
                              "button",
                              "click",
                              `去使用优惠卷:${item?.name}`,
                            ]);
                          }}
                          disabled={type !== 1}
                        >
                          <span
                            style={{
                              fontSize: fitSize(12),
                              color: isUsable ? "#212F3B" : "#AAAAAA",
                              fontWeight: 400,
                              fontFamily: fontName.PingFangSC,
                            }}
                          >
                            去使用
                          </span>
                        </Touchable>
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          </Tab.TabPane>
        ))}
      </Tab>
    </div>
  );
});
