import { FC, useState, useEffect, useRef } from 'react'
import { Images, colors, fitSize, fontName, windowWidth } from '../../../theme'
import './index.css'
import { useNavigate } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ZoneItem } from '../../../services/api'
import { RealTimePlayback } from './RealTimePlayback'
import { ActivityNewItem, FreeTimesItem, AwardItem, api } from '../../../services/api'
import { RewardItem } from '../../PayProcess/ShowOrder'
import { hideLoad, showInfo, showLoad } from '../../../utils/showUtil'
import { MultipleRewardsModal } from '../../../modal/MultipleRewardsModal'
import { GToast } from '../../../navigators'

interface LuckyDrawMachineProps {
  list: ZoneItem[]
}

export const LuckyDrawMachine: FC<LuckyDrawMachineProps> = ({ list }) => {
  const navigate = useNavigate()
  const [freeTimes, setFreeTimes] = useState<FreeTimesItem>()
  const [showModal, setShowModal] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [whetherUseCard, setWhetherUseCard] = useState<0 | 1 | 2>(0)
  const [isUseCard, setIsUseCard] = useState(false)
  const [cardList, setCardList] = useState<AwardItem[]>([])
  const [prevInfo, setPrevInfo] = useState<any>({})
  const [orderInfo, setOrderInfo] = useState<RewardItem>()
  const [startBuying, setStartBuying] = useState(false)
  const intervalId = useRef(null)
  const fetchNum = useRef(0)
  const [free, setFree] = useState('')

  const fetchGetFreeTimes = async (id: number) => {
    try {
      const res = await api.getFreeTimes(id)
      if (res.kind === 'ok') {
        setFreeTimes(res.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    fetchGetFreeTimes(list[0].id)
  }, [])

  useEffect(() => {
    if (startBuying) {
      intervalId.current = setInterval(() => {
        fetchNum.current++
        checkOrder()
      }, 1000)
    }
    return () => {
      clearInterval(intervalId.current)
    }
  }, [startBuying])

  const onMore = () => {
    console.log('onMore')
  }

  const goBuy = (id: number) => {
    console.log('id', id)
  }

  const mergedAwardImgs = list.reduce((acc, item) => {
    const awardImgs = item.award_imgs.includes(',') ? item.award_imgs.split(',').map(url => url.trim()) : [item.award_imgs]
    return [...acc, ...awardImgs]
  }, [])

  // 免费扭蛋有次数点击直接打开
  const onFree = () => {
    if (freeTimes?.remain_times && !list[0]?.price) {
      fetchCreateOrder(1)
      return
    }
    navigate(`/activity-new/${list[0]?.id}`)
  }

  // 创建订单
  const fetchCreateOrder = async (num: 1 | 3) => {
    const info = list[0]
    try {
      const res = await api.createOrder(info?.id, num, 0)
      if (res.kind === 'ok') {
        // 百度埋点
        window._hmt.push(['_trackEvent', 'page', 'view', '单抽'])
        if (info.price === 0) {
          showLoad('加载中...')
          setFree(res.data)
          setStartBuying(true)
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const setCardStatus = (status: 0 | 1 | 2, id: number) => {
    setCardList(prev =>
      prev.map(current => {
        return current.id === id ? { ...current, status } : current
      })
    )
  }

  const onReplaceItem = (id: number) => {
    if (cardList.length > 1) {
      setCardList(prev => prev.filter(current => current.id !== id))
      navigate(`/activity-new/${list[0]?.id}`)
    } else {
      setShowModal(false)
      fetchGetCoupon()
      setWhetherUseCard(0)
      setIsUseCard(false)
    }
  }

  const onShowCard = (id: number) => {
    setShowModal(false)
    GToast.current.showPrizeDraw('https://ndj.img.cbatime.com.cn/niudanji_static/video/video.mp4', true, () => {
      setCardList(prev => prev.map(current => (current.id === id ? { ...current, isShow: true } : current)))
      setShowModal(true)
    })
  }

  const fetchStartAgain = async (index: number, type: 0 | 1 | 2, popId: string) => {
    setPrevInfo(cardList[index])
    setWhetherUseCard(type)
    try {
      const res = await api.RedrawCard(cardList[index].exchange_no, popId)
      if (res.kind === 'ok') {
        GToast.current.showPrizeDraw('https://ndj.img.cbatime.com.cn/niudanji_static/video/video.mp4', true, () => {
          setCardList(prev =>
            prev.map((current, n) =>
              n === index
                ? {
                    ...res.data,
                    status: current.status,
                    isShow: true
                  }
                : current
            )
          )
          setShowModal(true)
        })
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const reserveCard = () => {
    setCardList(prev => prev.map(current => (current.id === prevInfo.id ? prevInfo : current)))
  }

  const fetchGetCoupon = async () => {
    setIsUseCard(false)
    try {
      const res = await api.claimCoupon(orderInfo.order_no)
      if (res.kind === 'ok') {
        if (res.data.value > 0 || res.data.business_type === 2) {
          GToast.current.showGetCoupon(
            true,
            res.data.business_type === 3 || res.data.business_type === 1 ? res.data.value / 100 : res.data.value,
            1,
            res.data.business_name,
            res.data.coupon_type === 1 ? res.data.start_time : 0,
            res.data.coupon_type === 1 ? res.data.end_time : 0,
            'Paid',
            res.data.coupon_type,
            () => {
              window._hmt.push(['_trackEvent', 'button', 'click', `领取天降好礼`])
              // 1.商品 2.道具 3.优惠券
              if (res.data.business_type === 3) {
                navigate('/coupon-center')
              } else if (res.data.business_type === 2) {
                navigate('/stage-property-card')
              } else {
                navigate('/')
              }
            },
            res.data.business_type === 2,
            res.data.thumb_img
          )
        } else {
          navigate(`/activity-new/${list[0]?.id}`)
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const checkOrder = async () => {
    if (fetchNum.current >= 14) {
      hideLoad()
      clearInterval(intervalId.current)
      setStartBuying(false)
      return
    }
    try {
      const res = await api.getUserOrderInfo(free)
      if (res.kind === 'ok') {
        if (res.data.award_list?.length > 0) {
          hideLoad()
          clearInterval(intervalId.current)
          setStartBuying(false)
          setCurrentSlide(0)
          setShowModal(true)
          setOrderInfo(res.data)
          setCardList(
            res.data.award_list.map(i => {
              return { ...i, isShow: false, status: 0 }
            })
          )
        } else {
          setStartBuying(false)
          showInfo('已售完')
        }
      } else {
        setStartBuying(false)
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <div
      style={{
        marginTop: fitSize(50)
      }}
    >
      <div
        style={{
          width: fitSize(348),
          height: fitSize(75),
          boxShadow: '0px 0px 10px 0px #4eb7ff',
          backgroundImage: `url(${Images.home.auto_list})`,
          backgroundSize: '100% 100%',
          margin: '0 auto',
          marginBottom: fitSize(12),
          borderRadius: fitSize(15),
          padding: fitSize(10),
          boxSizing: 'border-box'
        }}
      >
        <RealTimePlayback wsData={mergedAwardImgs} id={list[0].id} />
      </div>
      <div
        style={{
          width: fitSize(220),
          height: fitSize(242),
          margin: '0 auto',
          backgroundImage: `url(${Images.home.lucky_draw_bg})`,
          backgroundSize: '100% 100%'
        }}
        onClick={() => {
          onFree()
          window._hmt.push(['_trackEvent', 'button', 'click', '免费扭蛋'])
        }}
      ></div>
      <MultipleRewardsModal
        open={showModal}
        list={cardList}
        onClose={() => {
          setShowModal(false)
        }}
        setOpen={setShowModal}
        whetherUseCard={whetherUseCard}
        setWhetherUseCard={setWhetherUseCard}
        fetchGetCoupon={fetchGetCoupon}
        onReplaceItem={onReplaceItem}
        onShowCard={onShowCard}
        fetchStartAgain={fetchStartAgain}
        reserveCard={reserveCard}
        isUseCard={isUseCard}
        setIsUseCard={setIsUseCard}
        setCardStatus={setCardStatus}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
      />
    </div>
  )
}
