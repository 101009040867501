/**
 * This Api class lets you define an API endpoint and methods to request
 * data and process it.
 *
 * See the [Backend API Integration](https://github.com/infinitered/ignite/blob/master/docs/Backend-API-Integration.md)
 * documentation for more details.
 */
import { ApiResponse, ApisauceInstance, create } from 'apisauce'
import type {
  ApiConfig,
  GetNormalRequest,
  GetSystemTime,
  GetBannerList,
  GetLoginInfo,
  NormalReturn,
  NormalReturnData,
  GetCouponList,
  GetStagePropertyCardList,
  GetHomeZoneContent,
  GetActivityNew,
  GetActivityDetail,
  GetOrderBuy,
  GetAllAddress,
  AddAddress,
  EditAddress,
  EditAddressDefault,
  DeleteAddress,
  CreateOrder,
  CreateAliOrder,
  GetStoreList,
  GetUserPackage,
  GetUserOrderInfo,
  UserExchangeGoods,
  ClaimCoupon,
  GetVirtualList,
  RedrawCard,
  LogisticsDetails,
  Airdrop,
  GetAllLuckyDraw,
  GetLogisticsList,
  GetRealTimeData,
  GetLuckyDrawRecord,
  GetActivityModal,
  GetFreeTimes,
  GetIsBuyZone
} from './api.types'
import { GeneralApiProblem, getGeneralApiProblem } from './apiProblem'
import { arg } from '../../generateEnv'
import { env } from '../../env'
import { __DEV__ } from '../../models/account/account'

// @ts-ignore
const API_URL = env[arg].BASE_UR as string
export const PREFIX = '/v1/api'

/**
 * Configuring the apisauce instance.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL + PREFIX,
  pathPrefix: PREFIX,
  timeout: 5 * 60 * 1000
}

/**
 * Manages all requests to the API. You can use this class to build out
 * various requests that you need to call from your backend API.
 */
export class Api {
  apisauce: ApisauceInstance

  config: ApiConfig

  /**
   * Set up our API instance. Keep this lightweight!
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
    this.apisauce = create({
      // @ts-ignore
      baseURL: env[arg].BASE_URL,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json'
      }
    })
  }
  async setup() {}

  //通用请求
  async normalRequest<T = any>(path: string, params?: any, way: string = 'get'): Promise<GetNormalRequest<T>> {
    try {
      let response: ApiResponse<any>
      response = await this.apisauce[way](path, params ?? {})
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response?.data?.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'error', msg: e.message }
    }
  }

  /**
   * 获取系统时间
   */
  async getSystemTime(): Promise<GetSystemTime> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get(`/apg/s1/current_time`)
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response?.data?.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'error', msg: e?.message }
    }
  }

  async getBannerList(): Promise<GetBannerList> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/s1/banner/list')
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getLoginInfo(token: string): Promise<GetLoginInfo> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/s1/ddh/info', {
        token
      })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getProvinceCityArea(): Promise<NormalReturnData<any>> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/s1/province-city-area')
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getCouponList(page: number, page_size: number, coupon_status?: 1 | 2 | 3): Promise<GetCouponList> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/user/my/coupon/list', {
        page,
        page_size,
        coupon_status
      })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getStagePropertyCardList(page: number, page_size: number, propStatus?: number): Promise<GetStagePropertyCardList> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/user/my/prop/list', {
        page,
        page_size,
        propStatus
      })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getHomeZoneContent(): Promise<GetHomeZoneContent> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/s1/index')
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getActivityNew(id: string): Promise<GetActivityNew> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/s1/ndj/info', { id })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getActivityDetail(id: string): Promise<GetActivityDetail> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/s1/product/info', { id })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getOrderBuy(page: number, page_size: number, order_status?: 0 | 1 | 2 | 3): Promise<GetOrderBuy> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/user/my/order/list', { page, page_size, order_status })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getAllAddress(): Promise<GetAllAddress> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/user/my/address/list')
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async addAddress(
    deliver_name: string,
    deliver_phone: string,
    deliver_area: string,
    deliver_address: string,
    is_default: 0 | 1
  ): Promise<AddAddress> {
    try {
      const formData = new FormData()
      formData.append('deliver_name', deliver_name)
      formData.append('deliver_phone', deliver_phone)
      formData.append('deliver_area', deliver_area)
      formData.append('deliver_address', deliver_address)
      formData.append('is_default', String(is_default))
      const response: ApiResponse<any> = await this.apisauce.post('/apg/user/my/address/add', formData)
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async editAddress(
    id: string,
    deliver_name: string,
    deliver_phone: string,
    deliver_area: string,
    deliver_address: string,
    is_default: 0 | 1
  ): Promise<EditAddress> {
    try {
      const formData = new FormData()
      formData.append('id', id)
      formData.append('deliver_name', deliver_name)
      formData.append('deliver_phone', deliver_phone)
      formData.append('deliver_area', deliver_area)
      formData.append('deliver_address', deliver_address)
      formData.append('is_default', String(is_default))
      const response: ApiResponse<any> = await this.apisauce.post('/apg/user/my/address/edit', formData)
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async editAddressDefault(id: string, is_default: 0 | 1): Promise<EditAddressDefault> {
    try {
      const formData = new FormData()
      formData.append('id', id)
      formData.append('is_default', is_default.toString())
      const response: ApiResponse<any> = await this.apisauce.post('/apg/user/my/address/edit/default', formData)
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async deleteAddress(id: string): Promise<DeleteAddress> {
    try {
      const formData = new FormData()
      formData.append('id', id)
      const response: ApiResponse<any> = await this.apisauce.post('/apg/user/my/address/del', formData)
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async createOrder(niudanji_id: number, num: number, is_continuous: 0 | 1): Promise<CreateOrder> {
    try {
      const formData = new FormData()
      formData.append('niudanji_id', niudanji_id.toString())
      formData.append('num', num.toString())
      formData.append('is_continuous', is_continuous.toString())
      const response: ApiResponse<any> = await this.apisauce.post('/apg/user/order/create', formData)
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async createAliOrder(order_no: string): Promise<CreateAliOrder> {
    try {
      const formData = new FormData()
      formData.append('order_no', order_no)
      const response: ApiResponse<any> = await this.apisauce.post('/apg/user/trade/create', formData)
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getStoreList(page: number, page_size: number): Promise<GetStoreList> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/user/my/exchange/list', {
        page,
        page_size
      })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getUserPackage(): Promise<GetUserPackage> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/user/my/package/data', {})
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  // 用户订单详情
  async getUserOrderInfo(order_no: string): Promise<GetUserOrderInfo> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/user/order/info', { order_no })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async userExchangeGoods(exchange_no: string, address_id?: string): Promise<UserExchangeGoods> {
    try {
      const formData = new FormData()
      formData.append('exchange_no', exchange_no)
      formData.append('address_id', address_id)
      const response: ApiResponse<any> = await this.apisauce.post('/apg/user/exchange/gift', formData)
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async claimCoupon(order_no: string): Promise<ClaimCoupon> {
    try {
      const formData = new FormData()
      formData.append('order_no', order_no)
      const response: ApiResponse<any> = await this.apisauce.post('/apg/user/my/sky/gift', formData)
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getVirtualList(page: number, page_size: number): Promise<GetVirtualList> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/user/my/record/virtually/list', { page, page_size })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async RedrawCard(exchange_no: string, pop_id?: string): Promise<RedrawCard> {
    try {
      const formData = new FormData()
      formData.append('exchange_no', exchange_no)
      formData.append('pop_id', pop_id)
      const response: ApiResponse<any> = await this.apisauce.post('/apg/user/my/use/prop', formData)
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async LogisticsDetails(wl_no: string, mobile_last4?: string): Promise<LogisticsDetails> {
    try {
      const formData = new FormData()
      formData.append('wl_no', wl_no)
      if (mobile_last4) {
        formData.append('mobile_last4', mobile_last4)
      }
      const response: ApiResponse<any> = await this.apisauce.post('/apg/wl/get_info', formData)
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: JSON.parse(response.data.result).data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async airdrop(): Promise<Airdrop> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post('/apg/user/my/airdrop', {})
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getAllLuckyDraw(page: number, page_size: number, ids: string): Promise<GetAllLuckyDraw> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/s1/ndj/list', { page, page_size, ids })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getLogisticsList(page: number, page_size: number, delivery_status: 0 | 2 | 3 | 4): Promise<GetLogisticsList> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/user/my/delivery/list', { page, page_size, delivery_status })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getRealTimeData(niudanji_id: number): Promise<GetRealTimeData> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/s1/ndj/led', { niudanji_id })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getLuckyDrawRecord(page: number, page_size: number): Promise<GetLuckyDrawRecord> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/user/my/record/list', { page, page_size })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getActivityModal(): Promise<GetActivityModal> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/user/home/notify/list', {})
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getFreeTimes(id: number): Promise<GetFreeTimes> {
    try {
      const response: ApiResponse<any> = await this.apisauce.get('/apg/user/ndj/remain_times', { id })
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }

  async getIsBuyZone(): Promise<GetIsBuyZone> {
    try {
      const response: ApiResponse<any> = await this.apisauce.post('/apg/user/zone/is_buy_zone')
      const err = dealError(response)
      if (err) return err as GeneralApiProblem
      return { kind: 'ok', data: response.data.result }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: 'bad-data' }
    }
  }
}

// Singleton instance of the API for convenience
export const api = new Api()

export function dealError(response: ApiResponse<any>) {
  if (!response.ok) {
    const problem = getGeneralApiProblem(response)
    if (problem) return problem
  }
  if (response.data.code === 200) {
    return null
  }
  if (response.data.code !== 0) {
    if (response.data.code === 403) {
      return { kind: 'forbidden', msg: '无权限' }
    } else if (response.data.code === 401) {
      return { kind: 'unauthorized', msg: '未授权' }
    } else if (response.data.code === 401) {
      return { kind: 'not-sign', msg: '未签名' }
    } else if (response.data.code === 200006) {
      return { kind: 'captcha-traffic-limit', msg: '发送验证码1分钟限流' }
    } else {
      console.log(' response.data.status', response)
      return { kind: 'error', msg: response.data.status }
    }
  }
  return null
}
