import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './index.module.css'
import { Images, colors, fitSize, fontName } from '../../theme'
import { Touchable } from '../../components/Button'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { StickinessHeader } from '../../components/StickinessHeader'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { StagePropertyCardItem, api } from '../../services/api'
import { GToast } from '../../navigators'
import { EmptyItem } from '../../components/EmptyItem'

const TYPE_ICON = {
  1: Images.mine.card1,
  2: Images.mine.card2
}

const TYPE_LABEL = {
  1: '重抽卡',
  2: '高级重抽卡'
}

interface StagePropertyCardProps {}

export type TotalArray = {
  name: string
  data: StagePropertyCardItem[]
}

export const StagePropertyCard: FC<StagePropertyCardProps> = observer(() => {
  useDocumentTitle('道具卡')
  const [data, setData] = useState<TotalArray[]>([])
  const navigate = useNavigate()

  const fetchGetStagePropertyCard = async () => {
    let currentPage = 1
    let hasMorePages = true
    const PAGE_SIZE = 20
    let allData = []
    while (hasMorePages) {
      const res = await api.getStagePropertyCardList(currentPage, PAGE_SIZE)

      if (res.kind === 'ok') {
        if (res.data.list.length > 0) {
          allData = [...allData, ...res.data.list]
          if (currentPage * PAGE_SIZE < res.data.total) {
            currentPage++
          } else {
            hasMorePages = false
          }
        } else {
          hasMorePages = false
        }
      } else {
        GToast.current.handleErrorResponse(res)
        hasMorePages = false
      }
    }
    const newArray: TotalArray[] = Object.values(
      allData.reduce((acc, currentItem) => {
        if (!acc[currentItem.name]) {
          acc[currentItem.name] = { name: currentItem.name, data: [] }
        }
        acc[currentItem.name].data.push(currentItem)
        return acc
      }, {})
    )
    setData(newArray)
  }

  useEffect(() => {
    fetchGetStagePropertyCard()
  }, [])

  return (
    <div className={styles.container}>
      {/* <StickinessHeader
        title="道具卡"
        isBack={true}
        id="scrollableDiv"
        isAbsolute={false}
        iconLeft={fitSize(63)}
        routeName="/mine"
      /> */}
      <div className={styles.content} id="scrollableDiv">
        <EmptyItem isShow={data?.length === 0} text={'还没有道具卡'} type="not_coupon" />
        <div
          style={{
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            // backgroundColor: colors.palette.neutral100,
            // marginTop: fitSize(10),
            display: data?.length > 0 ? 'flex' : 'none',
            paddingBottom: 100
          }}
        >
          {data?.map((item, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: fitSize(347),
                  height: fitSize(90),
                  alignSelf: 'center',
                  marginTop: fitSize(10),
                  backgroundImage: `url(${
                    // item.is_used === 0
                    //   ? Images.mine.card_bg1
                    //   : Images.mine.card_bg2
                    Images.mine.card_bg1
                  })`,
                  backgroundSize: '100% 100%',
                  position: 'relative'
                }}
                key={index}
              >
                <div
                  style={{
                    width: fitSize(84),
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <img src={TYPE_ICON[1]} alt="" style={{ width: fitSize(46), height: fitSize(32) }} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      minWidth: fitSize(147),
                      height: fitSize(55),
                      marginLeft: fitSize(16),
                      justifyContent: 'space-between'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <span
                        style={{
                          fontFamily: fontName.PinFangSCSemibold,
                          fontSize: fitSize(13),
                          color: colors.palette.neutral30,
                          fontWeight: 500,
                          marginRight: fitSize(3)
                        }}
                      >{`${item?.name}*${item?.data?.length}`}</span>
                      <span
                        style={{
                          fontSize: fitSize(10),
                          color: '#6632FC',
                          fontWeight: 400,
                          fontFamily: fontName.PingFangSC
                        }}
                      >
                        适用于全部扭蛋机
                      </span>
                    </div>
                    <span
                      style={{
                        fontSize: fitSize(11),
                        color: '#AAAAAA',
                        fontWeight: 400,
                        fontFamily: fontName.PingFangSC
                      }}
                    >
                      有效期：领取后24小时内
                    </span>
                    <span
                      style={{
                        fontSize: fitSize(11),
                        color: '#AAAAAA',
                        fontWeight: 400,
                        fontFamily: fontName.PingFangSC
                      }}
                    >
                      扭蛋后使用可重置结果(每次限用1张)
                    </span>
                  </div>
                  <Touchable
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: fitSize(56),
                      height: fitSize(28),
                      borderRadius: fitSize(1),
                      position: 'absolute',
                      right: fitSize(14),
                      border: ' 1px solid #212F3B'
                    }}
                    onClick={() => {
                      navigate('/')
                    }}
                  >
                    <span
                      style={{
                        fontSize: fitSize(12),
                        color: '#212F3B',
                        fontWeight: 400,
                        fontFamily: fontName.PingFangSC
                      }}
                    >
                      去使用
                    </span>
                  </Touchable>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
})
