import { observer } from 'mobx-react-lite'
import { FC, useEffect, useReducer, useState, useRef } from 'react'
import styles from './index.module.css'
import { CarouselWithIndicator } from './components/Banner'
import { SectionGroup } from './components/SectionGroup'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { Images, colors, fitSize, fontName, windowWidth } from '../../theme'
import { GratuitousGoods } from './components/GratuitousGoods'
import { StickinessHeader } from '../../components/StickinessHeader'
import { GToast } from '../../navigators'
import { ActivityModalItem, BannerItem, ZoneItem, api } from '../../services/api'
import { useSecond } from '../../hooks/useSecond'
import { ActivityModal } from '../../modal/ActivityModal'
import { loadString } from '../../utils/storage'
import { useNavigate } from 'react-router-dom'
import { LuckyDrawMachine } from './components/LuckyDrawMachine'
import { useStores } from '../../models'

const initialData = {
  banner: [],
  newPerson: [],
  timeLimit: [],
  hotSale: [],
  freeZone: []
}

type DataState = {
  banner: BannerItem[]
  freeZone: any[]
  newPerson: any[]
  timeLimit: any[]
  hotSale: any[]
}

type DataActionType = 'BANNER' | 'NEW_PERSON' | 'TIME_LIMIT' | 'HOT_SALE' | 'FREE_ZONE'

type DataAction<T extends DataActionType> = {
  type: T
  value: any[]
}

const setData = (state: DataState, action: DataAction<DataActionType>) => {
  const { type, value } = action
  switch (type) {
    case 'BANNER':
      return { ...state, banner: value }
    case 'NEW_PERSON':
      return { ...state, newPerson: value }
    case 'TIME_LIMIT':
      return { ...state, timeLimit: value }
    case 'HOT_SALE':
      return { ...state, hotSale: value }
    case 'FREE_ZONE':
      return { ...state, freeZone: value }
    default:
      return state
  }
}

type DataStructure = {
  id: number // 主键id
  name: string // 菜单名称
  list: ZoneItem[]
}

interface HomeScreenProps {}

export const HomeScreen: FC<HomeScreenProps> = observer(() => {
  useDocumentTitle('首页')
  const [data, dispatch] = useReducer(setData, initialData)
  const { currentTime } = useSecond()
  const [rootList, setRootList] = useState<DataStructure[]>()
  const [openActivityModal, setOpenActivityModal] = useState(false)
  const [ActivityModalValue, setActivityModalValue] = useState<ActivityModalItem[]>([])
  const navigate = useNavigate()
  const [isBuyZone, setIsBuyZone] = useState(false)
  const { accountStore } = useStores()

  const fetchGetIsBuyZone = async () => {
    try {
      const res = await api.getIsBuyZone()
      if (res.kind === 'ok') {
        setIsBuyZone(res.data)
      } else {
        // GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  function findItemsByName(data: DataStructure[], nameToFind: string): ZoneItem[] | [] {
    for (const item of data) {
      if (item.name === nameToFind) {
        return item.list.filter(zoneItem => zoneItem.type !== 2)
      }
    }
    return []
  }

  function getAllTypeOneItems(data: DataStructure[]): ZoneItem[] {
    const result: ZoneItem[] = []
    for (const item of data) {
      result.push(...item.list.filter(zoneItem => zoneItem.type === 2))
    }
    return result
  }

  const fetchAirdrop = async () => {
    try {
      const res = await api.airdrop()
      if (res.kind === 'ok') {
        if (res.data.business_type !== 0) {
          GToast.current.showGetCoupon(
            true,
            res.data.business_type === 3 || res.data.business_type === 1 ? res.data.value / 100 : res.data.value,
            1,
            res.data.business_name,
            res.data.coupon_type === 1 ? res.data.start_time : 0,
            res.data.coupon_type === 1 ? res.data.end_time : 0,
            'Free',
            res.data.coupon_type,
            () => {
              window._hmt.push(['_trackEvent', 'button', 'click', `领取天降好礼`])
            },
            res.data.business_type === 2,
            res.data.thumb_img
          )
        }
      } else {
        // GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const fetchGetActivityModal = async () => {
    try {
      const res = await api.getActivityModal()
      if (res.kind === 'ok') {
        if (res.data.length > 0) {
          setActivityModalValue(res.data)
          if (!sessionStorage.getItem('showActivity')) {
            setOpenActivityModal(true)
            sessionStorage.setItem('showActivity', 'true')
          }
        }
      } else {
        // GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    localStorage.setItem('title', '')
    fetchBannerList()
    fetchGetHomeZoneContent()
  }, [])

  useEffect(() => {
    fetchAirdrop()
    fetchGetActivityModal()
    fetchGetIsBuyZone()
  }, [accountStore.token])

  const fetchBannerList = async () => {
    try {
      const res = await api.getBannerList()
      if (res.kind === 'ok') {
        dispatch({ type: 'BANNER', value: res.data })
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const fetchGetHomeZoneContent = async () => {
    try {
      const res = await api.getHomeZoneContent()
      if (res.kind === 'ok') {
        setRootList(res.data)
        dispatch({
          type: 'FREE_ZONE',
          value: getAllTypeOneItems(res.data) ?? []
        })
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    if (rootList) {
      const savedScrollPosition = localStorage.getItem('scrollPosition')
      if (savedScrollPosition !== null) {
        setScrollPosition(parseInt(savedScrollPosition, 10))
        requestAnimationFrame(() => {
          document.getElementById('barScroll').scrollTop = parseInt(savedScrollPosition, 10)
        })
      }
    }
  }, [rootList])

  const handleScroll = () => {
    setScrollPosition(divRef.current.scrollTop)
  }

  const divRef = useRef(null)

  useEffect(() => {
    if (divRef.current) {
      divRef.current.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    return () => {
      localStorage.setItem('scrollPosition', scrollPosition.toString())
    }
  }, [scrollPosition])

  return (
    <div className={styles.container}>
      <ActivityModal
        open={openActivityModal}
        onClose={() => {
          setOpenActivityModal(false)
        }}
        value={ActivityModalValue}
      />
      <div className={styles.content} id="barScroll" ref={divRef}>
        <div
          style={{
            width: '100%',
            height: data?.freeZone.length > 0 ? fitSize(467) : fitSize(270),
            marginBottom: data?.freeZone.length > 0 ? fitSize(420) : fitSize(80)
            // position: "relative",
          }}
        >
          <div
            style={{
              width: '100%',
              height: fitSize(164),
              alignItems: 'center',
              // backgroundColor: "#62FFD0",
              backgroundImage: `url(${Images.home.home_bar2})`,
              backgroundSize: '100% 100%'
            }}
          >
            {data.banner.length > 0 && <CarouselWithIndicator list={data.banner} />}
            {data.freeZone.length > 0 && <LuckyDrawMachine list={data.freeZone} />}
          </div>
        </div>
        {rootList?.map((item, index) => {
          return (
            <SectionGroup
              title={item.name}
              show={item.name !== '免费专区' && item?.list.length > 0}
              isBuyZone={isBuyZone}
              list={
                item.name === '福利专区'
                  ? findItemsByName(rootList, '福利专区')
                  : item.name === '限时专区'
                  ? findItemsByName(rootList, '限时专区')
                  : item.name === '热卖专区'
                  ? findItemsByName(rootList, '热卖专区')
                  : item?.list.filter(zoneItem => zoneItem.type !== 2)
              }
              cardType={item.name === '福利专区' ? 'NEW_PERSON' : item.name === '限时专区' ? 'TIME_LIMIT' : 'HOT_SALE'}
              key={item?.id}
              isCustom={item.name !== '福利专区' && item.name !== '限时专区' && item.name !== '热卖专区'}
            />
          )
        })}
      </div>
    </div>
  )
})
