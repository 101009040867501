import { useEffect, useState } from 'react'
import { useStores } from '../models'
import { api } from '../services/api'
import { __DEV__ } from '../models/account/account'
import _ from 'lodash'
import { arg } from '../generateEnv'
import { loadString, saveString, remove } from '../utils/storage'

export const ready = (callback: () => void) => {
  if ((window as any).window.AlipayJSBridge) {
    callback && callback()
  } else {
    document.addEventListener('AlipayJSBridge', callback, false)
  }
}

export function useInit() {
  const { accountStore } = useStores()
  // 登录状态
  const [isNetSettle, setIsNetSettle] = useState(false)
  const token = accountStore.token

  // 根据大都会返回的token获取用户信息
  const fetchLoginInfo = async (token: string) => {
    try {
      const res = await api.getLoginInfo(token)
      if (res.kind === 'ok' && !_.isEmpty(res.data)) {
        setIsNetSettle(true)
        if (accountStore.user.userId && res.data.user_id !== accountStore.user.userId) {
          uidChange()
        }
        accountStore.doMessageLogin(res.data.authorization)
        accountStore.setUserInfo({
          authorization: res.data.authorization,
          userId: res.data.user_id,
          ddhId: res.data.ddh_id,
          userName: res.data.user_name
        })
        api.apisauce.setHeader('Authorization', res.data.authorization)
      } else {
        clearLogin()
      }
    } catch (error) {
      setIsNetSettle(false)
    }
  }

  // useEffect(() => {
  //   if (isNetSettle) {
  //     if (token) {
  //       doTokenChangeInit()
  //     } else {
  //       accountStore.changeOk(true)
  //     }
  //   }
  // }, [token, isNetSettle])

  useEffect(() => {
    setTimeout(() => {
      doRoutineInit()
    }, 500)
  }, [])

  // 清空登录信息
  const clearLogin = async () => {
    remove('first')
    const n = void 0
    accountStore.setUserInfo({
      authorization: n,
      userId: n,
      ddhId: n,
      userName: n
    })
    accountStore.removeAuth()
  }

  // 重新授权
  const uidChange = async () => {
    clearLogin()
    doRoutineInit()
  }

  // 登录
  const doRoutineInit = async () => {
    accountStore.changeOk(true)
    setIsNetSettle(true)
    if (arg === 'local') {
      const MOCK_LOGIN = {
        authorization:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MjQyMTk4MzksImp0aSI6IjIwMjQwMTEwMTYwNDI2MTk1MDgxMTI3MDAwMDAxMTI1Iiwic3ViIjoidGVzdCJ9.qwKOmZxR7rRuh4vdYyTEG8QP5bbO8i5r0XTie0kx7J8',
        user_id: 14086, //用户id
        ddh_id: '20210828150840997071045097724828', //大都会用户id
        user_name: '白日放歌须纵酒' //大都会昵称
      }
      accountStore.doMessageLogin(MOCK_LOGIN.authorization)
      accountStore.setUserInfo({
        authorization: MOCK_LOGIN.authorization,
        userId: MOCK_LOGIN.user_id,
        ddhId: MOCK_LOGIN.ddh_id,
        userName: MOCK_LOGIN.user_name
      })
      api.apisauce.setHeader('Authorization', MOCK_LOGIN.authorization)
    } else {
      const authCodeList = (await loadString('first')) === 'true' ? ['metroUserId'] : ['metroUserId', 'mobile']
      ready(() => {
        ;(window as any).AlipayJSBridge.call(
          'metro_thirdPartyAuthToken',
          {
            // appid: 2024011014213318,
            thirdCode: 'niudanCode',
            // businessName: 'niudanCode',
            authCodeList
          },
          result => {
            const { code, data } = result
            if (code === 1000) {
              fetchLoginInfo(data.token)
              saveString('first', 'true')
            } else {
              clearLogin()
            }
          }
        )
      })
    }
  }

  const doTokenChangeInit = async () => {
    accountStore.changeOk(false)
    try {
      api.apisauce.setHeader('Authorization', token)
      if (accountStore.token) {
        accountStore.changeOk(true)
      }
    } catch (err) {
      console.log('登录失败', err)
      accountStore.clearUserInfo()
    }
  }

  return {
    isNetSettle
  }
}
