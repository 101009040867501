import { GetCouponModal } from '../modal/GetCouponModal'
import { PrizeDrawModal } from '../modal/PrizeDrawModal'
import { GeneralApiProblem } from '../services/api'
import { Images, colors, fitSize, fontName, maxWebWidth } from '../theme'
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import { promiseReturn } from '../type'
import { PlatteWhiteAlert } from '../modal/PlatteWhiteAlert'
import { LoadCircleModal } from '../modal/LoadCircleModal'

interface GlobalModalProps {}
type msg = {
  msg: string
  type: string
  needCover: boolean
  cprogress?: number
  tprogress?: number
  image?: object
}

type prizeDrawVideo = {
  src: string
  show: boolean
  onVideoEnd?: () => void
  onClose?: () => void
}

export type alertText = {
  closeText: string
  sureText: string
  info?: string
  isShowOne?: boolean
}

const DEFAULT_ALERT_TEXT = {
  closeText: '取消',
  sureText: '确认',
  info: ''
}

let AlertInfo = { msg: '', onClose: () => {}, onSure: () => {}, info: '' }

export interface GlobalModalHandle {
  showToast: (
    msg: string,
    type: 'CANCEL' | 'TOAST' | 'OK' | 'LOAD' | 'IMG',
    duration: number,
    needCover?: boolean,
    cprogress?: number,
    tprogress?: number,
    image?: Object
  ) => NodeJS.Timeout
  hideToast: (time?: NodeJS.Timeout) => void
  showLoading: (msg: string, time?: number) => void
  showPrizeDraw: (src: string, show: boolean, onVideoEnd?: () => void, onClose?: () => void) => void
  showGetCoupon: (
    show: boolean,
    number: number,
    total: number,
    title: string,
    startTime: number,
    endTime: number,
    type: 'Free' | 'Paid',
    couponType: number,
    onGetCoupon: () => void,
    isProp?: boolean,
    propIcon?: string
  ) => void
  handleErrorResponse: (res: GeneralApiProblem) => void
  showAlert: (title: string, onClose: () => void, onSure: () => void, info?: alertText) => Promise<unknown>
}

const durationDefault = 5 * 60 * 1000
const GlobalModalref: React.ForwardRefRenderFunction<GlobalModalHandle, GlobalModalProps> = ({}, ref) => {
  const time = useRef<NodeJS.Timeout>()
  const confirmRef = useRef<promiseReturn | null>(null)
  const [isShow, setIsShow] = useState(false)
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [alertInfo, setAlertInfo] = useState<alertText>(DEFAULT_ALERT_TEXT as alertText)

  const [info, setInfo] = useState<msg>({
    msg: '',
    type: '',
    needCover: true
  })
  const [prizeDraw, setPrizeDraw] = useState<prizeDrawVideo>({
    src: '',
    show: false,
    onVideoEnd: () => {},
    onClose: () => {}
  })
  const [couponInfo, setCouponInfo] = useState({
    show: false,
    number: 0,
    total: 0,
    title: '',
    startTime: 0,
    endTime: 0,
    type: 'Free',
    couponType: 0,
    onGetCoupon: () => {},
    isProp: false,
    propIcon: ''
  })

  useImperativeHandle(ref, () => ({
    showToast,
    hideToast,
    showLoading,
    showPrizeDraw,
    showGetCoupon,
    handleErrorResponse,
    showAlert
  }))

  const showToast = (msg: string, type: string, duration: number, needCover = true, cprogress = 1, tprogress = 1, image?: object) => {
    clearTimeout(time.current)
    setInfo({ msg, type, needCover, cprogress, tprogress, image })
    setIsShow(true)
    time.current = setTimeout(
      () => {
        hideToast()
      },
      duration ? duration : durationDefault
    )
    return time.current
  }

  const handleErrorResponse = (res: GeneralApiProblem) => {
    if (res.kind === 'unauthorized') {
      showToast(res.msg, 'TOAST', 2000)
    } else {
      console.log('res', res)
      showToast(res.msg, 'TOAST', 2000)
    }
  }

  const onBeforeSure = (func: () => void) => {
    if (confirmRef.current) confirmRef.current.resolve('success')
    func()
  }
  const onBeforeClose = (func: () => void) => {
    if (confirmRef.current) confirmRef.current.resolve({ message: '取消' })
    func()
  }

  const showAlert = (title: string, onClose: () => void, onSure: () => void, info: alertText = DEFAULT_ALERT_TEXT as alertText) => {
    if (confirmRef.current) confirmRef.current = null
    const confirmPromise = new Promise((resolve, reject) => {
      confirmRef.current = { resolve, reject }
    })
    AlertInfo = {
      msg: title,
      onClose: () => onBeforeClose(onClose),
      onSure: () => onBeforeSure(onSure),
      info: info.info
    }
    setAlertInfo(info)
    setIsShowAlert(true)
    return confirmPromise
    // return new Promise((resolve, reject) => {})
  }

  const showGetCoupon = (
    show: boolean,
    number: number,
    total: number,
    title: string,
    startTime: number,
    endTime: number,
    type: 'Free' | 'Paid',
    couponType: number,
    onGetCoupon: () => void,
    isProp?: boolean,
    propIcon?: string
  ) => {
    setCouponInfo({
      show,
      number,
      total,
      title,
      startTime,
      endTime,
      type,
      couponType,
      onGetCoupon,
      isProp,
      propIcon
    })
  }

  const showPrizeDraw = (src: string, show: boolean, onVideoEnd?: () => void, onClose?: () => void) => {
    setPrizeDraw({ src, show, onVideoEnd, onClose })
  }

  const showLoading = (msg: string, duration?: number) => {
    clearTimeout(time.current)
    setInfo({ msg, type: 'LOAD', needCover: true })
    setIsShow(true)
    time.current = setTimeout(
      () => {
        hideToast()
      },
      duration ? duration : durationDefault
    )

    return time.current
  }

  const hideToast = (t?: NodeJS.Timeout) => {
    setIsShow(false)
    clearTimeout(t ? t : time.current)
  }

  const getComponent = () => {
    switch (info.type) {
      case 'LOAD':
        return <LoadCircleModal msg={info.msg} />
      case 'TOAST':
        return <Toast msg={info.msg} />
      case 'OK':
        return <OKView msg={info.msg} />
      default:
        return null
    }
  }

  const modalUniversality = {
    display: 'flex',
    margin: 0,
    width: '100%',
    height: '100%',
    position: 'absolute' as const,
    zIndex: 10000,
    justifyContent: 'center',
    alignItems: 'center'
  }

  useEffect(() => {
    const handleBack = event => {
      if (isShow) {
        // 如果 isShow 为 true，则阻止返回操作
        event.preventDefault()
      }
    }

    // 添加返回事件监听
    window.addEventListener('popstate', handleBack)

    // 清理函数
    return () => {
      // 移除返回事件监听
      window.removeEventListener('popstate', handleBack)
    }
  }, [isShow])

  const AlertComponent = () => {
    return (
      <PlatteWhiteAlert
        closeModal={() => {
          setIsShowAlert(false)
        }}
        isShow={isShowAlert}
        msg={AlertInfo.msg}
        onClose={() => AlertInfo?.onClose()}
        onConfirm={() => AlertInfo?.onSure()}
        alertInfo={alertInfo}
      />
    )
  }

  return (
    <React.Fragment>
      <PrizeDrawModal
        src={prizeDraw.src}
        open={prizeDraw.show}
        onClose={() => {
          if (prizeDraw?.onClose) prizeDraw?.onClose()
          setPrizeDraw({ ...prizeDraw, show: false })
        }}
        onVideoEnd={() => {
          setPrizeDraw({ ...prizeDraw, show: false })
          if (prizeDraw?.onVideoEnd) prizeDraw?.onVideoEnd()
        }}
      />
      <GetCouponModal
        type={couponInfo.type as 'Free' | 'Paid'}
        open={couponInfo.show}
        onClose={() => {
          setCouponInfo({ ...couponInfo, show: false })
        }}
        info={couponInfo}
        onGetCoupon={() => {
          couponInfo.onGetCoupon()
          setCouponInfo({ ...couponInfo, show: false })
        }}
        isProp={couponInfo.isProp}
        propIcon={couponInfo.propIcon}
      />
      {AlertComponent()}
      {isShow && (
        <div
          style={{
            ...modalUniversality,
            pointerEvents: info.needCover ? 'auto' : 'none'
          }}
        >
          {getComponent()}
        </div>
      )}
    </React.Fragment>
  )
}

export const Toast = ({ msg }) => {
  const container = {
    display: 'flex',
    borderRadius: fitSize(5),
    backgroundColor: colors.palette.neutral600,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    minHeight: fitSize(50),
    minWidth: fitSize(100),
    maxWidth: fitSize(280)
  }

  const text: any = {
    color: colors.palette.neutral100,
    fontSize: fitSize(18),
    padding: '15px 20px',
    textAlign: 'center'

    // fontFamily: fontName.PingFangSC,
  }

  return (
    <div style={container}>
      <span style={text}>{msg || ''}</span>
    </div>
  )
}

const OKView = ({ msg }) => {
  const msgText = {
    color: colors.palette.neutral100,
    fontSize: fitSize(14),
    marginTop: fitSize(12),
    width: fitSize(159),
    fontFamily: fontName.PinFangSCSemibold
  }

  return (
    <div
      style={{
        width: fitSize(191),
        minHeight: fitSize(108),
        borderRadius: fitSize(6),
        backgroundColor: colors.palette.neutral600,
        alignSelf: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <img
        src={Images.common.ok}
        style={{
          width: fitSize(33),
          height: fitSize(33),
          marginTop: fitSize(20)
        }}
        alt=""
      />
      <span style={{ ...msgText, alignSelf: 'center', textAlign: 'center' }}>{msg || '请求成功'}</span>
    </div>
  )
}

export const GlobalModal = React.forwardRef(GlobalModalref)
