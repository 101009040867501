import { observer } from 'mobx-react-lite'
import { FC, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { SimpleHeader } from '../../components'
import { Images, colors, fitSize, fontName, windowHeight, windowWidth } from '../../theme'
import { ProvinceCityAreaSelect } from '../../components/ProvinceCityAreaSelect'
import { Touchable } from '../../components/Button'
import { api } from '../../services/api'
import { GToast } from '../../navigators'
import ReactLoading from 'react-loading'
import { showInfo } from '../../utils/showUtil'
import styles from './index.module.css'

interface ActivityWebViewProps {}

export const ActivityWebView: FC<ActivityWebViewProps> = observer(() => {
  // useDocumentTitle("活动");
  const { state } = useLocation()
  // const searchParams = new URLSearchParams(location.search);
  // const url = searchParams.get("url");
  const navigate = useNavigate()
  const [startBuying, setStartBuying] = useState(false)
  const fetchNum = useRef(0)
  const [success, setSuccess] = useState(false)
  const intervalId = useRef(null)

  const goToBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    setTimeout(() => {
      setStartBuying(state.startBuying)
    }, 3000)
  }, [])

  const checkOrder = async () => {
    if (fetchNum.current >= 20) {
      setSuccess(false)
      showInfo('支付取消')
      setStartBuying(false)
      navigate('/order-center', {
        state: {
          id: 1
        },
        replace: true
      })
      clearInterval(intervalId.current)
      return
    }
    try {
      const res = await api.getUserOrderInfo(state?.orderId)
      if (res.kind === 'ok') {
        if (res.data?.award_list.length > 0) {
          setSuccess(true)
          setStartBuying(false)
          navigate(`/activity-new/${state.activityId}`, {
            state: {
              submit: res.data
            },
            replace: true
          })
        }
      } else {
        // GToast.current.handleErrorResponse(res);
        setSuccess(false)
      }
    } catch (error) {
      setSuccess(false)
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (startBuying) {
      intervalId.current = setInterval(() => {
        fetchNum.current++
        checkOrder()
      }, 1000)
    }
    if (success) clearInterval(intervalId.current)
    return () => {
      clearInterval(intervalId.current)
      window._hmt.push(['_trackEvent', 'button', 'click', `取消购买`])
    }
  }, [startBuying])

  const searchString = 'https://ndj.img.cbatime.com.cn'
  const containsOnce = state.url.includes(searchString) && state.url.indexOf(searchString) === state.url.lastIndexOf(searchString)

  return (
    <div
      style={{
        height: windowHeight,
        width: windowWidth,
        // paddingTop: 44,
        backgroundColor: colors.palette.neutral100
      }}
    >
      <div
        style={{
          display: startBuying ? 'flex' : 'none',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          position: 'absolute',
          zIndex: 9999
        }}
      >
        <div
          style={{
            display: 'flex',
            alignSelf: 'center',
            borderRadius: fitSize(10),
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: fitSize(120),
            backgroundColor: colors.palette.neutral900,
            width: fitSize(150),
            height: fitSize(150)
          }}
        >
          <ReactLoading type={'bars'} color={'#62FFD0'} height={80} width={80} />
          <span
            style={{
              fontSize: fitSize(16),
              color: '#62FFD0',
              fontWeight: 500,
              marginBottom: fitSize(10),
              fontFamily: fontName.PingFangSC,
              marginTop: 20
            }}
          >
            支付中...
          </span>
        </div>
      </div>
      {/* <div style={{ position: "absolute", marginTop: fitSize(3) }}>
        <Touchable
          onClick={goToBack}
          style={{
            display: "flex",
            padding: fitSize(10),
            width: fitSize(60),
            flexShrink: 1,
          }}
        >
          <img
            src={Images.components["header_back"]}
            style={{
              width: fitSize(20),
              height: fitSize(20),
              objectFit: "contain",
            }}
            alt=""
          />
        </Touchable>
      </div> */}
      {!containsOnce ? (
        <iframe id="myFrame" title={state?.title || '活动'} src={state.url} width={'100%'} height={'100%'}></iframe>
      ) : (
        <img src={state.url} width={'100%'} height={'100%'} alt="" />
      )}
    </div>
  )
})
