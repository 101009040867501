import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import {
  colors,
  fitSize,
  fontName,
  Images,
  windowHeight,
  windowWidth,
} from "../theme";
import { SimpleHeader } from "../components";
import {
  Location,
  NavigationType,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { showInfo } from "../utils/showUtil";
import { Touchable } from "../components/Button";

const list = {
  0: Images.activityNew.text_00,
  1: Images.activityNew.text_01,
  2: Images.activityNew.text_02,
  3: Images.activityNew.text_03,
  4: Images.activityNew.text_04,
  5: Images.activityNew.text_05,
};

interface AgreementModalProps {
  open: boolean;
  onClose: () => void;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: windowWidth,
    height: windowHeight,
    backgroundColor: colors.palette.neutral100,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  overlay: { zIndex: 1000 },
};

export const AgreementModal: FC<AgreementModalProps> = ({
  open = false,
  onClose = () => {},
}) => {
  const location: Location = useLocation();
  const navType: NavigationType = useNavigationType();
  const [pageNumber, setPageNumber] = useState(0);

  const goToPreviousPage = () => {
    if (pageNumber === 0) {
      console.log("1", 1);
      return;
    }
    console.log("ssr1", 1);

    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  const goToNextPage = () => {
    if (pageNumber === 5) return;
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  useEffect(() => {
    if (navType === "POP" && location.key !== "default") {
      onClose();
    }
  }, [location]);

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById("root") as HTMLElement}
    >
      {/* <SimpleHeader title="用户协议" leftAction={onClose} /> */}
      <div
        onClick={onClose}
        style={{
          height: "100%",
          width: "115%",

        }}
      >
        <img src={list[pageNumber]} width={"100%"} height={"100%"} alt="" />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          position: "absolute",
          justifyContent: "space-between",
          width: windowWidth,
        }}
      >
        <Touchable
          onClick={goToPreviousPage}
          style={{
            zIndex: 2,
          }}
        >
          <img
            src={Images.mine.mine_right}
            alt=""
            style={{
              transform: "scaleX(-1)",
              width: 25,
              height: 25,
              marginLeft: 20,
              filter: "grayscale(100%)",
            }}
          />
        </Touchable>
        <Touchable
          onClick={goToNextPage}
          style={{
            zIndex: 2,
          }}
        >
          <img
            src={Images.mine.mine_right}
            alt=""
            style={{
              filter: "grayscale(100%)",
              width: 25,
              height: 25,
              marginRight: 20,
            }}
          />
        </Touchable>
      </div>
    </Modal>
  );
};
