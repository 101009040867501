import Modal from "react-modal";
import React, { useEffect } from "react";
import { alertText } from "../components";
import { fitSize, windowHeight, windowWidth } from "../theme/layout";
import { colors, fontName } from "../theme";
import { Touchable } from "../components/Button";
import {
  Location,
  NavigationType,
  useLocation,
  useNavigationType,
} from "react-router-dom";

interface PlatteWhiteAlertProps {
  msg: string;
  isShow: boolean;
  closeModal: () => void;
  onConfirm: () => void;
  onClose?: () => void;
  alertInfo: alertText;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: windowWidth,
    height: windowHeight,
    backgroundColor: colors.palette.neutral100,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  overlay: { zIndex: 1000 },
};

export const PlatteWhiteAlert: React.FC<PlatteWhiteAlertProps> = ({
  msg,
  closeModal,
  isShow,
  onConfirm,
  onClose,
  alertInfo: { closeText, sureText, info, isShowOne = true },
}) => {
  const location: Location = useLocation();
  const navType: NavigationType = useNavigationType();

  useEffect(() => {
    if (navType === "POP" && location.key !== "default") {
      onClose();
    }
  }, [location]);

  return (
    <Modal
      isOpen={isShow}
      onRequestClose={onClose}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById("root") as HTMLElement}
    >
      <div style={styles.container}>
        <span style={styles.msg}>{msg}</span>
        {!!info && (
          <div
            style={{
              color: "#333",
              fontSize: fitSize(16),
              marginTop: fitSize(13),
              marginBottom: fitSize(13),
              paddingLeft: fitSize(40),
              paddingRight: fitSize(40),
            }}
          >
            {info}
          </div>
        )}
        <div style={{ flex: 1 }} />
        <div
          style={{
            display: "flex",
            width: fitSize(254),
            flexDirection: "row",
            height: fitSize(48),
            borderTopColor: "#B3B3B3",
            borderTopWidth: 1,
          }}
        >
          {isShowOne ? (
            <Touchable
              onClick={() => {
                closeModal();
                if (onClose) onClose();
              }}
              style={styles.leftBtn}
            >
              <span style={styles.content}>{closeText}</span>
            </Touchable>
          ) : null}
          <Touchable
            onClick={() => {
              closeModal();
              onConfirm();
            }}
            style={styles.rightBtn}
          >
            <span style={styles.content}>{sureText}</span>
          </Touchable>
        </div>
      </div>
    </Modal>
  );
};

const styles = {
  container: {
    display: "flex",
    backgroundColor: "#333333",
    width: fitSize(254),
    minHeight: fitSize(132),
    alignSelf: "center",
    borderRadius: fitSize(10),
  },
  msg: {
    display: "flex",
    fontSize: fitSize(16),
    color: colors.palette.neutral100,
    alignSelf: "center",
    marginVertical: fitSize(18),
    // fontWeight: "bold",
    paddingHorizontal: fitSize(10),
    fontFamily: fontName.PingFangSC,
  },
  bottomWrap: {},
  leftBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: "#B3B3B3",
  },
  rightBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  content: {
    color: colors.palette.neutral100,
    fontSize: fitSize(13),
    fontWeight: "500",
    fontFamily: fontName.PinFangSCSemibold,
  },
};
