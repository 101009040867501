import { observer } from "mobx-react-lite";
import { FC, Fragment, memo, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { SimpleHeader } from "../../components/SimpleHeader";
import { Images, colors, fitSize, fontName, windowHeight } from "../../theme";
import Tab from "../../components/Tab";
import { Touchable } from "../../components/Button";
import { groupAndFlattenByFieldWithTotal } from "../LuckyDraw/LuckyDrawRecord";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { OrderBuyItem, api } from "../../services/api";
import { GToast } from "../../navigators";
import { EmptyItem } from "../../components/EmptyItem";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactLoading from "react-loading";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";

export const TYPE_MAP = {
  0: "已取消",
  1: "待付款",
  2: "待发货",
  3: "发货中",
  4: "已完成",
};

const TAB_CONTENT = [
  {
    title: "全部",
  },
  {
    title: "待付款",
  },
  {
    title: "已完成",
  },
  {
    title: "已取消",
  },
];

interface OrderBuyProps {}

interface ItemProps {
  item: OrderBuyItem;
  showIndex: number;
  currentIndex: number;
  onLoading: (type: 0 | 1 | 2 | 3) => void;
  type: 0 | 1 | 2 | 3;
}

const Item: FC<ItemProps> = memo(
  ({ item, showIndex, currentIndex, onLoading, type }) => {
    const [countdown, setCountdown] = useState("");
    const interval = useRef(null);
    const navigate = useNavigate();
    const [succeed, setSucceed] = useState(false);

    const onConfirm = (val) => {
      if (val?.order_status === 1) {
        navigate(`/activity-new/${item?.id}`, {
          state: {
            nonPay: true,
            nonPayNum: item?.nums,
            nonPayId: item?.order_no,
            infoId: item?.niudanji,
          },
        });
      } else if (val?.order_status === 3) {
        navigate(`/store`);
      } else if (val?.order_status === 4) {
        navigate("/logistics-list", {
          state: {
            id: 0,
          },
        });
      }
    };

    useEffect(() => {
      if (item?.order_status === 1) {
        const targetDate = dayjs(item?.create_time * 1000).add(5, "minute");
        interval.current = setInterval(() => {
          const now = dayjs();
          if (now.isBefore(targetDate)) {
            const diffInSeconds = targetDate.diff(now, "second");
            const remainingMinutes = Math.floor(diffInSeconds / 60);
            const remainingSeconds = diffInSeconds % 60;
            const newCountdown = `${String(remainingMinutes).padStart(
              2,
              "0"
            )}:${String(remainingSeconds).padStart(2, "0")}`;
            setCountdown((prevCountdown) => {
              return newCountdown;
            });
            setSucceed(true);
          } else {
            clearInterval(interval.current);
            onLoading(type);
            setSucceed(true);
          }
        }, 1000);
      } else {
        setSucceed(true);
      }
      return () => {
        clearInterval(interval.current);
      };
    }, [item]);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          // position: "relative",
        }}
      >
        {succeed ? (
          <Fragment>
            <Touchable
              style={{
                width: fitSize(347),
                height: fitSize(109),
                display: "flex",
                flexDirection: "row",
                borderTop: "1px solid #F5F5F8",
                alignSelf: "center",
              }}
              onClick={() => {
                navigate(`/order-center/order-details/${item.order_no}`, {
                  state: {
                    openLogistics: false,
                    index: currentIndex,
                  },
                });
              }}
            >
              <LazyLoadImage
                alt={""}
                height={fitSize(81)}
                src={item?.niudanji_img}
                width={fitSize(81)}
                effect="blur"
                wrapperProps={{
                  style: { transitionDelay: "0.3s" },
                }}
                style={{
                  objectFit: "scale-down",
                  border: "1px solid #F5F5F8",
                  marginTop: fitSize(14),
                  marginRight: fitSize(12),
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  marginTop: fitSize(14),
                  marginBottom: fitSize(14),
                  marginRight: fitSize(14),
                }}
              >
                <span
                  style={{
                    fontSize: fitSize(13),
                    fontWeight: 400,
                    color: colors.palette.neutral30,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: fitSize(220),
                  }}
                >
                  扭蛋
                </span>
                <span
                  style={{
                    fontSize: fitSize(11),
                    fontWeight: 400,
                    color: "#aaaaaa",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: fitSize(220),
                    marginTop: 1,
                    marginBottom: 1,
                  }}
                >
                  x{item?.nums}
                </span>
                <span
                  style={{
                    fontSize: fitSize(11),
                    fontWeight: 400,
                    color: "#aaaaaa",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: fitSize(220),
                    marginBottom: fitSize(7),
                  }}
                >
                  {dayjs(item.create_time * 1000).format("YYYY.MM.DD HH:mm:ss")}
                </span>
                <div
                  style={{
                    marginRight: fitSize(14),
                    marginTop: fitSize(6),
                    display:
                      item?.order_status === 2 ||
                      item?.order_status === 3 ||
                      item?.order_status === 4
                        ? "flex"
                        : "none",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: fitSize(9),
                      fontWeight: 500,
                      color: colors.palette.neutral30,
                      fontFamily: fontName.PinFangSCSemibold,
                    }}
                  >
                    实付款：
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: fitSize(9),
                        fontWeight: 500,
                        color: colors.palette.neutral30,
                        marginRight: fitSize(2),
                        fontFamily: fontName.PinFangSCSemibold,
                      }}
                    >
                      ¥
                    </span>
                    <span
                      style={{
                        fontSize: fitSize(18),
                        fontWeight: 500,
                        color: colors.palette.neutral30,
                        fontFamily: fontName.PinFangSCSemibold,
                      }}
                    >
                      {(item?.price / 100).toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </Touchable>
            <div
              style={{
                marginRight: fitSize(14),
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                display: item?.price === 0 ? "none" : "flex",
              }}
            >
              <Touchable
                style={{
                  minWidth: fitSize(77),
                  height: fitSize(32),
                  display:
                    item?.order_status === 1 ||
                    item?.order_status === 3 ||
                    item?.order_status === 4
                      ? "flex"
                      : "none",
                  justifyContent: "center",
                  backgroundImage: `url(${Images.home.home_hot_btn})`,
                  backgroundSize: "100% 100%",
                  marginBottom: 10,
                }}
                onClick={() => {
                  onConfirm(item);
                }}
              >
                <span
                  style={{
                    fontSize: fitSize(11),
                    fontWeight: 500,
                    color: "#767E85",
                    fontFamily: fontName.PinFangSCSemibold,
                    marginTop: fitSize(7),
                    marginLeft: fitSize(12),
                    marginRight: fitSize(15),
                  }}
                >
                  {item?.order_status === 1
                    ? `立即付款`
                    : item?.order_status === 3
                    ? "立即提货"
                    : item?.order_status === 4
                    ? "查看物流"
                    : ""}
                  {item?.order_status === 1 || countdown !== "00:00" ? (
                    <span
                      style={{
                        fontSize: fitSize(11),
                        fontWeight: 500,
                        color: "#767E85",
                        fontFamily: fontName.PingFangSC,
                        marginLeft: fitSize(2),
                      }}
                    >
                      {countdown}
                    </span>
                  ) : null}
                </span>
              </Touchable>
            </div>
          </Fragment>
        ) : (
          <CustomLoadingSkeleton />
        )}
      </div>
    );
  }
);

export const OrderBuy: FC<OrderBuyProps> = observer(() => {
  useDocumentTitle("我的订单");
  const {
    state: { id },
  } = useLocation();
  const [data, setData] = useState<OrderBuyItem[]>([]);
  const [type, setType] = useState(id);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const PAGE_SIZE = 20;

  const loadMoreData = () => {
    if (hasNextPage) {
      fetchGetLuckyDrawRecord();
    }
  };

  const fetchGetLuckyDrawRecord = async () => {
    try {
      const res = await api.getOrderBuy(page, PAGE_SIZE, type);
      if (res.kind === "ok") {
        if (res.data.list.length > 0) {
          setData((val) => [...val, ...res.data.list]);
          setPage(page + 1);
          setHasNextPage(page * PAGE_SIZE < res.data.total);
        } else {
          setHasNextPage(false);
        }
      } else {
        GToast.current.handleErrorResponse(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onRefresh = async (currentType: 0 | 1 | 2 | 3) => {
    try {
      const res = await api.getOrderBuy(1, PAGE_SIZE, currentType);
      if (res.kind === "ok") {
        if (res.data.list.length > 0) {
          setData(res.data.list);
          setPage(page + 1);
          setHasNextPage(page * PAGE_SIZE < res.data.total);
        } else {
          setHasNextPage(false);
        }
      } else {
        GToast.current.handleErrorResponse(res);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    onRefresh(type);
  }, [type]);

  return (
    <div className={styles.container}>
      {/* <SimpleHeader title="我的订单" /> */}
      <Tab
        active={id}
        callback={(index) => {
          setType(index);
          setPage(1);
          setHasNextPage(true);
          setData([]);
        }}
      >
        {TAB_CONTENT.map((tab, idx) => (
          <Tab.TabPane tab={tab.title} key={idx}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                width: "100%",
                height: windowHeight,
                overflowY: "scroll",
                touchAction: "none",
              }}
              id="scrollableDiv"
            >
              <EmptyItem
                isShow={data.length === 0}
                text={"空空如也"}
                type="not_all"
              />
              <InfiniteScroll
                dataLength={data.length}
                next={loadMoreData}
                hasMore={hasNextPage}
                // endMessage={
                //   <p style={{ textAlign: "center" }}>
                //     <b>已经到最底下啦.....</b>
                //   </p>
                // }
                loader={
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ReactLoading
                      type={"bubbles"}
                      color={"#62FFD0"}
                      height={50}
                      width={50}
                    />
                  </div>
                }
                scrollableTarget="scrollableDiv"
                style={{
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  // backgroundColor: colors.palette.neutral100,
                  marginTop: fitSize(10),
                  display: data.length > 0 ? "flex" : "none",
                  paddingBottom: 100,
                }}
              >
                {data.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        minHeight: fitSize(13),
                        padding: `${fitSize(12)} ${fitSize(14)} ${fitSize(14)}`,
                        backgroundColor: colors.palette.neutral100,
                        marginBottom: fitSize(10),
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            fontSize: fitSize(14),
                            fontWeight: 500,
                            color: colors.palette.neutral30,
                            fontFamily: fontName.PinFangSCSemibold,
                            marginLeft: fitSize(14),
                            marginTop: fitSize(12),
                            marginBottom: fitSize(12),
                          }}
                        >
                          {item?.niudanji_name}
                        </span>
                        <span
                          style={{
                            fontSize: fitSize(14),
                            fontWeight: 400,
                            color: colors.palette.neutral10,
                            marginRight: fitSize(14),
                            marginTop: fitSize(12),
                            marginBottom: fitSize(12),
                          }}
                        >
                          {TYPE_MAP[item?.order_status]}
                        </span>
                      </div>
                      <Item
                        item={item}
                        currentIndex={1}
                        showIndex={item?.nums}
                        onLoading={onRefresh}
                        type={type}
                      />
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          </Tab.TabPane>
        ))}
      </Tab>
    </div>
  );
});

const CustomLoadingSkeleton = () => {
  return (
    <Fragment>
      <Touchable
        style={{
          width: fitSize(347),
          height: fitSize(109),
          display: "flex",
          flexDirection: "row",
          borderTop: "1px solid #F5F5F8",
          alignSelf: "center",
        }}
      >
        <Skeleton
          width={fitSize(81)}
          height={fitSize(81)}
          style={{
            objectFit: "scale-down",
            border: "1px solid #F5F5F8",
            marginTop: fitSize(14),
            marginRight: fitSize(12),
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            marginTop: fitSize(14),
            marginBottom: fitSize(14),
            marginRight: fitSize(14),
          }}
        >
          <Skeleton
            width={fitSize(220)}
            height={fitSize(13)}
            style={{
              marginBottom: fitSize(1),
            }}
          />
          <Skeleton
            width={fitSize(220)}
            height={fitSize(11)}
            style={{
              marginTop: 1,
              marginBottom: 1,
            }}
          />
          <Skeleton
            width={fitSize(220)}
            height={fitSize(11)}
            style={{
              marginBottom: fitSize(7),
            }}
          />
          {/* <div
            style={{
              marginRight: fitSize(14),
              marginTop: fitSize(6),
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Skeleton
              width={fitSize(30)}
              height={fitSize(9)}
              style={{
                fontWeight: 500,
                fontFamily: fontName.PinFangSCSemibold,
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Skeleton
                width={fitSize(18)}
                height={fitSize(18)}
                style={{
                  marginRight: fitSize(2),
                }}
              />
              <Skeleton
                width={fitSize(40)}
                height={fitSize(18)}
                style={{
                  fontWeight: 500,
                  fontFamily: fontName.PinFangSCSemibold,
                }}
              />
            </div>
          </div> */}
        </div>
      </Touchable>
      <div
        style={{
          marginRight: fitSize(14),
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Touchable
          style={{
            minWidth: fitSize(77),
            height: fitSize(32),
            display: "flex",
            justifyContent: "center",
            // backgroundImage: `url(${Images.home.home_hot_btn})`,
            // backgroundSize: "100% 100%",
            marginBottom: 10,
          }}
        >
          <Skeleton
            width={fitSize(80)}
            height={fitSize(22)}
            style={{
              marginTop: fitSize(6),
              marginLeft: fitSize(12),
              marginRight: fitSize(15),
              fontFamily: fontName.PinFangSCSemibold,
            }}
          />
        </Touchable>
      </div>
    </Fragment>
  );
};
