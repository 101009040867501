import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import styles from './index.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { SimpleHeader } from '../../components/SimpleHeader'
import { Images, colors, fitSize, fontName, windowHeight } from '../../theme'
import dayjs from 'dayjs'
import Tab from '../../components/Tab'
import { Touchable } from '../../components/Button'
import { groupAndFlattenByFieldWithTotal } from '../LuckyDraw/LuckyDrawRecord'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { GToast } from '../../navigators'
import { StoreItem, api } from '../../services/api'
import { EmptyItem } from '../../components/EmptyItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'

const TYPE_MAP = {
  2: '待发货',
  3: '待收货',
  4: '已完成'
}

const TAB_CONTENT = [
  // {
  //   title: '全部'
  // },
  {
    title: '待发货'
  },
  {
    title: '待收货'
  },
  {
    title: '已完成'
  }
]

interface LogisticsListProps {}

export const LogisticsList: FC<LogisticsListProps> = observer(() => {
  useDocumentTitle('我的物流')
  const {
    state: { id }
  } = useLocation()
  const [data, setData] = useState<StoreItem[]>([])
  const ids = id === 2 ? 0 : id === 3 ? 1 : 0
  const [type, setType] = useState<number>(id)
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)
  const PAGE_SIZE = 20

  const loadMoreData = () => {
    if (hasNextPage) {
      fetchGetLogisticsList()
    }
  }

  const fetchGetLogisticsList = async () => {
    try {
      const res = await api.getLogisticsList(page, PAGE_SIZE, id)
      if (res.kind === 'ok') {
        if (res.data.list.length > 0) {
          setData(val => [...val, ...res.data.list])
          setPage(page + 1)
          setHasNextPage(page * PAGE_SIZE < res.data.total)
        } else {
          setHasNextPage(false)
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const onRefresh = async currentType => {
    try {
      const res = await api.getLogisticsList(1, PAGE_SIZE, currentType)
      if (res.kind === 'ok') {
        if (res.data.list.length > 0) {
          setData(res.data.list)
          setPage(page + 1)
          setHasNextPage(page * PAGE_SIZE < res.data.total)
        } else {
          setHasNextPage(false)
        }
      } else {
        GToast.current.handleErrorResponse(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    onRefresh(type)
  }, [type])

  return (
    <div className={styles.container}>
      {/* <SimpleHeader title="我的物流" /> */}
      <Tab
        active={ids}
        callback={index => {
          setType(index + 2)
          setPage(1)
          setHasNextPage(true)
          setData([])
        }}
      >
        {TAB_CONTENT.map((tab, idx) => (
          <Tab.TabPane tab={tab.title} key={idx}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                width: '100%',
                height: windowHeight,
                overflowY: 'scroll',
                touchAction: 'none'
              }}
              id="scrollableDiv"
            >
              <EmptyItem isShow={data.length === 0} text={'空空如也'} type="not_all" />
              <InfiniteScroll
                dataLength={data.length}
                next={loadMoreData}
                hasMore={hasNextPage}
                // endMessage={
                //   <p style={{ textAlign: "center" }}>
                //     <b>已经到最底下啦.....</b>
                //   </p>
                // }
                loader={
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <ReactLoading type={'bubbles'} color={'#62FFD0'} height={50} width={50} />
                  </div>
                }
                scrollableTarget="scrollableDiv"
                style={{
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                  display: data.length > 0 ? 'flex' : 'none',
                  paddingBottom: 100,
                  marginTop: fitSize(10)
                }}
              >
                {data.map((item, index) => {
                  return (
                    <Touchable
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        minHeight: fitSize(13),
                        padding: `${fitSize(12)} ${fitSize(14)} ${fitSize(14)}`,
                        backgroundColor: colors.palette.neutral100,
                        marginBottom: fitSize(10)
                      }}
                      key={index}
                      onClick={() => {
                        if (item?.order_status === 3 || item?.order_status === 4) {
                          navigate(`/logistics-list/order-details/${item.order_no}/logistics-details/${item?.track_no}`, {
                            state: {
                              order_no: item.order_no,
                              award_img: item.award_img
                            }
                          })
                        }
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%'
                        }}
                      >
                        <span
                          style={{
                            fontSize: fitSize(14),
                            fontWeight: 500,
                            color: colors.palette.neutral30,
                            fontFamily: fontName.PinFangSCSemibold,
                            marginLeft: fitSize(14),
                            marginTop: fitSize(12),
                            marginBottom: fitSize(12),
                            display: 'inline-block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: fitSize(350)
                          }}
                        >
                          {item?.niudanji_name}
                        </span>
                        {/* {idx === 0 && (
                          <span
                            style={{
                              fontSize: fitSize(13),
                              color: colors.palette.neutral30,
                              marginRight: fitSize(14),
                              marginTop: fitSize(12),
                              marginBottom: fitSize(12),
                              display: 'inline-block'
                            }}
                          >
                            {TYPE_MAP[item?.order_status]}
                          </span>
                        )} */}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          height: '100%'
                        }}
                      >
                        <div
                          style={{
                            width: fitSize(347),
                            height: fitSize(109),
                            display: 'flex',
                            flexDirection: 'row',
                            borderTop: '1px solid #F5F5F8',
                            alignSelf: 'center'
                          }}
                        >
                          <LazyLoadImage
                            alt={''}
                            height={fitSize(81)}
                            src={item?.award_img}
                            width={fitSize(81)}
                            effect="blur"
                            wrapperProps={{
                              style: { transitionDelay: '0.3s' }
                            }}
                            style={{
                              objectFit: 'scale-down',
                              border: '1px solid #F5F5F8',
                              marginTop: fitSize(14),
                              marginRight: fitSize(12)
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              flexGrow: 1,
                              marginTop: fitSize(14),
                              marginBottom: fitSize(14),
                              marginRight: fitSize(14),
                              justifyContent: 'center'
                            }}
                          >
                            <span
                              style={{
                                fontSize: fitSize(13),
                                fontWeight: 400,
                                color: colors.palette.neutral30,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: fitSize(220)
                              }}
                            >
                              {item?.award_name}
                            </span>
                            <span
                              style={{
                                fontSize: fitSize(11),
                                fontWeight: 400,
                                color: '#aaaaaa',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: fitSize(220),
                                marginTop: 1,
                                marginBottom: 1
                              }}
                            >
                              x{item?.award_num}
                            </span>
                            <span
                              style={{
                                fontSize: fitSize(11),
                                fontWeight: 400,
                                color: '#aaaaaa',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: fitSize(220),
                                marginBottom: fitSize(7)
                              }}
                            >
                              {dayjs(item.create_time * 1000).format('YYYY.MM.DD HH:mm:ss')}
                            </span>
                            <div
                              style={{
                                display: item?.order_status === 3 || item?.order_status === 4 ? 'flex' : 'none',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <span
                                style={{
                                  fontSize: fitSize(11),
                                  color: '#66E1C7',
                                  fontWeight: 500,
                                  fontFamily: fontName.PinFangSCSemibold
                                }}
                              >
                                物流单号：{item?.track_no}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Touchable>
                  )
                })}
              </InfiniteScroll>
            </div>
          </Tab.TabPane>
        ))}
      </Tab>
    </div>
  )
})
