import { useEffect } from "react";

/**
 * useDocumentTitle
 * @param title
 * @param keepOnUnmount
 * @description 设置页面标题
 * @example
 * useDocumentTitle('首页')
 * useDocumentTitle('首页', false)
 */
export function useDocumentTitle(title: string, keepOnUnmount: boolean = true) {
  const oldTitle = document.title;

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    return () => {
      if (!keepOnUnmount) {
        document.title = oldTitle;
      }
    };
  }, [oldTitle, keepOnUnmount]);
}
