import { observer } from 'mobx-react-lite'
import { Fragment, createRef, useEffect } from 'react'
import * as Screens from '../screens'
import { useStores } from '../models'
import { ready, useInit } from '../hooks/useInit'
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { BottomTabNavigator } from './BottomTabNavigator'
import { NotFound } from './NotFound'
import styles from './index.module.css'
import { GlobalModal } from '../components'

export const AppStack = observer(function AppStack() {
  const { isNetSettle } = useInit()
  const { accountStore } = useStores()
  const navigate = useNavigate()
  const location = useLocation()

  // useEffect(() => {
  //   if (location.pathname.indexOf('/activity-new') === -1) {
  //     if (!accountStore.isOK) navigate('/')
  //   }
  // }, [accountStore.isOK, navigate, location])

  useEffect(() => {
    if (location.pathname === '/info') {
      window._hmt.push(['_trackEvent', 'page', 'view', '站内信进入网站'])
      navigate('/')
    }
  }, [])

  return (
    <Fragment>
      {accountStore.isOK && isNetSettle ? (
        <Fragment>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Screens.HomeScreen />} />
            <Route path="/store" element={<Screens.StoreScreen />} />
            <Route path="/mine" element={<Screens.MineScreen />} />
            <Route path="/activity-new/:id" element={<Screens.ActivityNew />} />
            <Route path="/lucky-draw-details/:id" element={<Screens.LuckyDrawDetails />} />
            <Route path="/activity-new/:id/detail/:detailId" element={<Screens.ActivityDetail />} />
            <Route path="/web-view" element={<Screens.ActivityWebView />} />
            <Route path="/activity-poster/:id" element={<Screens.ActivityPoster />} />
            <Route path="/lucky-draw-record" element={<Screens.LuckyDrawRecord />} />
            <Route path="/address-mange" element={<Screens.AddressManage />} />
            <Route path="/new-address" element={<Screens.NewAddress />} />
            <Route path="/stage-property-card" element={<Screens.StagePropertyCard />} />
            <Route path="/coupon-usable" element={<Screens.CouponUsable />} />
            <Route path="/virtual-prize" element={<Screens.VirtualPrize />} />
            <Route path="/setting" element={<Screens.SettingScreen />} />
            <Route path="/more-setting" element={<Screens.MoreSetting />} />
            <Route path="/my-frame" element={<Screens.myFrame />} />
            <Route path="/relation-service" element={<Screens.RelationService />} />
            <Route path="/coupon-center" element={<Screens.CouponCenter />} />
            <Route path="/order-center" element={<Screens.OrderBuy />} />
            <Route path="/order-center/order-details/:detailId" element={<Screens.OrderDetails />} />
            <Route path="/logistics-list" element={<Screens.LogisticsList />} />
            <Route path="/logistics-list/order-details/:detailId/logistics-details/:logisticsId" element={<Screens.LogisticsDetails />} />
          </Routes>
          <BottomTabNavigator />
        </Fragment>
      ) : (
        <></>
      )}
    </Fragment>
  )
})

export const GToast = createRef<React.ElementRef<typeof GlobalModal>>()

export interface NavigationProps {}

export const AppNavigator = observer(function AppNavigator(props: NavigationProps) {
  return (
    <BrowserRouter>
      <GlobalModal ref={GToast} />
      <AppStack />
    </BrowserRouter>
  )
})
