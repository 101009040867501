// TODO: write documentation for colors and palette in own markdown file and add links from here

const palette = {
  neutral10: "#777777",
  neutral20: "#999999",
  neutral30: "#333333",
  neutral40: "#CCCCCC",
  neutral50: "#666666",
  neutral60: "#18181C",
  neutral70: "#E8E8E8",
  neutral80: "#242227",
  neutral90: "#18181D",
  neutral100: "#FFFFFF",
  neutral200: "#F4F2F1",
  neutral300: "#D7CEC9",
  neutral400: "#B6ACA6",
  neutral500: "#978F8A",
  neutral600: "#424242",
  neutral700: "#3C3836",
  neutral800: "#191015",
  neutral900: "#000000",

  primary100: "#E2FEF3",
  primary200: "#C7FDED",
  primary300: "#A9FAE9",
  primary400: "#93F3E8",
  primary500: "#6FEBE8",
  primary600: "#51C1CA",
  primary700: "#3598A9",
  primary800: "#237087",
  primary900: "#155372",

  secondary50: "#FB6547",
  secondary100: "#FEEBDA",
  secondary200: "#FDD1B5",
  secondary300: "#FDB390",
  secondary400: "#FC9674",
  secondary500: "#FB6546",
  secondary600: "#D74332",
  secondary700: "#B32523",
  secondary800: "#91151E",
  secondary900: "#780C1D",

  accent100: "#FFEED4",
  accent200: "#FFE1B2",
  accent300: "#FDD495",
  accent400: "#FBC878",
  accent500: "#FFBB50",

  angry100: "#F2D6CD",
  angry500: "#C03403",

  overlay20: "rgba(25, 16, 21, 0.2)",
  overlay50: "rgba(25, 16, 21, 0.5)",

  black: "#373737",
  white: "#ffffff",
  offWhite: "#F5F5F5",
  orange: "#FBA928",
  orangeDarker: "#EB9918",
  lightGrey: "#939AA4",
  lighterGrey: "#F5F5F5",
  angry: "#dd3333",
  allBack: "#000000",
} as const

export const colors = {
  /**
   * The palette is available to use, but prefer using the name.
   * This is only included for rare, one-off cases. Try to use
   * semantic names as much as possible.
   */
  palette,
  /**
   * A helper for making something see-thru.
   */
  transparent: "rgba(0, 0, 0, 0)",
  /**
   * The default text color in many components.
   */
  text: palette.neutral200,
  /**
   * Secondary text information.
   */
  textDim: palette.neutral600,
  /**
   * The default color of the screen background.
   */
  background: palette.neutral900,
  /**
   * The default border color.
   */
  border: palette.neutral400,
  /**
   * The main tinting color.
   */
  tint: palette.primary500,
  /**
   * A subtle color used for lines.
   */
  separator: palette.neutral300,
  /**
   * Error messages.
   */
  error: palette.angry500,
  /**
   * Error Background.
   *
   */
  errorBackground: palette.angry100,
  /**
   * A subtle color used for borders and lines.
   */
  line: palette.offWhite,
}
