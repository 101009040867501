const DEFAULT_DENSITY = 1;
export const maxWebWidth = 600;
export const windowWidth = window.screen.width;
export const windowHeight = window.screen.height;
export const deviceWidth =
  windowWidth > maxWebWidth ? maxWebWidth : windowWidth;
export function fitSize(size: number | undefined) {
  if (size === undefined) return 0;
  return (size / DEFAULT_DENSITY) * (deviceWidth / 375);
}
