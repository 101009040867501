import React, { useState, useEffect, ReactElement, Dispatch, useContext, useRef } from 'react'
import { Images, fitSize, fontName } from '../../../theme'
import { CarouselContext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { RealTimeItem } from '../../../services/api'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import './index.css'

interface RealTimePlaybackProps {
  wsData: string[]
  id: number
}

export const RealTimePlayback: React.FC<RealTimePlaybackProps> = ({ wsData, id }): ReactElement => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const navigate = useNavigate()

  return (
    <div
      onClick={() => {
        navigate(`/activity-new/${id}`)
      }}
      style={{
        height: fitSize(55)
      }}
    >
      <CarouselProvider
        infinite={true}
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={wsData.length}
        isPlaying={wsData.length > 6}
        interval={3000}
        visibleSlides={6}
        touchEnabled={false}
        dragEnabled={false}
      >
        <Content list={wsData} setCurrentIndex={setCurrentIndex} currentIndex={currentIndex} />
      </CarouselProvider>
    </div>
  )
}

const Content = ({
  list,
  setCurrentIndex,
  currentIndex
}: {
  list: string[]
  setCurrentIndex: Dispatch<React.SetStateAction<number>>
  currentIndex: number
}) => {
  const carouselContext = useContext(CarouselContext)
  const [data, setData] = useState<string[]>([])

  useEffect(() => {
    setData(list)
  }, [list])

  useEffect(() => {
    function onChange() {
      setCurrentIndex(carouselContext?.state?.currentSlide)
    }
    carouselContext?.subscribe(onChange)
    return () => carouselContext?.unsubscribe(onChange)
  }, [carouselContext])

  return (
    <Slider>
      {data?.map((item, index) => {
        return (
          <Slide
            index={index}
            key={index}
            style={{
              width: fitSize(54),
              height: fitSize(54),
              BrowserRouter: 5
            }}
          >
            <CarouselSlide item={item} />
          </Slide>
        )
      })}
    </Slider>
  )
}

const CarouselSlide = ({ item }: { item: string }) => {
  return (
    <div
      style={{
        width: fitSize(54),
        height: fitSize(54),
        backgroundImage: `url(${item})`,
        backgroundSize: `100% 100%`
      }}
    ></div>
  )
}
