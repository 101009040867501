import { Instance, SnapshotOut, flow, types } from "mobx-state-tree";
import { api } from "../../services/api";
import { userInfoModel, userInfoStoreSnapshot } from "./userInfo";
import { arg } from "../../generateEnv";
import { withEnvironment } from "../extensions/with-environment";

export const __DEV__ = arg === "test";

export const AccountModel = types
  .model("AccountStore")
  .props({
    token: types.optional(types.string, ""),
    user: types.optional(userInfoModel, {}),
    isOK: false,
    currentAccount: "",
  })
  .extend(withEnvironment)
  .views((store) => ({
    get isAuthenticated() {
      return !!store.token;
    },
  }))
  .actions((self) => {
    function setAuthToken(data: string) {
      api.apisauce.setHeader("Authorization", data);
      self.token = data;
    }
    function removeAuth() {
      if (self.token !== undefined) {
        api.apisauce.deleteHeader("Authorization");
        self.token = "";
      }
    }
    return {
      doMessageLogin: function (token: string, maasToken?: string) {
        console.log("settoken", token);
        console.log("setmaastoken", maasToken);
        setAuthToken(token);
      },
      removeAuth,
      clearUserInfo: () => {
        self.user = undefined as any;
        self.token = undefined as any;
      },
      setUserInfo: (data: userInfoStoreSnapshot) => {
        console.log("data", data);
        self.user = data;
      },
      changeOk: (isOk: boolean = true) => {
        self.isOK = isOk;
      },
      setCurrentAccount(value: string) {
        self.currentAccount = value;
      },
    };
  });

type AccountStoreType = Instance<typeof AccountModel>;
export interface AccountStore extends AccountStoreType {}
type AccountStoreSnapshotType = SnapshotOut<typeof AccountModel>;
export interface AccountStoreSnapshot extends AccountStoreSnapshotType {}
export const createAccountStoreDefaultModel = () =>
  types.optional(AccountModel, {});
