import { ButtonHTMLAttributes, FC } from "react";
import { ButtonWithPreventDoubleClick } from "./withPreventDoubleClick";

interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const Touchable: FC<CustomButtonProps> = (props) => {
  return (
    <ButtonWithPreventDoubleClick {...props}>
      {props.children}
    </ButtonWithPreventDoubleClick>
  );
};
