import React, { useState, useEffect, ReactElement } from "react";

import { ResizablePIP } from "./ResizingPIP";
import { WebVideoCompatibility } from "../components";

interface PIPModalProps {
  onClose: () => void;
  video: string;
}

export const PIPModal: React.FC<PIPModalProps> = ({
  onClose,
  video,
}): ReactElement => {
  const [isPaused, setIsPaused] = useState(false);

  return (
    <ResizablePIP
      width={150}
      height={250}
      minConstraints={[150, 250]}
      maxConstraints={[150, 250]}
      setIsPaused={setIsPaused}
      isPaused={isPaused}
      onClose={onClose}
    >
      <WebVideoCompatibility
        uri={video}
        isPaused={isPaused}
        muted={true}
        loop={true}
        w={"100%"}
        h={"100%"}
        isReset={false}
      />
    </ResizablePIP>
  );
};
