import { useRef, useEffect, useState } from 'react'
import { Images, colors, fitSize } from '../../../theme'
import { RealTimePlayback } from './RealTimePlayback'
import { LuckyWheel } from '@lucky-canvas/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styles from './index.module.css'

export type ListItem = {
  url: string
  isPaused: boolean
  isVideo: boolean
}

export enum TypeEnum {
  progressBar = 'progressBar',
  countBox = 'countBox'
}

export const MyLuckyWheel = ({
  list,
  type,
  showBackgroundImage = true,
  height = fitSize(200),
  setCurrentVideo,
  isWs = false,
  wsData = []
}) => {
  const [title, setTitle] = useState(Images.activityNew.activity_new_bg1)

  useEffect(() => {
    // title 扭蛋机专区
    const t = localStorage.getItem('title')
    if (t === '热卖专区') {
      setTitle(Images.activityNew.activity_new_bg)
    } else if (t === '福利专区') {
      setTitle(Images.activityNew.activity_new_bg2)
    } else {
      setTitle(Images.activityNew.activity_new_bg1)
    }
  }, [title])

  return (
    <div
      style={{
        width: '100%',
        height: height,
        flexShrink: 0,
        backgroundImage: showBackgroundImage ? `url(${title})` : '',
        backgroundSize: '100% 100%',
        backgroundColor: colors.palette.neutral100,
        position: 'relative',
        paddingTop: fitSize(50),
        paddingBottom: fitSize(27)
      }}
    >
      {isWs ? <RealTimePlayback wsData={wsData} /> : null}
    </div>
  )
}
