import React, { FC, useEffect } from "react";
import Modal from "react-modal";
import {
  Images,
  colors,
  fitSize,
  fontName,
  windowHeight,
  windowWidth,
} from "../theme";
import {
  Location,
  NavigationType,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { __DEV__ } from "../models/account/account";
import { Touchable } from "../components/Button";

interface TakeDeliveryModalProps {
  id: number;
  src: string;
  name: string;
  open: boolean;
  onClose: () => void;
  onTakeDelivery: (id: number) => void;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: windowWidth,
    height: windowHeight,
    overflow: "hidden",
    zIndex: 1000,
    backgroundColor: colors.palette.neutral30 + "22",
  },
  overlay: { zIndex: 1000 },
};

export const TakeDeliveryModal: FC<TakeDeliveryModalProps> = ({
  id,
  src,
  name,
  open,
  onClose,
  onTakeDelivery,
}) => {
  const location: Location = useLocation();
  const navType: NavigationType = useNavigationType();

  useEffect(() => {
    if (navType === "POP" && location.key !== "default") {
      onClose();
    }
  }, [location]);

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById("root") as HTMLElement}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            width: fitSize(274),
            height: fitSize(375),
            display: "flex",
            flexDirection: "column",
            backgroundImage: `url(${Images.store.store_modal_bg2})`,
            backgroundSize: "100% 100%",
          }}
        >
          <img
            src={Images.store.store_modal_bg1}
            alt=""
            style={{
              width: fitSize(262),
              height: fitSize(363),
              position: "absolute",
              zIndex: 1,
            }}
          />
          <img
            src={src}
            alt=""
            style={{
              width: fitSize(260),
              height: fitSize(260),
              clipPath: "polygon(0 40px, 40px 0, 100% 0, 100% 100%, 0 100%)",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <span
              style={{
                width: "100%",
                marginTop: fitSize(14),
                marginBottom: fitSize(14),
                marginLeft: fitSize(12),
                fontSize: fitSize(16),
                fontWeight: 400,
                color: colors.palette.neutral30,
                fontFamily: fontName.PingFangSC,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: fitSize(130),
              }}
            >
              {name}
            </span>
            <div
              style={{
                display: "flex",
                width: fitSize(236),
                height: fitSize(37),
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "center",
                borderRadius: 1,
                backgroundImage: `url(${Images.store.store_extract})`,
                backgroundSize: "100% 100%",
                zIndex: 2,
              }}
            >
              <Touchable
                style={{
                  display: "flex",
                  width: fitSize(130),
                  height: fitSize(37),
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  onTakeDelivery(id);
                }}
              >
                <span
                  style={{
                    fontSize: fitSize(13),
                    fontWeight: 500,
                    color: colors.palette.neutral30,
                    fontFamily: fontName.PingFangSC,
                  }}
                >
                  提货
                </span>
              </Touchable>
              <Touchable
                style={{
                  display: "flex",
                  height: fitSize(37),
                  flexGrow: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={onClose}
              >
                <span
                  style={{
                    fontSize: fitSize(13),
                    fontWeight: 500,
                    color: "#aaaaaa",
                    fontFamily: fontName.PingFangSC,
                  }}
                >
                  取消
                </span>
              </Touchable>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
