import React, { Dispatch, FC, Fragment, useContext, useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Images, colors, fitSize, fontName, windowHeight, windowWidth } from '../theme'
import { Location, NavigationType, useLocation, useNavigationType } from 'react-router-dom'
import { CarouselContext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { __DEV__ } from '../models/account/account'
import { Touchable } from '../components/Button'
import { UserItemCard } from '../screens/Activity/components/UserItemCard'
import { useCountdown } from '../hooks/useCountdown'
import { showInfo, showLoad } from '../utils/showUtil'
import { animated, useSpring } from '@react-spring/web'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { AwardItem, StagePropertyCardItem, api } from '../services/api'
import { TYPE_CLASS, TotalArray } from '../screens'
import { GToast } from '../navigators'

const TYPE_ICON = {
  1: Images.mine.card1
  // 2: Images.mine.card2,
}

const TYPE_LABEL = {
  1: '重抽卡'
  // 2: "高级重抽卡",
}

const TYPE_BG = {
  1: Images.mine.card_bg1
  // 2: Images.mine.card_bg2,
}

type UseCardInfoItem = {
  isOk: boolean
  id: number
  name: 0 | 1
  number: number
  type: 0 | 1 | 2
  image: string
}

interface MultipleRewardsModalProps {
  list: AwardItem[]
  open: boolean
  onClose: () => void
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  whetherUseCard: 0 | 1 | 2
  setWhetherUseCard: Dispatch<React.SetStateAction<0 | 1 | 2>>
  fetchGetCoupon: () => void
  onReplaceItem: (id: number) => void
  onShowCard: (id: number) => void
  fetchStartAgain: (index: number, type: 0 | 1 | 2, popId: string) => void
  reserveCard: () => void
  isUseCard: boolean
  setIsUseCard: Dispatch<React.SetStateAction<boolean>>
  setCardStatus: (status: 0 | 1 | 2, id: number) => void
  currentSlide: number
  setCurrentSlide: Dispatch<React.SetStateAction<number>>
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: windowWidth,
    height: windowHeight,
    backgroundColor: colors.palette.neutral900 + 'a6',
    overflow: 'hidden'
  },
  overlay: { zIndex: 3 }
}

const BOTTOM_SHEET = fitSize(381)

export const MultipleRewardsModal: FC<MultipleRewardsModalProps> = ({
  list,
  open = false,
  onClose = () => {},
  setOpen,
  whetherUseCard = 0,
  setWhetherUseCard,
  fetchGetCoupon = () => {},
  onReplaceItem,
  onShowCard,
  fetchStartAgain,
  reserveCard,
  isUseCard,
  setIsUseCard,
  setCardStatus,
  currentSlide,
  setCurrentSlide
}) => {
  const location: Location = useLocation()
  const navType: NavigationType = useNavigationType()
  const totalSlides = list.length
  const isTouchEnabled = totalSlides > 1
  const [currentIndex, setCurrentIndex] = useState(0)
  const [openItemCard, setOpenItemCard] = useState(false)
  const [openWarning, setOpenWarning] = useState(false)
  const [useCardInfo, setUseCardInfo] = useState<UseCardInfoItem>({
    isOk: false,
    id: 0,
    name: 0,
    number: 0,
    type: 0,
    image: ''
  })
  const { showVerification, time, onTime, onReset } = useCountdown(3)
  const [allPropertyCard, setAllPropertyCard] = useState<TotalArray[]>([])

  const processData = (data: any[]) => {
    const processedData = data.reduce((result, item) => {
      const existingItem = result.find(resultItem => resultItem.type === item.type)
      if (existingItem) {
        existingItem.num += 1
      } else {
        result.push({ type: item.type, num: 1 })
      }
      return result
    }, [])

    setAllPropertyCard(processedData)

    console.log('Processed Data:', processedData)
  }

  const fetchGetStagePropertyCard = async () => {
    let currentPage = 1
    let hasMorePages = true
    const PAGE_SIZE = 20
    let allData = []
    while (hasMorePages) {
      const res = await api.getStagePropertyCardList(currentPage, PAGE_SIZE)

      if (res.kind === 'ok') {
        if (res.data.list.length > 0) {
          allData = [...allData, ...res.data.list]
          if (currentPage * PAGE_SIZE < res.data.total) {
            currentPage++
          } else {
            hasMorePages = false
          }
        } else {
          hasMorePages = false
        }
      } else {
        GToast.current.handleErrorResponse(res)
        hasMorePages = false
      }
    }
    const newArray: TotalArray[] = Object.values(
      allData.reduce((acc, currentItem) => {
        if (!acc[currentItem.name]) {
          acc[currentItem.name] = { name: currentItem.name, data: [] }
        }
        acc[currentItem.name].data.push(currentItem)
        return acc
      }, {})
    )
    setAllPropertyCard(newArray)
  }

  useEffect(() => {
    if (openItemCard) {
      fetchGetStagePropertyCard()
    }
  }, [openItemCard])

  useEffect(() => {
    if (!open) {
      setCurrentIndex(0)
      setOpenItemCard(false)
      setOpenWarning(false)
      setUseCardInfo({
        isOk: false,
        id: 0,
        name: 0,
        number: 0,
        type: 0,
        image: ''
      })
      // TODO
    }
  }, [open])

  useEffect(() => {
    if (navType === 'POP' && location.key !== 'default') {
      onClose()
    }
  }, [location])

  const onPutIntoStorage = () => {
    onReplaceItem(list[currentIndex].id)
  }

  const onNewPutIntoStorage = () => {
    onReplaceItem(list[currentIndex].id)
  }

  const useItemCard = () => {
    setOpenItemCard(true)
  }

  const openUseCard = () => {
    setOpenItemCard(false)
    setOpenWarning(false)
    setUseCardInfo({ ...useCardInfo, isOk: true })
    fetchStartAgain(currentIndex, useCardInfo.type, String(useCardInfo.id))
    onClose()
    setWhetherUseCard(useCardInfo.type)
  }

  const setShowCard = (id: number) => {
    onShowCard(id)
    setCurrentSlide(currentIndex)
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      style={customStyles}
      appElement={document.getElementById('root') as HTMLElement}
      ariaHideApp={false}
    >
      <div
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          totalSlides={totalSlides}
          touchEnabled={isTouchEnabled}
          currentSlide={currentSlide}
        >
          <Content
            list={list}
            setCurrentIndex={setCurrentIndex}
            isReplace={list[currentIndex]?.status === 2}
            onShowCard={setShowCard}
            prevInfo={useCardInfo}
            currentIndex={list[currentIndex]?.id}
          />
        </CarouselProvider>
        {list[currentIndex]?.isShow ? (
          <Fragment>
            {list[currentIndex]?.status === 2 ? (
              <div
                style={{
                  width: '100%',
                  height: fitSize(40),
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: fitSize(61)
                }}
              >
                <Touchable
                  style={{
                    width: fitSize(162),
                    height: fitSize(46),
                    display: 'flex',
                    // alignItems: "center",
                    justifyContent: 'center',
                    // marginLeft: fitSize(27),
                    backgroundImage: `url(${Images.activityNew.take_draw_button})`,
                    backgroundSize: '100% 100%',
                    marginRight: fitSize(23)
                  }}
                  onClick={() => {
                    // onNewPutIntoStorage();
                    setWhetherUseCard(1)
                    setUseCardInfo({
                      isOk: false,
                      id: 0,
                      name: 0,
                      number: 0,
                      type: 0,
                      image: ''
                    })
                    setCardStatus(1, list[currentIndex].id)
                    showInfo('替换成功', 'OK')
                  }}
                >
                  <span
                    style={{
                      fontSize: fitSize(13),
                      fontFamily: fontName.PinFangSCSemibold,
                      fontWeight: 500,
                      color: colors.palette.neutral30,
                      marginTop: fitSize(11)
                    }}
                  >
                    替换
                  </span>
                </Touchable>
                <Touchable
                  style={{
                    width: fitSize(162),
                    height: fitSize(46),
                    display: 'flex',
                    // alignItems: "center",
                    justifyContent: 'center',
                    // marginRight: fitSize(27),
                    backgroundImage: `url(${Images.activityNew.more_draw_button1})`,
                    backgroundSize: '100% 100%'
                  }}
                  onClick={() => {
                    reserveCard()
                    setWhetherUseCard(1)
                    setUseCardInfo({
                      isOk: false,
                      id: 0,
                      name: 0,
                      number: 0,
                      type: 0,
                      image: ''
                    })
                  }}
                >
                  <span
                    style={{
                      fontSize: fitSize(13),
                      fontFamily: fontName.PinFangSCSemibold,
                      fontWeight: 500,
                      color: colors.palette.neutral30,
                      marginTop: fitSize(11)
                    }}
                  >
                    保留
                  </span>
                </Touchable>
              </div>
            ) : list[currentIndex]?.status === 1 ? (
              <div
                style={{
                  width: '100%',
                  height: fitSize(40),
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: fitSize(20)
                }}
              >
                <Touchable
                  style={{
                    width: fitSize(162),
                    height: fitSize(46),
                    display: 'flex',
                    // alignItems: "center",
                    justifyContent: 'center',
                    borderRadius: 2,
                    // backgroundColor: "#FFE442",
                    backgroundImage: `url(${Images.activityNew.take_draw_button})`,
                    backgroundSize: '100% 100%'
                  }}
                  onClick={() => {
                    onPutIntoStorage()
                  }}
                >
                  <span
                    style={{
                      fontSize: fitSize(13),
                      fontFamily: fontName.PinFangSCSemibold,
                      fontWeight: 500,
                      color: colors.palette.neutral30,
                      marginTop: fitSize(11)
                    }}
                  >
                    开心收下
                  </span>
                </Touchable>
              </div>
            ) : (
              <Fragment>
                {isUseCard ? (
                  <div
                    style={{
                      width: '100%',
                      height: fitSize(40),
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: fitSize(20)
                    }}
                  >
                    <Touchable
                      style={{
                        width: fitSize(162),
                        height: fitSize(46),
                        display: 'flex',
                        // alignItems: "center",
                        justifyContent: 'center',
                        borderRadius: 2,
                        backgroundImage: `url(${Images.activityNew.take_draw_button})`,
                        backgroundSize: '100% 100%'
                      }}
                      onClick={() => {
                        onPutIntoStorage()
                      }}
                    >
                      <span
                        style={{
                          fontSize: fitSize(13),
                          fontFamily: fontName.PinFangSCSemibold,
                          fontWeight: 500,
                          color: colors.palette.neutral30,
                          marginTop: fitSize(11)
                        }}
                      >
                        开心收下
                      </span>
                    </Touchable>
                  </div>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: fitSize(40),
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: fitSize(20)
                    }}
                  >
                    <Touchable
                      style={{
                        width: fitSize(162),
                        height: fitSize(46),
                        display: 'flex',
                        // alignItems: "center",
                        justifyContent: 'center',
                        marginLeft: fitSize(27),
                        backgroundImage: `url(${Images.activityNew.take_draw_button})`,
                        backgroundSize: '100% 100%',
                        marginRight: fitSize(23)
                      }}
                      onClick={() => {
                        onPutIntoStorage()
                      }}
                    >
                      <span
                        style={{
                          fontSize: fitSize(13),
                          fontFamily: fontName.PinFangSCSemibold,
                          fontWeight: 500,
                          color: colors.palette.neutral30,
                          marginTop: fitSize(11)
                        }}
                      >
                        开心收下
                      </span>
                    </Touchable>
                    <Touchable
                      style={{
                        width: fitSize(162),
                        height: fitSize(46),
                        display: 'flex',
                        // alignItems: "center",
                        justifyContent: 'center',
                        marginRight: fitSize(27),
                        backgroundImage: `url(${Images.activityNew.more_draw_button1})`,
                        backgroundSize: '100% 100%'
                      }}
                      onClick={useItemCard}
                    >
                      <span
                        style={{
                          fontSize: fitSize(13),
                          fontFamily: fontName.PinFangSCSemibold,
                          fontWeight: 500,
                          color: colors.palette.neutral30,
                          marginTop: fitSize(11)
                        }}
                      >
                        使用道具卡
                      </span>
                    </Touchable>
                  </div>
                )}
              </Fragment>
            )}
          </Fragment>
        ) : null}
      </div>
      <UserItemCard
        open={openItemCard}
        onClose={() => {
          setOpenItemCard(false)
        }}
        setOpen={setOpenItemCard}
        h={BOTTOM_SHEET}
      >
        <div
          style={{
            width: '100%',
            height: BOTTOM_SHEET,
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url(${Images.activityNew.useCard})`,
            backgroundSize: '100% 100%',
            backgroundColor: 'rgba(0, 0, 0, 0)'
          }}
        >
          <div
            style={{
              width: '100%',
              height: fitSize(41),
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            {/* <span
              style={{
                fontSize: fitSize(15),
                fontFamily: fontName.PinFangSCSemibold,
                fontWeight: 500,
                color: colors.palette.neutral30,
                marginTop: fitSize(20),
              }}
            >
              使用道具卡
            </span> */}
            <Touchable
              onClick={() => {
                setOpenItemCard(false)
                setOpenWarning(false)
                setUseCardInfo({
                  isOk: false,
                  id: 0,
                  name: 0,
                  number: 0,
                  type: 0,
                  image: ''
                })
                onReset()
              }}
              style={{
                width: fitSize(46),
                height: fitSize(43),
                position: 'absolute',
                right: fitSize(14),
                top: fitSize(55)
              }}
            >
              <img
                src={Images.activityNew.use_card_close}
                style={{
                  width: '100%',
                  height: '100%'
                }}
                alt=""
              />
            </Touchable>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              marginTop: fitSize(107)
            }}
          >
            {allPropertyCard.map((item, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: fitSize(347),
                    height: fitSize(90),
                    alignSelf: 'center',
                    marginBottom: fitSize(10),
                    backgroundImage: `url(${TYPE_BG[1]})`,
                    backgroundSize: '100% 100%',
                    zIndex: 9999
                  }}
                  key={index}
                >
                  <div
                    style={{
                      width: fitSize(84),
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <img src={TYPE_ICON[1]} alt="" style={{ width: fitSize(44), height: fitSize(30) }} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexGrow: 1,
                      alignItems: 'center'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: fitSize(147),
                        height: fitSize(46),
                        marginLeft: fitSize(16),
                        justifyContent: 'space-between'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <span
                          style={{
                            fontFamily: fontName.PinFangSCSemibold,
                            fontSize: fitSize(13),
                            color: colors.palette.neutral30,
                            fontWeight: 500,
                            marginRight: fitSize(3)
                          }}
                        >{`${item?.name}*${item?.data?.length}`}</span>
                        <span
                          style={{
                            fontSize: fitSize(10),
                            color: '#6632FC',
                            fontWeight: 400
                          }}
                        >
                          全部扭蛋机可用
                        </span>
                      </div>
                      <span
                        style={{
                          fontSize: fitSize(11),
                          color: '#AAAAAA',
                          fontWeight: 400
                        }}
                      >
                        {/* 使用后可选择保留或者替换 */}
                        使用后可将直接替换
                      </span>
                    </div>
                    <Touchable
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: fitSize(56),
                        height: fitSize(28),
                        // backgroundColor: "#FFE442",
                        border: '1px solid #212F3B',
                        borderRadius: fitSize(1),
                        position: 'absolute',
                        right: fitSize(28)
                      }}
                      onClick={() => {
                        if (item.data.length > 0) {
                          const res = item.data[0]
                          const info: UseCardInfoItem = {
                            isOk: true,
                            id: res?.id,
                            name: 1,
                            number: res?.num,
                            type: 1,
                            image:
                              list[currentIndex]?.business_type === 1
                                ? list[currentIndex]?.award_img
                                : list[currentIndex]?.business_type === 2
                                ? Images.activityNew.item_card
                                : list[currentIndex]?.business_type === 3
                                ? Images.activityNew.coupon_card
                                : ''
                          }
                          setOpenItemCard(false)
                          setOpenWarning(true)
                          setUseCardInfo(info)
                          onTime()
                        } else {
                          showInfo('道具不足')
                        }
                      }}
                    >
                      <span
                        style={{
                          fontSize: fitSize(12),
                          color: colors.palette.neutral30,
                          fontWeight: 400
                        }}
                      >
                        去使用
                      </span>
                    </Touchable>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </UserItemCard>
      <UserItemCard
        open={openWarning}
        onClose={() => {
          setOpenWarning(false)
        }}
        setOpen={setOpenWarning}
        h={fitSize(212)}
      >
        <div
          style={{
            width: '100%',
            height: fitSize(212),
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: colors.palette.neutral100,
            borderTopRightRadius: fitSize(10),
            borderTopLeftRadius: fitSize(10)
          }}
        >
          <div
            style={{
              width: '100%',
              height: fitSize(41),
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Touchable
              onClick={() => {
                setOpenItemCard(false)
                setOpenWarning(false)
                setUseCardInfo({
                  isOk: false,
                  id: 0,
                  name: 0,
                  number: 0,
                  type: 0,
                  image: ''
                })
                onReset()
              }}
              style={{
                width: fitSize(12),
                height: fitSize(12),
                position: 'absolute',
                right: fitSize(14),
                top: fitSize(14)
              }}
            >
              <img
                src={Images.components.close}
                style={{
                  width: '100%',
                  height: '100%'
                }}
                alt=""
              />
            </Touchable>
          </div>
          <span
            style={{
              fontSize: fitSize(15),
              fontFamily: fontName.PingFangSC,
              fontWeight: 500,
              color: colors.palette.neutral30,
              alignSelf: 'center'
            }}
          >
            确定使用{TYPE_LABEL[useCardInfo.name]}？
          </span>
          <span
            style={{
              fontSize: fitSize(12),
              fontWeight: 400,
              color: colors.palette.neutral10,
              fontFamily: fontName.PingFangSC,
              marginTop: fitSize(4),
              alignSelf: 'center'
            }}
          >
            使用{TYPE_LABEL[useCardInfo.name]}
            后，中奖商品不会保留，直接替换，是否继续使用
          </span>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: fitSize(40)
            }}
          >
            <Touchable
              style={{
                width: fitSize(162),
                height: fitSize(47),
                display: 'flex',
                // alignItems: "center",
                justifyContent: 'center',
                // backgroundColor: "#F5F5F8",
                // borderRadius: fitSize(19),
                marginLeft: fitSize(14),
                backgroundImage: `url(${Images.activityNew.take_draw_button1})`,
                backgroundSize: '100% 100%'
              }}
              onClick={() => {
                setOpenItemCard(false)
                setOpenWarning(false)
                setUseCardInfo({
                  isOk: false,
                  id: 0,
                  name: 0,
                  number: 0,
                  type: 0,
                  image: ''
                })
                onReset()
                setWhetherUseCard(0)
                window._hmt.push(['_trackEvent', 'button', 'click', `取消使用重抽卡`])
              }}
            >
              <span
                style={{
                  fontSize: fitSize(14),
                  fontFamily: fontName.PinFangSCSemibold,
                  fontWeight: 500,
                  color: colors.palette.neutral30,
                  marginTop: fitSize(11)
                }}
              >
                取消
              </span>
            </Touchable>
            <Touchable
              style={{
                width: fitSize(162),
                height: fitSize(47),
                display: 'flex',
                // alignItems: "center",
                justifyContent: 'center',
                // borderRadius: fitSize(19),
                marginRight: fitSize(14),
                backgroundImage: `url(${Images.activityNew.take_draw_button})`,
                backgroundSize: '100% 100%'
              }}
              onClick={() => {
                onReset()
                openUseCard()
                setIsUseCard(true)
                setCardStatus(useCardInfo.type, list[currentIndex].id)
                window._hmt.push(['_trackEvent', 'button', 'click', `使用重抽卡`])
              }}
              disabled={showVerification}
            >
              <span
                style={{
                  fontSize: fitSize(14),
                  fontFamily: fontName.PinFangSCSemibold,
                  fontWeight: 500,
                  color: colors.palette.neutral30,
                  marginTop: fitSize(11)
                }}
              >
                确定{showVerification ? ` ( ${time} )` : null}
              </span>
            </Touchable>
          </div>
        </div>
      </UserItemCard>
    </Modal>
  )
}

const Content = ({
  list,
  setCurrentIndex,
  isReplace,
  onShowCard,
  prevInfo,
  currentIndex
}: {
  list: AwardItem[]
  setCurrentIndex: Dispatch<React.SetStateAction<number>>
  isReplace: boolean
  onShowCard: (id: number) => void
  prevInfo: UseCardInfoItem
  currentIndex: number
}) => {
  const carouselContext = useContext(CarouselContext)

  useEffect(() => {
    function onChange() {
      setCurrentIndex(carouselContext?.state?.currentSlide)
    }
    carouselContext?.subscribe(onChange)
    return () => carouselContext?.unsubscribe(onChange)
  }, [carouselContext])

  return (
    <Slider>
      {list.map((item, index) => {
        return (
          <Slide
            index={index}
            key={index}
            style={{
              marginTop: fitSize(173)
            }}
          >
            <ViewSlide item={item} isReplace={isReplace} onShowCard={onShowCard} prevInfo={prevInfo} currentIndex={currentIndex} />
          </Slide>
        )
      })}
    </Slider>
  )
}

const ViewSlide = ({
  item,
  isReplace,
  onShowCard,
  prevInfo,
  currentIndex
}: {
  item: AwardItem
  isReplace: boolean
  onShowCard: (id: number) => void
  prevInfo?: UseCardInfoItem
  currentIndex: number
}) => {
  const [loaded, setLoaded] = useState(false)
  const [one, setOne] = useState(false)

  useEffect(() => {
    if (!one) {
      setLoaded(true)
      setOne(true)
    }
  }, [])
  console.log('item', item)

  const fadeInOut = useSpring({
    opacity: loaded && currentIndex === item.id ? 1 : 0,
    from: { opacity: 0 },
    config: {
      duration: 800
    }
  })

  return (
    <animated.div
      style={{
        // ...fadeInOut,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column'
      }}
    >
      {item?.isShow ? (
        <Fragment>
          {isReplace ? (
            <Fragment>
              <span
                style={{
                  fontSize: fitSize(28),
                  fontFamily: fontName.PinFangSCSemibold,
                  fontWeight: 500,
                  color: colors.palette.neutral100,
                  width: fitSize(300),
                  textAlign: 'center',
                  position: 'absolute',
                  top: 0,
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical'
                }}
              >
                恭喜您抽中了
                {TYPE_CLASS[item?.award_type]}款
              </span>
              <img
                src={Images.activityNew.bowknot}
                alt=""
                style={{
                  position: 'absolute',
                  width: fitSize(277),
                  height: fitSize(44),
                  top: 25,
                  zIndex: -1
                }}
              />
              <div
                style={{
                  display: 'flex',
                  width: fitSize(302),
                  height: fitSize(305),
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  left: -fitSize(75)
                }}
              >
                <img
                  src={
                    item?.business_type === 1
                      ? item?.award_img
                      : item?.business_type === 2
                      ? Images.activityNew.item_card
                      : item?.business_type === 3
                      ? Images.activityNew.coupon_card
                      : ''
                  }
                  alt=""
                  style={{
                    width: fitSize(100),
                    boxShadow: '0px 0px 36px 0px #FFE442b3',
                    height: fitSize(100)
                  }}
                  onLoad={() => setLoaded(true)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  width: fitSize(302),
                  height: fitSize(305),
                  backgroundImage: `url(${Images.activityNew.halo2})`,
                  backgroundSize: '100% 305px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  right: -fitSize(44),
                  backgroundRepeat: 'no-repeat'
                }}
              >
                <img
                  src={prevInfo.image}
                  alt=""
                  style={{
                    width: fitSize(161.72),
                    height: fitSize(161.72),
                    boxShadow: '0px 0px 36px 0px #FFE442b3',
                    borderRadius: 2,
                    border: '1.5px solid #212F3B'
                  }}
                  onLoad={() => setLoaded(true)}
                />
                <img
                  src={Images.activityNew.halo_bg}
                  alt=""
                  style={{
                    width: fitSize(169),
                    height: fitSize(169),
                    position: 'absolute',
                    zIndex: -1,
                    right: 65,
                    top: 75
                  }}
                />
              </div>
              <img
                src={Images.activityNew.replace}
                style={{
                  width: fitSize(127),
                  height: fitSize(68),
                  objectFit: 'cover',
                  position: 'absolute',
                  bottom: fitSize(50),
                  left: fitSize(87)
                }}
                alt=""
              />
            </Fragment>
          ) : (
            <Fragment>
              <span
                style={{
                  fontSize: fitSize(28),
                  fontFamily: fontName.PinFangSCSemibold,
                  fontWeight: 500,
                  color: colors.palette.neutral100,
                  width: fitSize(300),
                  textAlign: 'center',
                  position: 'absolute',
                  top: -24
                }}
              >
                恭喜您抽中了
                {TYPE_CLASS[item?.award_type]}款
              </span>
              <img
                src={Images.activityNew.halo}
                alt=""
                style={{
                  display: 'flex',
                  width: '100%',
                  height: fitSize(378),
                  backgroundSize: '100% 378px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  zIndex: -2
                }}
              />
              <img
                src={
                  item?.business_type === 1
                    ? item?.award_img
                    : item?.business_type === 2
                    ? Images.activityNew.item_card
                    : item?.business_type === 3
                    ? Images.activityNew.coupon_card
                    : ''
                }
                alt=""
                style={{
                  width: fitSize(200),
                  height: fitSize(200),
                  borderRadius: 2,
                  // boxShadow: "0px 0px 36px 0px #FFE442b3",
                  border: '1.5px solid #212F3B'
                }}
                onLoad={() => setLoaded(true)}
              />
              <img
                src={Images.activityNew.halo_bg}
                alt=""
                style={{
                  width: fitSize(211),
                  height: fitSize(211),
                  position: 'absolute',
                  zIndex: -1,
                  right: 81,
                  top: 90
                }}
              />
              <span
                style={{
                  fontSize: fitSize(10),
                  fontFamily: fontName.PingFangSC,
                  fontWeight: 400,
                  color: colors.palette.neutral100,
                  width: fitSize(300),
                  textAlign: 'center',
                  position: 'absolute',
                  bottom: 50,
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: fitSize(170)
                }}
              >
                {item?.award_name}
              </span>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Touchable
          style={{
            width: '100%',
            height: fitSize(330),
            alignSelf: 'center',
            marginTop: fitSize(50)
          }}
          onClick={() => {
            onShowCard(item.id)
          }}
          disabled={item.isShow}
        >
          <img
            src={Images.activityNew.blind_box}
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            alt=""
          />
        </Touchable>
      )}
    </animated.div>
  )
}
