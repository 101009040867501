import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import {
  Images,
  colors,
  fitSize,
  fontName,
  windowHeight,
  windowWidth,
} from "../theme";
import {
  Location,
  NavigationType,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";
import { ActivityModalItem } from "../services/api";
import { Touchable } from "../components/Button";

interface ActivityModalProps {
  open: boolean;
  onClose: () => void;
  value: ActivityModalItem[];
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: windowWidth,
    height: windowHeight,
    backgroundColor: colors.palette.neutral900 + "a6",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  overlay: { zIndex: 1000 },
};

export const ActivityModal: FC<ActivityModalProps> = ({
  open = false,
  onClose = () => {},
  value,
}) => {
  const location: Location = useLocation();
  const navType: NavigationType = useNavigationType();
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (navType === "POP" && location.key !== "default") {
      onClose();
    }
  }, [location]);

  const closeCurrentActivity = () => {
    setCurrentActivityIndex((prevIndex) => prevIndex + 1);
  };

  const shouldDisplayActivity = () => {
    const currentActivity = value[currentActivityIndex];

    if (currentActivity) {
      if (currentActivity.rate_type === 2) {
        return true;
      }

      return true;
    }

    return false;
  };

  const hasNextActivity = currentActivityIndex < value.length - 1;

  return (
    <Modal
      isOpen={open && shouldDisplayActivity()}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById("root") as HTMLElement}
      onRequestClose={() => {
        if (!hasNextActivity) {
          onClose();
        }
      }}
    >
      {currentActivityIndex < value.length && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: fitSize(295),
            height: fitSize(436),
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Touchable
            style={{
              width: fitSize(295),
              height: fitSize(394),
              marginBottom: fitSize(8),
            }}
            onClick={() => {
              const currentActivity = value[currentActivityIndex];
              // if (currentActivity.url_type === 2) {

              // } else {
              //   navigate(
              //     `/web-view/?url=${decodeURIComponent(currentActivity.url)}`
              //   );
              // }
              navigate(currentActivity.url, {
                state: {
                  atOnce: currentActivity.url.includes("/activity-new"),
                },
              });
            }}
          >
            <img
              src={value[currentActivityIndex].img}
              alt={`Activity ${value[currentActivityIndex].id}`}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Touchable>
          <Touchable
            onClick={closeCurrentActivity}
            style={{
              width: fitSize(14),
              height: fitSize(14),
            }}
          >
            <img
              alt=""
              src={Images.components.modal_back}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Touchable>
        </div>
      )}
    </Modal>
  );
};
