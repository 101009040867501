import React, {
  CSSProperties,
  Dispatch,
  ReactNode,
  Ref,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import videojs from "video.js";
import { isBaiDu, isIos, isQuarkBrowser, isUCBrowser } from "../utils/util";
import { fitSize } from "../theme";

interface SimpleVideoProps {
  uri: string;
  isPaused?: boolean;
  muted?: boolean;
  onVideoEnd?: () => void;
  loop?: boolean;
  poster?: string | undefined;
  style?: CSSProperties | undefined;
  w?: number | string | undefined;
  h?: number | string | undefined;
  children?: ReactNode;
  isReset?: boolean | undefined;
  resizeMode?: "cover" | "contain" | "stretch" | undefined;
  setSuccessLoading?: Dispatch<React.SetStateAction<boolean>>;
}

interface SimpleVideoPropsRef {
  toggleVideo?: () => void;
  sonVideoPalyState?: boolean;
}

export const WebVideoCompatibility = forwardRef(
  (props: SimpleVideoProps, ref: Ref<SimpleVideoPropsRef>) => {
    const {
      uri,
      isPaused = true,
      muted = true,
      loop = true,
      style,
      poster,
      children,
      isReset = true,
      resizeMode = "cover",
      w,
      h,
      onVideoEnd,
      setSuccessLoading,
    } = props;

    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(!isPaused);
    const firstPlay = useRef(false);

    const loadVideo = async (src) => {
      try {
        const videoElement = videoRef.current;
        const player = videojs(videoElement, {
          controls: false,
          poster: poster ?? "",
          autoplay: false,
          muted: muted,
          loop,
          playsinline: true,
          preload: "auto",
          controlsList: "nodownload,nofullscreen,noremoteplayback ",
          html5: {
            hls: {
              enableLowInitialPlaylist: true,
              smoothQualityChange: true,
            },
          },
          plugins: {
            // 可根据需要添加其他插件配置
          },
          errorDisplay: false,
        });
        player.src({ type: "video/mp4", src: src });
        player.on("ended", () => {
          if (onVideoEnd) onVideoEnd();
        });
        playerRef.current = player;
        setSuccessLoading && setSuccessLoading(true);
        if (!firstPlay.current) {
          videoState();
        }
      } catch (error) {
        // 执行自定义错误处理逻辑，例如记录日志或显示错误提示
        console.error("视频加载失败:", error);
        return null;
      }
    };

    const getCachedVideoData = (key: string) => {
      return sessionStorage.getItem(key);
    };

    const setCachedVideoData = (data: string, key: string) => {
      sessionStorage.setItem(key, data);
    };

    const fetchWebVideoData = async () => {
      try {
        if (getCachedVideoData(uri)) {
          loadVideo(getCachedVideoData(uri));
          setSuccessLoading && setSuccessLoading(true);
          console.log("Video loaded from cache.");
          return;
        }
        const headers = new Headers();
        headers.append("Range", "bytes=0-");

        const response = await fetch(uri, { headers });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const arrayBuffer = await response.arrayBuffer();
        const blob = new Blob([arrayBuffer], {
          type: "application/octet-stream",
        });

        const videoData = URL.createObjectURL(blob);

        // cachedVideoData.current = videoData
        setCachedVideoData(videoData, uri);
        loadVideo(videoData);
        setSuccessLoading && setSuccessLoading(true);

        console.log("Video loaded successfully.");
      } catch (error) {
        setSuccessLoading && setSuccessLoading(false);
        console.error("Error fetching web video data:", error);
        // throw error
      }
    };

    useEffect(() => {
      if (!!uri) {
        // if (isIos || isQuarkBrowser || isUCBrowser) {
        //   loadVideo(uri);
        // } else {
        //   fetchWebVideoData();
        // }
        loadVideo(uri);
      }
    }, [uri]);

    const videoState = () => {
      const player = playerRef.current;
      if (player) {
        if (isPaused) {
          player.pause();
          try {
            if (isReset) player?.currentTime(0);
          } catch (error) {
            console.error("Error playing video:", error);
          }
        } else {
          player.play().catch((error) => {
            console.error("Error playing video:", error);
          });
        }
      }
    };

    useEffect(() => {
      if (!!uri) {
        videoState();
      }
    }, [isPaused]);

    React.useImperativeHandle(ref, () => ({
      toggleVideo: () => {
        if (playerRef.current) {
          if (playerRef.current.paused) {
            playerRef.current.play();
            setIsPlaying(true);
          } else {
            playerRef.current.pause();
            setIsPlaying(false);
          }
        }
      },
      sonVideoPalyState: isPlaying,
    }));

    useEffect(() => {
      const player = playerRef.current;
      if (player) {
        player.muted(muted);
      }
    }, [muted]);

    if (isBaiDu) return;

    const container: CSSProperties = {
      width: w ?? "100%",
      height: h ?? "100%",
      backgroundColor: "transparent",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ...style,
    };

    const content: CSSProperties = {
      width: "100%",
      height: h,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    const videoStyle: CSSProperties = {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    };

    return (
      <div style={container}>
        <div style={content}>
          <video
            className="video-js"
            width={"100%"}
            controls={false}
            x5-video-player-type="h5-page"
            height={"100%"}
            muted={muted}
            poster={poster}
            style={videoStyle}
            ref={videoRef}
            onPause={() => {
              setIsPlaying(false);
            }}
            id="capsule"
          />
        </div>
        {children}
      </div>
    );
  }
);
