import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { Images, colors, fitSize } from "../theme";
import { Touchable } from "./Button";

interface TabProps {
  children: React.ReactNode;
  active?: number;
  callback: (index: number) => void;
  contentLayout?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around";
}

interface TabData {
  tab: string;
  children: React.ReactNode;
}

const Tab: React.FC<TabProps> & { TabPane: React.FC<TabData> } = ({
  children,
  active = 0,
  callback,
  contentLayout = "flex-start",
}) => {
  const [activeTab, setActiveTab] = useState(active);
  const [tabsData, setTabsData] = useState<TabData[]>([]);

  useEffect(() => {
    let data: TabData[] = [];
    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;
      const {
        props: { tab, children },
      } = element as React.ReactElement<{
        tab: string;
        children: React.ReactNode;
      }>;
      data.push({ tab, children });
    });

    setTabsData(data);
  }, [children]);

  return (
    <div className={styles.nav_tab_container}>
      <div
        className={styles.nav_tab}
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: fitSize(15),
          paddingRight: fitSize(15),
        }}
      >
        {tabsData.map(({ tab }, index) => (
          <Touchable
            className={styles.nav_item}
            onClick={() => {
              callback(index);
              setActiveTab(index);
            }}
            key={tab}
            disabled={activeTab === index}
          >
            <span
              className={
                index === activeTab
                  ? styles.nav_active_tab
                  : styles.nav_static_tab
              }
            >
              {tab}
            </span>
            <div
              style={{
                width: fitSize(33),
                height: fitSize(8),
                backgroundImage: `url(${
                  index === activeTab ? Images.mine.tab_a : ""
                })`,
                backgroundSize: "100% 100%",
                position: "absolute",
                bottom: 12,
                zIndex: 0,
              }}
            />
          </Touchable>
        ))}
      </div>
      <div className={styles.nav_tab_content}>
        {tabsData[activeTab] && tabsData[activeTab].children}
      </div>
    </div>
  );
};

interface TabPaneProps {
  children: React.ReactNode;
}

const TabPane: React.FC<TabPaneProps> = ({ children }) => {
  return <>{children}</>;
};

Tab.TabPane = TabPane;

export default Tab;
