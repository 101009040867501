import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Images, fitSize } from "../../../theme";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import "./index.module.css";
import { Location, useLocation } from "react-router-dom";

interface CardDetailModalProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  h?: number;
}

export const UserItemCard: FC<CardDetailModalProps> = ({
  children,
  open,
  onClose,
  setOpen,
  h
}) => {
  const sheetRef = useRef<BottomSheetRef>();
  const focusRef = useRef<HTMLButtonElement>();
  const location: Location = useLocation();

  // useEffect(() => {
  //   return () => {
  //     setOpen(false);
  //   };
  // }, [location]);

  return (
    <BottomSheet
      open={open}
      skipInitialTransition
      ref={sheetRef}
      initialFocusRef={focusRef}
      defaultSnap={({ height }) => {
        return h;
      }}
      snapPoints={({ height, maxHeight }) => {
        return [h, h];
      }}
      scrollLocking={true}
      expandOnContentDrag
      // style={{ display: open ? "flex" : "none" }}
      blocking={false}
    >
      {children}
    </BottomSheet>
  );
};
