import React, { CSSProperties } from "react";
import { Images, fitSize, fontName } from "../theme";

interface EmptyItemProps {
  text: string;
  type: "not_coupon" | "not_order" | "not_all" | "not_data" | "not_network";
  isShow?: boolean;
  style?: CSSProperties;
}

export const EmptyItem: React.FC<EmptyItemProps> = ({
  isShow,
  style,
  text,
  type,
}) => {
  return (
    <div
      style={{
        display: isShow ? "flex" : "none",
        flexDirection: "column",
        alignItems: "center",
        position: "absolute",
        top: "30%",
        left: "30%",
        ...style,
      }}
    >
      <img
        src={Images.components[type]}
        alt=""
        style={{
          display: isShow ? "flex" : "none",
          width: fitSize(172),
          height: fitSize(165),
          alignSelf: "center",
        }}
      />
      <span
        style={{
          fontFamily: fontName.PingFangSC,
          fontSize: fitSize(14),
          color: "#767E85",
          fontWeight: 400,
          marginTop: fitSize(14),
          textAlign: "center",
          width: "100%",
        }}
      >
        {text}
      </span>
    </div>
  );
};
